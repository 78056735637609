/**
 * Generated by orval v6.19.0 🍺
 * Do not edit manually.
 * OLHSO API
 * The OLHSO API description
 * OpenAPI spec version: 0.1
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  Truck,
  TruckControllerFindManyParams,
  TruckList
} from '../../model'



  /**
 * @summary 트럭 목록 조회
 */
export const truckControllerFindMany = <TData = AxiosResponse<TruckList>>(
    params?: TruckControllerFindManyParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/olhso/trucks`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }
/**
 * @summary 단일 트럭 조회
 */
export const truckControllerFindOne = <TData = AxiosResponse<Truck>>(
    id: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/olhso/trucks/${id}`,options
    );
  }
export type TruckControllerFindManyResult = AxiosResponse<TruckList>
export type TruckControllerFindOneResult = AxiosResponse<Truck>
