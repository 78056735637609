import React from 'react';
import styled from 'styled-components';

interface ConfirmModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  truckId?: string
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;
  
  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    onClose(); // Call the onClose function passed as a prop
  };

  const handleModalContentClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation(); // Prevent the click from closing the modal
  };

  return (
      <ModalOverlay onClick={handleOverlayClick}>
      <ModalContent onClick={handleModalContentClick}> 
        <ModalHeader>Warning</ModalHeader>
        <ModalBody>
          <ModalQuestion>Are you sure ?</ModalQuestion>
          <ModalWarning>Check it out again and do it!</ModalWarning>
          <ModalActions>
            <CancelButton onClick={onClose}>Cancel</CancelButton>
            <ConfirmButton onClick={onConfirm}>Yes</ConfirmButton>
          </ModalActions>
        </ModalBody>
      </ModalContent>
    </ModalOverlay>
  );
};

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: #FFF2F1;
  border: 1px solid #CD3320;
  border-radius: 12px;
  padding: 25px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  width: 330px; 
`;

const ModalHeader = styled.h2`
  color: #d93838;
  margin-bottom: 20px;
  text-align: center;
`;

const ModalBody = styled.div`
  color: #333;
  font-size: 16px;
  margin-bottom: 20px;
  text-align: center;
`;

const ModalQuestion = styled.p`
  font-weight: bold;
  margin-bottom: 5px;
`;

const ModalWarning = styled.p`
  color: #555;
  font-size: 14px;
`;

const ModalActions = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
`;

const Button = styled.button`
  padding: 10px 20px;
  border-radius: 25px;
  font-weight: bold;
  cursor: pointer;
  border: 2px solid; // Add border color in specific button styles
`;

const CancelButton = styled(Button)`
  background: #FFF2F1;
  color: #d93838;
  border-color: #d93838;
  width: 124px;
  height: 40px;
  margin-top: 15px;
`;

const ConfirmButton = styled(Button)`
  background: #d93838;
  color: #fff;
  border-color: #d93838;
  width: 124px;
  height: 40px;
  margin-top: 15px;
`;

export default ConfirmModal;


