/**
 * Generated by orval v6.19.0 🍺
 * Do not edit manually.
 * OLHSO API
 * The OLHSO API description
 * OpenAPI spec version: 0.1
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  TruckStockForTruck,
  TruckStockForTruckList,
  UpdateTruckStocksBulkForTruckDto
} from '../../model'



  /**
 * @summary 관리자 페이지에서 트럭의 재고 목록 조회
 */
export const adminTruckStockControllerFindOneByTruckId = <TData = AxiosResponse<TruckStockForTruck[]>>(
    id: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/olhso/admin/trucks/${id}/stock-for-truck`,options
    );
  }
/**
 * @summary 관리자 페이지에서 재고 등록하기
 */
export const adminTruckStockControllerUpdate = <TData = AxiosResponse<TruckStockForTruckList>>(
    id: string,
    updateTruckStocksBulkForTruckDto: UpdateTruckStocksBulkForTruckDto, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.put(
      `/olhso/admin/trucks/${id}/stock-for-truck`,
      updateTruckStocksBulkForTruckDto,options
    );
  }
