import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import DriverSidebar from "../../../components/DriverSidebar";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import Driving from "../../../images/Driving.png";
import NotDriving from "../../../images/NotDriving.png";
import Pause from "../../../images/Pause.png";
import { adminTruckControllerFindMany } from "../../../api/service/admin-trucks/admin-trucks";
import { initToken } from '../../../utils/setAuthToken';
import { Truck } from '../../../api/model/truck';
import { adminTruckControllerUpdate } from '../../../api/service/admin-trucks/admin-trucks';
import { UpdateTruckDto } from '../../../api/model/updateTruckDto';
import InventoryModal from "../../../components/InventoryModal";
import XBtn from "../../../images/XBtn.png";

interface TruckInfoProps {

};

interface StatusTableCellProps {
  drivingStatus: string; // 이 부분을 DrivingStatus 타입으로 변경할 수도 있습니다.
};

const TruckInfo: React.FC<TruckInfoProps> = () => {
  const navigate = useNavigate();
  const [trucks, setTrucks] = useState<Truck[]>([]);
  const [editingTruck, setEditingTruck] = useState<string | null>(null);
  const [editUsedWoks, setEditUsedWoks] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTruck, setSelectedTruck] = useState<{id: string, licensePlateNumber: string, usedWoks: number} | null>(null);
  const [modalQuantity, setModalQuantity] = useState<string>("0");

  // 토큰을 초기화하고, 초기화된 토큰 값을 반환하는 함수
  const initTokenAndGetToken = async () => {
    await initToken(); 
    console.log("initToken:",initToken)
    // const token = getAccessToken(); /
    // return token;
  };

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleTruckUsedWoksEditClick = (item: {id: string, licensePlateNumber: string, usedWoks: number}) => {
    setSelectedTruck(item)
    openModal()
  }

  const handleModalQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const numericQuantity = value === '' ? 0 : Math.min(Math.max(parseInt(value, 10), 0), 4);
    setModalQuantity(numericQuantity.toString());
  }

  const handleSaveBtnClick = async () => {
    try {
      if(!selectedTruck) {
        alert('Please select a truck');
        return
      } else {
        const updateTruckUsedWoksData: UpdateTruckDto = {
          licensePlateNumber: selectedTruck.licensePlateNumber,
          usedWoks: Number(modalQuantity)
        }
        await adminTruckControllerUpdate(selectedTruck.id, updateTruckUsedWoksData)
        closeModal()
        fetchTrucks()
      }
    } catch (error: any) {
      alert('Error handleSaveBtnClick Truck UsedWoks, Please try again later.')
    }
  }
  
  // 트럭 목록 조회 요청 
  const fetchTrucks = async () => {
    try {
      const token = await initTokenAndGetToken();
      // await initToken(); // API 호출 전에 토큰 설정
      const response = await adminTruckControllerFindMany();
      const truck = response.data.items;
      console.log('트럭 데이터 아이템은', response.data.items);
      setTrucks(truck);
    } catch (error) {
      console.error('트럭 데이터를 불러오는 중 에러 발생:', error);
      alert('Server Error, Please try again later.');
    }
  };

  const calculateUsedWoks = (truck: Truck) => {
    let usedWoks = 0;
    if(truck.usedWoks){
      truck.usedWoks.wok1 && (usedWoks += 1);
      truck.usedWoks.wok2 && (usedWoks += 1);
      truck.usedWoks.wok3 && (usedWoks += 1);
      truck.usedWoks.wok4 && (usedWoks += 1);
    }
    return usedWoks;
  }

  // 사용하는 웍 변경하는 함수
  // const handleSaveClick = async (truckId: string) => {
  //   try {
  //     const truckToUpdate = trucks.find(t => t.licensePlateNumber === truckId);
  //     if (!truckToUpdate) {
  //       throw new Error('Truck not found');
  //     }
  
  //     const updateData: UpdateTruckDto = {
  //       // truckToUpdate 객체에서 필요한 다른 필드를 추가
  //       usedWoks: editUsedWoks,
  //       // licensePlateNumber: truckToUpdate,
  //     }; 
  
  //     await adminTruckControllerUpdate(truckId, updateData);
  //     console.log('Updated truck data:', updateData);
  //     await fetchTrucks(); // 트럭 목록 새로고침
  //   } catch (error) {
  //     // 타입 가드를 사용하여 error가 Error 인스턴스인지 확인
  //     if (error instanceof Error) {
  //       console.error('Error updating truck data:', error.message);
  //       // 서버에서 오류가 발생한 경우, 서버의 응답을 로그에 기록
  //       if ('response' in error && error.response) {
  //         console.error('Server response:', error.response);
  //       }
  //     } else {
  //       console.error('An unknown error occurred');
  //     }
  //     alert('트럭 데이터 업데이트에 실패했습니다. 잠시 후 다시 시도해주세요');
  //   }
  // };

  // 사용하는 웍 변경하는 함수
  const handleSaveClick = async (truckId: string) => {
    try {
      const truckToUpdate = trucks.find(t => t.licensePlateNumber === truckId);
      if (!truckToUpdate) {
        throw new Error('Truck not found');
      }
  
      // API 요청 본문 형식에 맞춰 updateData 객체 구성
      const updateData = {
        licensePlateNumber: truckToUpdate.licensePlateNumber, // 현재 트럭의 번호판 번호
        usedWoks: editUsedWoks, // 수정된 usedWoks 값
      };
  
      await adminTruckControllerUpdate(truckId, updateData);
      console.log('Updated truck data:', updateData);
      await fetchTrucks(); // 트럭 목록 새로고침
    } catch (error) {
      console.error('Error updating truck data:', error);
      alert('트럭 데이터 업데이트에 실패했습니다. 잠시 후 다시 시도해주세요.');
    }
  };
  
  
  
  useEffect(() => {
    const fetchData = async () => {
      await fetchTrucks();
    };

    fetchData();
  }, [navigate]); 

  return (
    <>
      <Header />
      <Sidebar />
      <Content>
        <DriverSidebar />
        <Title>Trucks Info</Title>
        <Area>
          <Table>
            <TableHeader>
              <TableCellTitle>Truck Number</TableCellTitle>
              <TableCellTitle>Truck Name</TableCellTitle>
              <TableCellTitle>Dashboard</TableCellTitle>
              <TableCellTitle>Driving Status</TableCellTitle>
              <TableCellTitle>Truck Availability</TableCellTitle>
              <TableCellTitle>usedWoks</TableCellTitle>
              <TableCellTitle>usedWoks Edit</TableCellTitle>
              <TableCellTitle></TableCellTitle>
            </TableHeader> 
            {/* Table Body 자리*/}
            <TableBody>
            {trucks.map(truck => (
              <TableRow key={truck.licensePlateNumber}>
                <TableCell>{truck.licensePlateNumber}</TableCell>
                <TableCell>{truck.name}</TableCell>
                <TableCell>{truck.distance}</TableCell>
                {/* <TableCell>{truck.drivingStatus}</TableCell> */}
                <StatusTableCell drivingStatus={truck.drivingStatus}>
                  {truck.drivingStatus}
                </StatusTableCell>
                <VehicleStatusTableCell vehicleStatus={truck.vehicleStatus}>
                  {truck.vehicleStatus}
                </VehicleStatusTableCell>
                <TableCell>
                    {calculateUsedWoks(truck)}
                </TableCell>
                <TableCell>
                <EditButton
                // onClick={() => handleEditClick(driver.id)}
                onClick={() => handleTruckUsedWoksEditClick({id: truck.id, licensePlateNumber: truck.licensePlateNumber, usedWoks: calculateUsedWoks(truck)})}
                >
                  Edit
                </EditButton>
                </TableCell>
                <TableCell>
                </TableCell>
                <TableCell>
                  {/* <InventoryBtn 
                    onClick={() => {
                      navigate("/driver/truckinfo/inventorycheck");
                    }}
                  >Inventory</InventoryBtn> */}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          </Table>
          <Button
            onClick={() => {
              navigate("/driver/truckinfo/addtruck");
            }}
          >+ Add Truck</Button>
          {/* --------------모달 컴포넌트 렌더링----------------*/}
          <InventoryModal isOpen={isModalOpen} onClose={closeModal}>
            {/* 모달 내용 */}
            <ModalContainer>
              <XBtnImage
                  src={XBtn}
                  alt={"xbtn"}
                  onClick={closeModal}
              />
              <ModalTitle>Edit Truck Used Woks</ModalTitle>
              <ModalArea>
                <TruckNumber>Truck Number</TruckNumber>
                <TruckNumberTextInput>{selectedTruck ? selectedTruck.licensePlateNumber : "Select Truck"}</TruckNumberTextInput>
                <UsedWoks>UsedWoks</UsedWoks>
                <UsedWoksTextInput
                    placeholder='0'
                    min="0"
                    max="4"
                    value={modalQuantity}
                    onChange={handleModalQuantityChange}
                />
                <SaveBtn onClick={handleSaveBtnClick}>
                  Save
                </SaveBtn>
              </ModalArea>
            </ModalContainer>
          </InventoryModal>
        </Area>
      </Content>
    </>
  );
};


const QuantityInput = styled.input`
    width: 50px; 
    padding: 5px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    text-align: center;
`;


const Content = styled.div`
  display: inline-flex;
`;

const Title = styled.div`
  font-family: 'Poppins';
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #22232b;
  margin-top: 78px;
  margin-left: 35px;
`;



const Image = styled.img`
    width: 8px;
    height: 8px;
    flex-shrink: 0;
    margin-right: 8px;
`

const Area = styled.div`
    height: 928px;
    width: 995px;
    margin-top: 54px;
    position: absolute;
    margin-top: 168px;
    margin-left: 354px;
`
const Button = styled.div`
    width: 135px;
    height: 38px;
    display: inline-flex;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 3px;
    background-color: #121317;
    color: #FFFFFF;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; 
    margin-left: 1191px;
    margin-right: 114px;
    margin-top: 411px;
`

const Table = styled.table`
    border-collapse: collapse;
    width: 100%;
`;

const TableHeader = styled.thead`
    /* ... 테이블 헤더 관련 스타일링 */
    border-top: 1px solid #E4E4E4;
    border-bottom : 1px solid #E4E4E4;
    padding-top: 12px;
    padding-bottom: 12px;
    width: 995px; 
    color:  #838796;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
`;

const TableBody = styled.tbody`
 
`;

const TableRow = styled.tr`
      
`;

const TableCell = styled.td`
    padding-top: 24px;
    /* color: #000; */
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
`;

const TableCellTitle = styled.td`
    color: #838796;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    padding-top: 12px;
    padding-bottom: 12px;   
`

const StatusTableCell = styled.td<StatusTableCellProps>`
  padding-top: 24px;
  color: ${(props) => {
    switch (props.drivingStatus) {
      case "Driving":
        return '#1DB954'; 
      case "Emergency":
        return '#FB3A3A'; 
      case "ADMIN_STOP":
        return '#FB3A3A';
      case "Pause":
        return '#F5C20D'; 
      case "Assigned":
        return '#1DB954'; 
      case "IDLE":
        return '#BABCC5'; //회색으로 바꿔야함
      default:
        return '#22232B'; 
    }
  }};
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const VehicleStatusTableCell = styled.td<{ vehicleStatus: string }>`
  padding-top: 24px;
  color: ${(props) => props.vehicleStatus === "AVAILABLE" ? '#1DB954' : '#FB3A3A'};
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const EditButton = styled.button`
  background: #FFFFFF;
  border: 1px solid #000000;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */
`

const XBtnImage = styled.img`
    flex-shrink: 0;
    display: flex;
    /* padding-left: 496px; */
    position: absolute;
    margin-top: 24px;
    margin-right: 32px;
    margin-left: 544px;
`

const ModalTitle = styled.div`
  color: #22232B;
  font-family: 'Poppins';
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; 
  letter-spacing: -0.192px;
  margin-top: 50px;
  margin-left: 30px;
`;

const TruckNumberTextInput = styled.div`
  display: flex;
  /* padding: 6px 12px; */
  align-items: center;
  gap: 10px;
  border-radius: 3px;
  background: #F1F1F4;
  border: none;
  width: 536px;
  height: 32px;
  text-align: center;
  align-self: center;
  position: relative;
  border-radius: 3px;
  margin-top: 8px;
  margin-bottom: 8px;
  //입력하지 못하도록
  pointer-events: none;
  color: #838796;
  font-family: 'Poppins';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; 
  padding-left: 8px;

  &:focus {
        outline: none; 
        border: 2px solid #ED6A2C; 
    }
`;

const ModalArea = styled.div`
  margin-left: 30px;
  margin-top: 43px;
`;

const TruckNumber = styled.div`
  color: #838796;
  font-family: 'Poppins';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; 
`;

const UsedWoksTextInput = styled.input`
  display: flex;
  gap: 10px;
  border-radius: 3px;
  background: #F1F1F4;
  border: none;
  width: 536px;
  height: 32px;
  position: relative;
  border-radius: 3px;
  margin-top: 8px;
  margin-bottom: 8px;
  padding-left: 8px;

  &:focus {
        outline: none; 
        border: 2px solid #ED6A2C; 
    }
`;

const UsedWoks = styled.div`
  color: #838796;
  font-family: 'Poppins';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; 
`;

const SaveBtn = styled.button`
  display: inline-flex;
  width: 86px;
  height: 38px;
  /* padding: 8px 24px; */
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 3px;
  background: #121317;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  color: #FFF;
  font-family: 'Poppins';
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; 
  margin-left: 462px;
  margin-top: 21px;
`

export default TruckInfo;

