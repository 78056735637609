import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

const ClientSidebar: React.FC = () => {
  const clientMenuList = [
    "Coupon",
    "BBQ Coin",
    "Push Notification",
    "Menu",
    "Notice",
    "Scheduled Orders",
    "Total Orders",
    "Canceled Orders",
    "Users",
    "Review",
  ];
  const clientSubMenuList = ["Menu", "Add / Edit"];
  const reviewSubMenuList = [
    "Review Content",
    "Add/Edit Review Content",
    "Matching Review Content",
  ];

  const navigate = useNavigate();
  const location = useLocation();

  const [clientMenu, setClientMenu] = useState<string>("");
  const [subClientMenu, setSubClientMenu] = useState<string>("");

  useEffect(() => {
    let path = location.pathname;
    setClientMenu(path);

    // Menu 관련 하위 경로 설정
    if (path.includes("/client/menu/menu")) {
      setSubClientMenu("Menu");
      setClientMenu("/client/menu");
    } else if (path.includes("/client/menu/option")) {
      setSubClientMenu("Option");
      setClientMenu("/client/menu");
    }

    // Review 관련 하위 경로 설정
    else if (path.includes("/client/review/content")) {
      setSubClientMenu("Review Content");
    } else if (path.includes("/client/review/addedit")) {
      setSubClientMenu("Add/Edit Review Content");
    } else if (path.includes("/client/review/matching")) {
      setSubClientMenu("Matching Review Content");
    } else {
      setSubClientMenu("");
    }
  }, [location]);

  return (
    <ClientSideMenuWrapper>
      {clientMenuList.map((menu) => {
        const diffMenu = menu.toLocaleLowerCase().replace(/\s+/g, "");

        if (diffMenu !== "menu" && diffMenu !== "review") {
          return (
            <ClientSideMenuContent
              key={menu}
              clicked={clientMenu.includes(diffMenu)}
              onClick={() => {
                setClientMenu(diffMenu);
                setSubClientMenu("");
                navigate(
                  "/client/" + menu.toLocaleLowerCase().replace(/\s+/g, "")
                );
              }}
            >
              {menu}
            </ClientSideMenuContent>
          );
        } else if (diffMenu === "menu") {
          return (
            <ClientSideMenuContent
              key={menu}
              clicked={clientMenu.includes(diffMenu)}
              onClick={() => {
                setClientMenu(diffMenu);
              }}
            >
              {menu}
              {clientMenu.includes("menu") ? (
                <div>
                  {clientSubMenuList.map((subMenu) => {
                    let loc = "";
                    if (subMenu === "Menu") {
                      loc = "menu";
                    } else if (subMenu === "Option") {
                      loc = "option";
                    }
                    return (
                      <ClientSubSideMenuContent
                        key={subMenu}
                        clicked={subClientMenu.includes(subMenu)}
                        onClick={() => {
                          setSubClientMenu(subMenu);
                          navigate("/client/menu/" + loc);
                        }}
                      >
                        {subMenu}
                      </ClientSubSideMenuContent>
                    );
                  })}
                </div>
              ) : (
                <></>
              )}
            </ClientSideMenuContent>
          );
        } else if (diffMenu === "review") {
          return (
            <ClientSideMenuContent
              key={menu}
              clicked={clientMenu.includes(diffMenu)}
              onClick={() => {
                setClientMenu(diffMenu);
                // Review 메뉴를 클릭했을 때 하위 메뉴가 토글되도록 설정
                setSubClientMenu(subClientMenu === "" ? "Review Content" : "");
              }}
            >
              {menu}
              {clientMenu.includes("review") && (
                <div>
                  {reviewSubMenuList.map((subMenu) => {
                    let loc = "";
                    if (subMenu === "Review Content") {
                      loc = "content";
                    } else if (subMenu === "Add/Edit Review Content") {
                      loc = "addedit";
                    } else if (subMenu === "Matching Review Content") {
                      loc = "matching";
                    }
                    return (
                      <ClientSubSideMenuContent
                        key={subMenu}
                        clicked={subClientMenu.includes(subMenu)}
                        onClick={(e) => {
                          e.stopPropagation();
                          setSubClientMenu(subMenu);
                          navigate("/client/review/" + loc);
                        }}
                      >
                        {subMenu}
                      </ClientSubSideMenuContent>
                    );
                  })}
                </div>
              )}
            </ClientSideMenuContent>
          );
        }
      })}
    </ClientSideMenuWrapper>
  );
};

interface StyledButtonProps {
  clicked: boolean;
}

const ClientSubSideMenuContent = styled.div<StyledButtonProps>`
  color: ${({ clicked }) => (clicked ? "#22232B" : "#838796")};
  font-weight: ${({ clicked }) => (clicked ? 700 : 400)};

  margin: 24px 0px 24px 20px;
`;

const ClientSideMenuContent = styled.div<StyledButtonProps>`
  color: ${({ clicked }) => (clicked ? "#22232B" : "#838796")};
  font-weight: ${({ clicked }) => (clicked ? 700 : 400)};

  margin: 24px 0px 24px 20px;
`;

const ClientSideMenuWrapper = styled.div`
  padding-top: 48px;
  padding-left: 68px;
  width: 250px;
  min-width: 250px;
  height: 100vh;
  background-color: #eeeeee;
`;

export default ClientSidebar;
