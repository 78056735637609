import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import ClientSidebar from "../../../components/ClientSidebar";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import Menu from "../../../images/Menu.png";
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { adminMenuControllerFindMany, adminMenuControllerUpdate,adminMenuControllerDelete } from "../../../api/service/admin-menu/admin-menu";
import { AdminMenuControllerFindManyParams } from "../../../api/model/adminMenuControllerFindManyParams"

interface MenusProps {

};

const Menus: React.FC<MenusProps> = () => {
  const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [isTruckTypeDropdownOpen, setIsTruckTypeDropdownOpen] = useState(false);
    const [isDrivingAreaDropdownOpen, setIsDrivingAreaDropdownOpen] = useState(false);
    const [isToggleOn, setIsToggleOn] = useState(false); // 초기 상태는 false (비활성화)로 설정
    const truckTypeDropdownRef = useRef<HTMLDivElement>(null);
    const drivingAreaDropdownRef = useRef<HTMLDivElement>(null);
    const [menus, setMenus] = useState<any[]>([]); // 메뉴 목록 상태 초기화, 적절한 타입으로 대체 필요
    const [isActive, setIsActive] = useState(false); //토글 버튼
    const closeDropdowns = () => {
          setIsOpen(false);
      };
  
    const handleClickOutside = (event: MouseEvent) => {
    const isClickedInsideTruckTypeDropdown = truckTypeDropdownRef.current?.contains(event.target as Node);
    const isClickedInsideDrivingAreaDropdown = drivingAreaDropdownRef.current?.contains(event.target as Node);
    
    if (!isClickedInsideTruckTypeDropdown && !isClickedInsideDrivingAreaDropdown) {
            setIsTruckTypeDropdownOpen(false);
            setIsDrivingAreaDropdownOpen(false);
        }
    };

    // 드래그 앤 드롭 이벤트를 처리하는 함수
    const onDragEnd = (result: DropResult) => {
        if (!result.destination) return;

        const newMenus = Array.from(menus);
        const [reorderedItem] = newMenus.splice(result.source.index, 1);
        newMenus.splice(result.destination.index, 0, reorderedItem);

        setMenus(newMenus);
    };



    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);


      useEffect(() => {
          document.addEventListener('click', closeDropdowns);
          return () => {
              document.removeEventListener('click', closeDropdowns);
          };
      }, []);


//       useEffect(() => {
//         // API 요청을 보내고 데이터를 가져오는 비동기 함수를 호출
//         const fetchData = async () => {
//           try {
//             const response = await fetch('API_ENDPOINT_URL'); // API의 엔드포인트 URL로 변경
//             const data = await response.json();
//             setMenus(data); // 받아온 데이터를 menus 상태에 저장
//           } catch (error) {
//             console.error('Error fetching data:', error);
//           }
//         };
      
//         fetchData(); // fetchData 함수 호출
//       }, []);
      
//   // 메뉴 추가 함수
// const handleToggle = async (menuId) => {
//   try {
//     // API 요청을 통해 메뉴 상태 업데이트
//     const response = await fetch(`API_ENDPOINT_URL/${menuId}/toggle`, {
//       method: 'POST',
//     });
//     if (response.ok) {
//       // 성공적으로 업데이트되면 메뉴 목록을 다시 불러옴
//       fetchData();
//     } else {
//       console.error('Failed to toggle menu state');
//     }
//   } catch (error) {
//     console.error('Error toggling menu state:', error);
//   }
// };

// // 메뉴 삭제 함수
// const handleDelete = async (menuId) => {
//   try {
//     // API 요청을 통해 메뉴 삭제
//     const response = await fetch(`API_ENDPOINT_URL/${menuId}`, {
//       method: 'DELETE',
//     });
//     if (response.ok) {
//       // 성공적으로 삭제되면 메뉴 목록을 다시 불러옴
//       fetchData();
//     } else {
//       console.error('Failed to delete menu');
//     }
//   } catch (error) {
//     console.error('Error deleting menu:', error);
//   }
// };

// 기존 내 코드
//   return (
//     <>
//       <Header />
//       <Sidebar />
//       <Content>
//         <ClientSidebar />
//         <Title>Menu</Title>
//         <Area>
//                 <Table>
//                     <TableHeader>
//                         <TableCellTitle>

//                         </TableCellTitle>
//                         <TableCellTitle>Name</TableCellTitle>
//                         <TableCellTitle>Type</TableCellTitle>
//                         <TableCellTitle>Price</TableCellTitle>
//                         <TableCellTitle>State</TableCellTitle>
//                         <TableCellTitle></TableCellTitle>
//                         <TableCellTitle></TableCellTitle>
//                     </TableHeader> 
//                 <TableBody>                 
//                     <TableRow> {/* 2. 테이블 행 전체의 위치를 이동시킬 수 있음. */}
//                             <TableCell>
//                             <MenuImg src={Menu} /> {/* 1. 이미지를 클릭 후 드래그 하면*/}
//                             </TableCell>
//                             <TableCell>Yangnyum Galbi</TableCell>
//                             <TableCell>Main</TableCell>
//                             <TableCell>$65</TableCell>
//                             <TableCell>
//                             <ToggleSwitch>
//                               <CheckBox
//                                 type="checkbox"
//                                 checked={isActive}
//                                 onChange={() => setIsActive(!isActive)}
//                               />
//                               <ToggleSlider />
//                             </ToggleSwitch>
//                             </TableCell>
//                             <TableCell>
//                                 <EditBtn>Edit</EditBtn>
//                             </TableCell>
//                             <TableCell>
//                                 <DeleteBtn>Delete</DeleteBtn>
//                             </TableCell>
//                     </TableRow>
//                 </TableBody>
//                 </Table>
//                 <Button
//                 onClick={() => {
//                   navigate("/client/menu/menu/addmenu");
//                 }}
//                 >+ Add Menu</Button>
//         </Area>
      
//       </Content>
//     </>
//   );

//api 연동을 고려한 코드 
return (
    <>
      <Header />
      <Sidebar />
      <Content>
        <ClientSidebar />
        <Title>Menu</Title>
        <Area>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable-menus">
              {(provided) => (
                <Table {...provided.droppableProps} ref={provided.innerRef}>
                  <TableHeader>
                    <TableCellTitle></TableCellTitle>
                    <TableCellTitle>Name</TableCellTitle>
                    <TableCellTitle>Type</TableCellTitle>
                    <TableCellTitle>Price</TableCellTitle>
                    <TableCellTitle>State</TableCellTitle>
                    <TableCellTitle></TableCellTitle>
                    <TableCellTitle></TableCellTitle>
                  </TableHeader>
                  <TableBody>
                    {menus.map((menu, index) => (
                      <Draggable key={menu.id} draggableId={menu.id.toString()} index={index}>
                        {(provided) => (
                          <TableRow
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <TableCell>
                              <MenuImg src={Menu} {...provided.dragHandleProps} />
                            </TableCell>
                            <TableCell>{menu.name}</TableCell>
                            <TableCell>{menu.type}</TableCell>
                            <TableCell>{menu.price}</TableCell>
                            <TableCell>
                              <ToggleSwitch>
                                <CheckBox
                                  type="checkbox"
                                  checked={isActive}
                                  onChange={() => setIsActive(!isActive)}
                                />
                                <ToggleSlider />
                              </ToggleSwitch>
                            </TableCell>
                            <TableCell>
                              <EditBtn>Edit</EditBtn>
                            </TableCell>
                            <TableCell>
                              <DeleteBtn>Delete</DeleteBtn>
                            </TableCell>
                          </TableRow>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </TableBody>
                </Table>
              )}
            </Droppable>
          </DragDropContext>
          <Button
            onClick={() => {
              navigate("/client/menu/menu/addmenu");
            }}
          >
            + Add Menu
          </Button>
        </Area>
      </Content>
    </>
  );
  


};

const Content = styled.div`
  display: inline-flex;
`;

const Title = styled.div`
  font-family: 'Poppins';
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #22232b;
  margin-top: 78px;
  margin-left: 35px;
`;

const Area = styled.div`
    height: 928px;
    width: 995px;
    margin-top: 54px;
    position: absolute;
    margin-top: 168px;
    margin-left: 354px;
`

const MenuImg = styled.img`
    width: 20px;
    height: 20px;
`
const Button = styled.div`
    width: 154px;
    height: 38px;
    display: inline-flex;
    /* padding: 8px 24px; */
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 3px;
    background-color: #121317;
    color: #FFFFFF;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; 
    margin-left: 750px;
    margin-right: 114px;
    margin-top: 411px;
`

const DeleteBtn = styled.div`
    width: 50px;
    height: 25px;
    display: inline-flex;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 3px;
    background: #121317;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    color: #FFFFFF;
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px; 
    cursor: pointer;
`

const EditBtn = styled.div`
    width: 43px;
    height: 25px;
    display: inline-flex;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 3px;
    border: 1px solid #9EA1AE;
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    color: var(--content-gray-900, #22232B);
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px; 
    cursor: pointer;
`

const Table = styled.table`
    border-collapse: collapse;
    width: 100%;
`;

const TableHeader = styled.thead`
    /* ... 테이블 헤더 관련 스타일링 */
    border-top: 1px solid #E4E4E4;
    border-bottom : 1px solid #E4E4E4;
    padding-top: 12px;
    padding-bottom: 12px;
    width: 995px; 
    color:  #838796;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
`;

const TableBody = styled.tbody`
 
`;

const TableRow = styled.tr`
      
`;

const TableCell = styled.td`
    padding-top: 24px;
    color: #000;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
`;

const TableCellTitle = styled.td`
    color: #838796;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    padding-top: 12px;
    padding-bottom: 12px;   
`

const ToggleSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 47.7px;
  height: 23.33px;
`;

const ToggleSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }
`;

const CheckBox = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${ToggleSlider} {
    background-color: #ED6A2C;
  }

  &:focus + ${ToggleSlider} {
    box-shadow: 0 0 1px #2196F3;
  }

  &:checked + ${ToggleSlider}:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
`;

export default Menus;



