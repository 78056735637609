import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from "react-router-dom";
import Login from "../src/pages/login/LogIn";
import Client from "./pages/client/Client";
import Coupon from "./pages/client/coupon/Coupon";
import AddCoupon from "./pages/client/coupon/AddCoupon";
import EditCoupon from "./pages/client/coupon/EditCoupon";
import AddMenu from "./pages/client/menu/AddMenu";
import EditMenu from "./pages/client/menu/EditMenu";
import AddEditMenu from "./pages/client/menu/AddEditMenu";
import Menus from "./pages/client/menu/Menus";
import BBQCoin from "./pages/client/BBQCoin";
import Notice from "./pages/client/notice/Notice";
import PostNotice from "./pages/client/notice/PostNotice";
import ScheduleDorders from "./pages/client/Scheduledorders";
import TotalOrders from "./pages/client/TotalOrders";
import Users from "./pages/client/Users";
import Driver from "./pages/driver/Driver";
import DriverInfo from "./pages/driver/driverInfo/DriverInfo";
import Drivers from "./pages/driver/driverInfo/Drivers";
import Start from "./pages/driver/driverInfo/Start";
import Truck from "./pages/driver/driverInfo/Truck";
import Work from "./pages/driver/driverInfo/Work";
import Inventory from "./pages/driver/Inventory";
import InventoryRegistration from "./pages/driver/InventoryRegistration";
import AddTruck from "./pages/driver/truckInfo/AddTruck";
// import InvntoryCheck from "./pages/driver/truckInfo/InvetoryCheck";
import TruckInfo from "./pages/driver/truckInfo/TruckInfo";
import TruckDeliveryStatus from "./pages/driver/truckDeliveryStatus/TruckDeliveryStatus";
import PushNotification from "./pages/client/pushnoti/PushNotification";
import AddPushNotification from "./pages/client/pushnoti/AddPushNotification";
import CanceledOrders from "./pages/client/CanceledOrders";
import Home from "./pages/Home";
import AddDrivers from "./pages/driver/driverInfo/AddDrivers";
import EditDrivers from "./pages/driver/driverInfo/EditDrivers";
import Review from "./pages/client/review/Review";
import AddEditReview from "./pages/client/review/AddEditReview";
import MatchReview from "./pages/client/review/MatchReview";
import axios from "axios";
import { BASE_URL } from "./constants/axios";
import { initToken } from "./utils/setAuthToken";


// axios의 전역 기본값 설정
axios.defaults.baseURL = BASE_URL;

function checkLoginStatus() {
  // return true; // 또는 확인 결과에 따라 true 또는 false를 반환
  // localStorage에서 사용자 정보를 가져오기
  const userJson = localStorage.getItem('user');

  // 사용자 정보가 있다면 로그인 상태로 간주
  // 실제로는 토큰 등을 사용하여 더 정교한 확인이 필요합니다.
  return !!userJson;
}


function App() {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  // const isLoggedIn = checkLoginStatus();

  // useEffect(() => {
  //   initToken(); // 컴포넌트가 마운트될 때 initToken 함수 호출
  // }, []);

  useEffect(() => {
    const checkAndInitToken = async () => {
      const loggedIn = checkLoginStatus();
      setIsLoggedIn(loggedIn);

      if (loggedIn) {
        // 로그인되어 있을 경우에만 initToken 함수 호출
        await initToken();

       // initToken 함수 호출 후에 isLoggedIn을 true로 설정
       setIsLoggedIn(true);
      }
    };

    checkAndInitToken();
  }, []);

  return (
    <Router>
      <Routes>
      <Route
          path="/"
          element={isLoggedIn ? <Driver /> : <Navigate to="/login" />}
      />
        <Route path="/login" element={<Login />} />
        <Route path="/driver" element={<Driver />} />
        <Route path="/driver/truckinfo" element={<TruckInfo />} />
   
        <Route path="/driver/truckdeliverystatus" element={<TruckDeliveryStatus />} />
        <Route path="/driver/truckinfo/addtruck" element={<AddTruck />} />
        <Route path="/driver/driverinfo" element={<DriverInfo />} />
        <Route path="/driver/driverinfo/drivers" element={<Drivers />} />
        <Route path="/driver/driverinfo/drivers/adddriver" element={<AddDrivers />} />
        <Route path="/driver/driverinfo/drivers/editdriver" element={<EditDrivers />} />
        <Route path="/driver/driverinfo/work" element={<Work />} />
        <Route path="/driver/driverinfo/truck" element={<Truck />} />
        <Route path="/driver/driverinfo/start" element={<Start />} />

        <Route path="/driver/inventory" element={<Inventory />} />
        <Route path="/driver/inventoryregistration" element={<InventoryRegistration />} />

        <Route path="/client" element={<Client />} />

        <Route path="/client/coupon" element={<Coupon />} />
        <Route path="/client/coupon/addcoupon" element={<AddCoupon />} />
        <Route path="/client/coupon/editcoupon" element={<EditCoupon />} />

        <Route path="/client/bbqcoin" element={<BBQCoin />} />

        <Route path="/client/pushnotification" element={<PushNotification />} />
        <Route path="/client/pushnotifi/addnotifi" element={<AddPushNotification />} />

        <Route path="/client/menu" element={<AddEditMenu />} />
        <Route path="/client/menu/menu" element={<Menus />} />
        <Route path="/client/menu/menu/addmenu" element={<AddMenu />} />
        <Route path="/client/menu/menu/editmenu" element={<EditMenu />} />


        <Route path="/client/notice" element={<Notice />} />
        <Route path="/client/notice/postnotice" element={<PostNotice />} />

        <Route path="/client/scheduledorders" element={<ScheduleDorders />} />

        <Route path="/client/totalorders" element={<TotalOrders />} />

        <Route path="/client/canceledorders" element={<CanceledOrders />} />

        <Route path="/client/users" element={<Users />} />

        <Route path="/client/review/content" element={<Review />} />
        <Route path="/client/review/addedit" element={<AddEditReview />} />
        <Route path="/client/review/matching" element={<MatchReview />} />
      </Routes>
    </Router>
  );
}

export default App;
