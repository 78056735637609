import React, { useCallback, useState, useRef, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import DriverSidebar from "../../../components/DriverSidebar";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import DropDown from "../../../images/DropDown.png";
import { adminTruckDriverControllerFindMany, adminTruckDriverControllerCreateAttendance, adminTruckDriverControllerRemoveAttendance } from '../../../api/service/admin-truck-drivers/admin-truck-drivers';
import { TruckDriver } from "../../../api/model/truckDriver";
import {TrucDriverStatus} from "../../../api/model";

interface WorkProps {

};

interface TableCellProps {
    status?: string; 
  }

const Work: React.FC<WorkProps> = () => {
  const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [isTruckTypeDropdownOpen, setIsTruckTypeDropdownOpen] = useState(false);
    const [isDrivingAreaDropdownOpen, setIsDrivingAreaDropdownOpen] = useState(false);
    const [selectedTruckType, setSelectedTruckType] = useState<string | null>(null);
    const truckTypeDropdownRef = useRef<HTMLDivElement>(null);
    const drivingAreaDropdownRef = useRef<HTMLDivElement>(null);
    const [selectedDriverId, setSelectedDriverId] = useState<null | string>(null);
    const [drivers, setDrivers] = useState<TruckDriver[]>([]); 
    const [loading, setLoading] = useState(false);
    const [dropdownStates, setDropdownStates] = useState<{[key: string]: boolean}>({});
    const [selectedCommuteStatus, setSelectedCommuteStatus] = useState<{ [driverId: string]: string }>({});
    const [isButtonClicked, setIsButtonClicked] = useState(false);
    
    // 트럭 운전자 목록 조회
    const fetchTruckDrivers = useCallback(async () => {
        setLoading(true);
    
        try {
        const { data } = await adminTruckDriverControllerFindMany();
        setDrivers(data.items); 
        console.log("works 페이지에서 트럭 운전자 목록 조회:", 
        data.items
        );
        } catch (error) {
        console.error('트럭 운전자 목록 조회 에러:', error);
        }
    
        setLoading(false);
    }, []);

    useEffect(() => {
        fetchTruckDrivers();
      }, [fetchTruckDrivers]);
      

    // 출근/퇴근 요청 처리
    const handleAttendance = async () => {
        if (!selectedDriverId) return;
        const commuteStatus = selectedCommuteStatus[selectedDriverId];
        try {
            if (commuteStatus === "At work") {
                await adminTruckDriverControllerCreateAttendance(selectedDriverId);
            } else if (commuteStatus === "Leave work") {
                await adminTruckDriverControllerRemoveAttendance(selectedDriverId);
            }
            console.log("출퇴근 요청 처리 성공", commuteStatus)
            navigate("/driver/driverinfo/drivers")
            alert("Commute status updated successfully!");
        } catch (error) {
            console.error("출퇴근 요청 처리 에러", error);
            alert("Failed to update commute status.");
        }

        setIsButtonClicked(true);
        setTimeout(() => setIsButtonClicked(false), 2000);
    };

    const closeDropdowns = () => {
          setIsOpen(false);
      };

    const handleClickOutside = (event: MouseEvent) => {
        const isClickedInsideTruckTypeDropdown = truckTypeDropdownRef.current?.contains(event.target as Node);
        const isClickedInsideDrivingAreaDropdown = drivingAreaDropdownRef.current?.contains(event.target as Node);
        
        if (!isClickedInsideTruckTypeDropdown && !isClickedInsideDrivingAreaDropdown) {
            setIsTruckTypeDropdownOpen(false);
            setIsDrivingAreaDropdownOpen(false);
        }
    };

    const toggleTruckTypeDropdown = () => {
        setIsTruckTypeDropdownOpen(!isTruckTypeDropdownOpen);
        setIsDrivingAreaDropdownOpen(false);
    };
    
    const handleTruckTypeItemClick = (item: string) => {
        setSelectedTruckType(item);
        setIsTruckTypeDropdownOpen(false);
    };

    const handleOutsideClick = (event:any) => {
        if (truckTypeDropdownRef.current && !truckTypeDropdownRef.current.contains(event.target)) {
            setIsTruckTypeDropdownOpen(false);
        }
        if (drivingAreaDropdownRef.current && !drivingAreaDropdownRef.current.contains(event.target)) {
            setIsDrivingAreaDropdownOpen(false);
        }
    };

    // 드롭다운 토글 함수
    const toggleDropdown = (driverId: string) => {
        setDropdownStates(prev => ({ ...prev, [driverId]: !prev[driverId] }));
    };

    // 드롭다운 아이템 클릭 핸들러
    const handleDropdownItemClick = (driverId: string, item: string) => {
        setSelectedCommuteStatus(prev => ({ ...prev, [driverId]: item }));
        setSelectedDriverId(driverId);
        toggleDropdown(driverId);
    };


    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);


    useEffect(() => {
          document.addEventListener('click', closeDropdowns);
          return () => {
              document.removeEventListener('click', closeDropdowns);
          };
    }, []);

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);
    

  return (
    <>
      <Header />
      <Sidebar />
      <Content>
        <DriverSidebar />
        <Title>Driver Commute</Title>
        <Area>
                <Table>
                    <TableHeader>
                        <TableCellTitle>Driver</TableCellTitle>
                        <TableCellTitle>Commute</TableCellTitle>
                        <TableCellTitle></TableCellTitle>
                    </TableHeader> 
                <TableBody>
                    {drivers.map((driver) => (
                        <TableRow key={driver.id}>
                            <TableCell>{driver.firstName + ' ' + driver.lastName}</TableCell>
                            <TableCell>
                            {/*드롭다운 영역*/}
                            <DropdownContainer>
                            <DropdownButton 
                                onClick={() => toggleDropdown(driver.id)}
                                status={selectedCommuteStatus[driver.id] || null}>
                                <span>{selectedCommuteStatus[driver.id] || 'Select Status'}</span>
                                <Image
                                        src={DropDown}
                                        alt='dropdown'
                                        onClick={toggleTruckTypeDropdown}
                                        // background-color={isTruckTypeDropdownOpen ? "#2FAF4B" : "#F1F1F4"}
                                    />
                            </DropdownButton>
                            <DropdownContent isOpen={dropdownStates[driver.id] || false}>
                                {driver.status === TrucDriverStatus.WORKING ?
                                    <DropdownItem
                                        isSelected={selectedCommuteStatus[driver.id] === 'Leave work'}
                                        onClick={() => handleDropdownItemClick(driver.id, 'Leave work')}>
                                        Leave work
                                    </DropdownItem>
                                    :
                                    <DropdownItem
                                        isSelected={selectedCommuteStatus[driver.id] === 'At work'}
                                        onClick={() => handleDropdownItemClick(driver.id, 'At work')}>
                                        At work
                                    </DropdownItem>
                                }
                            </DropdownContent>
                        </DropdownContainer>
                            </TableCell>
                            <TableCell>
                                <SaveBtn onClick={handleAttendance} clicked={isButtonClicked}>Save</SaveBtn>
                            </TableCell>
                        </TableRow>
                               ))}
                    </TableBody>
                </Table>
        </Area>
      </Content>
    </>
  );
};


const Content = styled.div`
  display: inline-flex;
`;

const Title = styled.div`
  font-family: 'Poppins';
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #22232b;
  margin-top: 78px;
  margin-left: 35px;
`;

const Area = styled.div`
    height: 928px;
    width: 995px;
    margin-top: 54px;
    position: absolute;
    margin-top: 168px;
    margin-left: 354px;
`

const DropdownContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    margin-top: 18px;
    margin-bottom: 24px;
`;

const DropdownButton = styled.button<{ status: string | null }>`
    background-color: ${(props) => {
        if (props.status === "At work") return "#D5FBD9";
        if (props.status === "Leave work") return "#FF3866CC";
        return "#F1F1F4"; // 기본 배경색
    }};
    color: ${(props) => {
        if (props.status === "At work") return "#2FAF4B";
        if (props.status === "Leave work") return "#8B0000";
        return "#838796"; // 기본 글씨색
    }};
    border: none;
    padding: 10px;
    cursor: pointer;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; 
    width: 240px;
    height: 32px;
    display: flex; 
    align-items: center; 
    gap: 8px; 
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;

    span {
        white-space: nowrap;
    }
`;


const DropdownContent = styled.div<{ isOpen: boolean }>`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  position: absolute;
  top: 100%;
  left: 0;
  width: 240px; // 드롭다운 버튼의 너비와 동일하게 설정
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 4px;
  overflow: hidden;
`;


const DropdownItem = styled.div<{ isSelected: boolean }>`
    padding: 3px;
    width: 240px;
    cursor: pointer;
    background-color: ${(props) => (props.isSelected ? "#f1f1f1" : "initial")};
    &:hover {
    background-color: #f1f1f1;
    }
`;

const Image = styled.img`
    flex-shrink: 0;
    display: inline-block;
    padding-left: 196px;
    /* margin-left: 306px; */
    position: absolute;
    color: #2FAF4B; 
`

const SaveBtn = styled.div<{ clicked: boolean }>`
    width: 50px;
    height: 25px;
    display: inline-flex;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 3px;
    /* background: #121317; */
    background: ${props => props.clicked ? "#FFFFFF" : "#121317"};
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    /* color: #FFFFFF; */
    color: ${props => props.clicked ? "#121317" : "#FFFFFF"};
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px; 
    cursor: pointer;
`

const Table = styled.table`
    border-collapse: collapse;
    width: 100%;
`;

const TableHeader = styled.thead`
    /* ... 테이블 헤더 관련 스타일링 */
    border-top: 1px solid #E4E4E4;
    border-bottom : 1px solid #E4E4E4;
    padding-top: 12px;
    padding-bottom: 12px;
    width: 995px; 
    color:  #838796;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
`;

const TableBody = styled.tbody`
 
`;

const TableRow = styled.tr`
      
`;

const TableCell = styled.td`
    color: #000;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;  
    width: 300px;
`;

const TableCellTitle = styled.td`
    color: #838796;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    padding-top: 12px;
    padding-bottom: 12px;   
`



export default Work;