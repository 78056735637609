import React, { useState, useRef, useEffect } from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import styled from "styled-components";
import DriverSidebar from "../../components/DriverSidebar";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import DropDown from "../../images/DropDown.png";
import InventoryModal from '../../components/InventoryModal';
import XBtn from "../../images/XBtn.png";
import {Truck, TruckStockForTruck} from "../../api/model";
import {initToken} from "../../utils/setAuthToken";
import {adminTruckControllerFindMany} from "../../api/service/admin-trucks/admin-trucks";
import {
    adminTruckStockControllerFindOneByTruckId
} from "../../api/service/admin-trucks-stock-for-truck/admin-trucks-stock-for-truck";

interface InventoryProps {

};


const Inventory: React.FC<InventoryProps> = () => {
    const [inventorys, setInventorys] = useState<TruckStockForTruck[]>([])
    const [trucks, setTrucks] = useState<Truck[]>([])
    const [isOpen, setIsOpen] = useState(false);
    const [isTruckTypeDropdownOpen, setIsTruckTypeDropdownOpen] = useState(false);
    const [isDrivingAreaDropdownOpen, setIsDrivingAreaDropdownOpen] = useState(false);
    const [selectedTruck, setSelectedTruck] = useState<{id: string, licensePlateNumber: string, truckArray: string} | null>(null);
    const truckTypeDropdownRef = useRef<HTMLDivElement>(null);
    const drivingAreaDropdownRef = useRef<HTMLDivElement>(null);
    const [selectedInventory, setSelectedInventory] = useState<string | null>(null);
    const inventoryDropdownRef = useRef<HTMLDivElement>(null);
    const [isDropdown1Active, setIsDropdown1Active] = useState(false); // 배열 드롭을 선택해야 트럭 넘버 활성화되도록
    
      const closeDropdowns = () => {
          setIsOpen(false);
      };

      const handleGetTruckStocks= async (truckId:string) => {
          try {
              const response = await adminTruckStockControllerFindOneByTruckId(truckId)
              setInventorys(response.data) //TODO: 트럭의 재고 배열정보를 state에 저장
          } catch (error) {
                console.error('트럭 재고를 불러오는 중 에러 발생:', error);
                alert('Server Error, Please try again later.');
          }
      }

      const handleClickOutside = (event: MouseEvent) => {
        const isClickedInsideTruckTypeDropdown = truckTypeDropdownRef.current?.contains(event.target as Node);
        const isClickedInsideDrivingAreaDropdown = drivingAreaDropdownRef.current?.contains(event.target as Node);
        
        if (!isClickedInsideTruckTypeDropdown && !isClickedInsideDrivingAreaDropdown) {
            setIsTruckTypeDropdownOpen(false);
            setIsDrivingAreaDropdownOpen(false);
        }
    };
      const initTokenAndGetToken = async () => {
          await initToken()
      }
      const fetchTrucks = async () => {
          try {
              const token = await initTokenAndGetToken();
              const response = await adminTruckControllerFindMany();
              const truck = response.data.items;
              setTrucks(truck)
          } catch (error) {
              console.error('트럭 데이터를 불러오는 중 에러 발생:', error);
              alert('Server Error, Please try again later.');
          }
      }
      const location = useLocation()
      const selectedTruckFromPrevPage = location.state?.selectedTruck

    useEffect(() => {
        if(selectedTruckFromPrevPage){
            handleTruckTypeItemClick(selectedTruckFromPrevPage)
        }
    }, [trucks, selectedTruckFromPrevPage]);

      useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);


      useEffect(() => {
          document.addEventListener('click', closeDropdowns);
          return () => {
              document.removeEventListener('click', closeDropdowns);
          };
      }, []);

      //TODO:화면 로딩시 최초 1회만 트럭데이터 불러오기 및 트럭 넘버 드롭다운 활성화
      useEffect(() => {
          fetchTrucks()
          setIsDropdown1Active(true)
      }, [])

    const toggleTruckTypeDropdown = () => {
        setIsTruckTypeDropdownOpen(!isTruckTypeDropdownOpen);
        setIsDrivingAreaDropdownOpen(false);
    };

    // TODO: 트럭 넘버 드롭다운 메뉴 클릭시
    const handleTruckTypeItemClick = (item: {id: string, licensePlateNumber: string, truckArray: string }) => {
        setSelectedTruck(item); // TODO: 선택된 트럭의 licensePlateNumber를 보여주기 위해 state에 저장
        setSelectedInventory(item.truckArray) // TODO: 선택된 트럭의 배열 정보를 보여주기 위해 state에 저장
        handleGetTruckStocks(item.id) // TODO: 선택된 트럭의 재고 정보를 불러오기 위해 함수 호출
        setIsTruckTypeDropdownOpen(false);
    };

    // TODO: inventorys.map을 통해서 뿌려진 stock 각각의 레시피 이름과 수량을 받아와서 뿌려줌
    const InventoryBox: React.FC<{ name: string, menuQty: number }> = ({
      name, menuQty
    }) => (
      <StyledInventoryBox>
        <div style={{ fontWeight: "500", fontSize: "14px", lineHeight: "20px", color: "#9EA1AE" }}>
          {name || "Empty"}
        </div>
        <div style={{ marginTop: "70px", color: "#838796", fontWeight: "bold", fontSize: "14px" }}>
          {menuQty || 0}
        </div>
      </StyledInventoryBox>
    );

  return (
    <>
      <Header />
      <Sidebar />
      <Content>
        <DriverSidebar />
        <Title>Inventory Check</Title>
        <Area>
                <TitleDetail>Truck Number</TitleDetail>
                {/*트럭 넘버 드롭다운 메뉴 */}
                <DropdownContainer1 ref={truckTypeDropdownRef}>
                    <DropdownButton>
                        <SelectedItemText>{selectedTruck ? selectedTruck.licensePlateNumber : "Select Truck"}</SelectedItemText>
                        <DropDownImage
                            src={DropDown}
                            alt="dropdown"
                            onClick={toggleTruckTypeDropdown}
                        />
                    </DropdownButton>
                    <DropdownContent1 isOpen={isTruckTypeDropdownOpen && isDropdown1Active}> {/* isActive 여부에 따라 활성화 */}
                        {trucks.map((truck, index) => (
                            <DropdownItem1 key={index} onClick={() => handleTruckTypeItemClick({id: truck.id, licensePlateNumber: truck.licensePlateNumber, truckArray: `${truck.slotArray?.row}X${truck.slotArray?.col}`})}>
                            {truck.licensePlateNumber}
                            </DropdownItem1>
                        ))}
                    </DropdownContent1>
                </DropdownContainer1>

                <TitleDetail>Select Arrays</TitleDetail> 
                {/*배열 드롭다운 메뉴 */}
                <DropdownContainer ref={inventoryDropdownRef}>
                <DropdownButton>
                  <SelectedItemText>{selectedInventory || "A truck has not been selected yet"}</SelectedItemText>
                  <ContentBox/>
                </DropdownButton>
                </DropdownContainer>

                 {/* 재고 입력 박스 */}
                <InventoryBoxes>
                  {inventorys.map((stock, index) => (
                    <InventoryBox
                      key={index}
                      name={stock.recipe?.name || "Empty"}
                      menuQty={stock.menuQty || 0}
                    />
                  ))}
                </InventoryBoxes>
        </Area>
      </Content>
    </>
  );
};

const SelectedItemText = styled.span`
  color: #22232B;
`;

const StyledInventoryBox = styled.div`
  width: 120px;
  height: 120px;
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  border-radius: 4px;
  border: 1px solid rgba(18, 19, 23, 0.10);
  background: #F1F1F4;
  color: #9EA1AE;
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; 
  letter-spacing: -0.07px;
  padding-top: 16px;
  padding-left: 16px;
`;

const Content = styled.div`
  display: inline-flex;
`;

const Title = styled.div`
  font-family: 'Poppins';
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #22232b;
  margin-top: 78px;
  margin-left: 35px;
`;

const Area = styled.div`
    height: 928px;
    width: 995px;
    margin-top: 54px;
    position: absolute;
    margin-top: 168px;
    margin-left: 354px;
`

const InventoryBoxes = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4개의 열로 배치 */
  grid-gap: 20px; /* 박스 간의 간격 설정 */
  width: 540px;
`;

const DropdownContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    margin-top: 8px;
    margin-bottom: 24px;
`;

const DropdownContainer1 = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    margin-top: 8px;
    margin-bottom: 24px;
`;

const DropdownButton = styled.button`
    background-color: #f1f1f1;
    border: none;
    padding: 10px;
    cursor: pointer;
    color:  #83879d;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    width: 610px;
    height: 32px;
    display: flex; 
    align-items: center; 
    gap: 8px; 
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;

    span {
        //내용 한줄로 보이게
        white-space: nowrap; /
    }
`;

const DropdownContent1 = styled.div<{ isOpen: boolean }>`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: calc(100%); 
  left: 0;
  width: 610px; 
`;

const DropdownItem1 = styled.div`
  padding: 8px; 
  cursor: pointer;
  width: 595px;
  white-space: nowrap; /* 내용이 너무 길 경우 줄 바꿈을 방지합니다. */
  overflow: hidden; /* 내용이 너무 길 경우 잘린 부분을 숨깁니다. */
  text-overflow: ellipsis; /* 내용이 너무 길 경우 말줄임표를 표시합니다. */
  &:hover {
    background-color: #f1f1f1;
  }
`;

const ContentBox = styled.div`
  display: inline-flex;
`;

const DropDownImage = styled.img`
    flex-shrink: 0;
    display: inline-block;
    padding-left: 560px;
    position: absolute;
`

const TitleDetail = styled.div`
    color:  #838796;
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 8px;
`;
export default Inventory;