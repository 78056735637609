/**
 * Generated by orval v6.19.0 🍺
 * Do not edit manually.
 * OLHSO API
 * The OLHSO API description
 * OpenAPI spec version: 0.1
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  AdminCouponControllerFindManyParams,
  Coupon,
  CouponList,
  CreateCouponDto,
  UpdateCouponDto
} from '../../model'



  /**
 * @summary 쿠폰 목록 조회하기
 */
export const adminCouponControllerFindMany = <TData = AxiosResponse<CouponList>>(
    params?: AdminCouponControllerFindManyParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/olhso/admin/coupons`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }
/**
 * @summary 쿠폰 생성하기
 */
export const adminCouponControllerCreate = <TData = AxiosResponse<Coupon>>(
    createCouponDto: CreateCouponDto, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `/olhso/admin/coupons`,
      createCouponDto,options
    );
  }
/**
 * @summary 쿠폰 단일 조회
 */
export const adminCouponControllerFindOne = <TData = AxiosResponse<Coupon>>(
    id: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/olhso/admin/coupons/${id}`,options
    );
  }
/**
 * @summary 쿠폰 수정하기 또는 활성화 변경
 */
export const adminCouponControllerUpdate = <TData = AxiosResponse<Coupon>>(
    id: string,
    updateCouponDto: UpdateCouponDto, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.put(
      `/olhso/admin/coupons/${id}`,
      updateCouponDto,options
    );
  }
/**
 * @summary 쿠폰 삭제하기
 */
export const adminCouponControllerDelete = <TData = AxiosResponse<void>>(
    id: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.delete(
      `/olhso/admin/coupons/${id}`,options
    );
  }
