import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import ClientSidebar from "../../../components/ClientSidebar";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import DropDown from "../../../images/DropDown.png";
import ReactPaginate from 'react-paginate';

interface NoticeProps {
    
};

const Notice: React.FC<NoticeProps> = () => {
    const navigate = useNavigate();
    const [isChecked, setIsChecked] = useState(false);
    const noticeData = [
        {
          id: 1,
          title: 'MV222wefwefwefewfwe33R5E',
          date: '11 : 30 / August 25, 2023',
        },
        // 다른 데이터 항목 추가 
      ];
      const [rowStates, setRowStates] = useState(noticeData.map(() => true)); // 초기에 모든 행을 보이도록 설정
      const handleDeleteRow = (index:any) => {
        const updatedRowStates = [...rowStates];
        updatedRowStates[index] = false; // 클릭한 행을 숨김
      
        setRowStates(updatedRowStates);
      };
      

    const [noticePage, setNoticePage] = useState(0);
    const [eventPage, setEventPage] = useState(0);
    const itemsPerPage = 4; // 한 페이지 당 몇 개의 아이템을 보여줄지

    // 페이지 변경 핸들러
    // const handleNoticePageClick = (event: PageClickEvent) => {
    //   setNoticePage(event.selected);
    // };
  
    // const handleEventPageClick = (event: PageClickEvent) => {
    //   setEventPage(event.selected);
    // };

    // // 현재 페이지에 보여줄 아이템들을 계산
    // const displayedNotices = noticeData.slice(
    //   noticePage * itemsPerPage,66ㅅㄹㅊ
    //   (noticePage + 1) * itemsPerPage
    // );

    // const displayedEvents = eventData.slice(
    //   eventPage * itemsPerPage,
    //   (eventPage + 1) * itemsPerPage
    // );

      
    return (
      <>
      <Header/>
      <Sidebar/>
      <Content>
        <ClientSidebar />
        <Title>Notice / Event</Title>
        <Area>
            {/* Notice */}
            <TitleDetail1>Notice</TitleDetail1>
                <Table>
                    <TableHeader>
                        <TableCellTitle></TableCellTitle>
                        <TableCellTitle>Title</TableCellTitle>
                        <TableCellTitle>Date</TableCellTitle>
                        <TableCellTitle></TableCellTitle>
                        <TableCellTitle></TableCellTitle>
                    </TableHeader> 
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                {/* 체크 박스*/}
                                <StyledCheckbox
                                  type="checkbox"
                                  checked={isChecked}
                                  onChange={() => setIsChecked(!isChecked)}
                                />
                            </TableCell>
                            <TableCellT isChecked={isChecked}>
                                MV222wefwefwefewfwe33R5E
                            </TableCellT>
                            <TableCell>11 : 30 / August 25, 2023</TableCell>
                            <TableCell>
                                <EditBtn
                                onClick={() => {
                                  navigate("/client/coupon/editcoupon");
                                }}
                                >Edit</EditBtn>
                            </TableCell>
                            <TableCell>
                                <DeleteBtn 
                              // onClick={() => handleDeleteRow()}
                              >
                                Delete
                              </DeleteBtn>
                            </TableCell>
                            
                        </TableRow>
                     
                    </TableBody>
                </Table>
            {/* 페이지네이션 */}
            {/* <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              breakLabel={'...'}
              pageCount={Math.ceil(noticeData.length / itemsPerPage)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handleNoticePageClick}
              containerClassName={'pagination'}
              activeClassName={'active'}
            /> */}
            {/* Event */}
            <TitleDetail2>Event</TitleDetail2>
                <Table>
                        <TableHeader>
                            <TableCellTitle></TableCellTitle>
                            <TableCellTitle>Title</TableCellTitle>
                            <TableCellTitle>Date</TableCellTitle>
                            <TableCellTitle></TableCellTitle>
                            <TableCellTitle></TableCellTitle>
                        </TableHeader> 
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    {/* 체크 박스*/}
                                    <StyledCheckbox
                                    type="checkbox"
                                    checked={isChecked}
                                    onChange={() => setIsChecked(!isChecked)}
                                    />
                                </TableCell>
                                <TableCellT isChecked={isChecked}>
                                    MV222wefwefwefewfwe33R5E
                                </TableCellT>
                                <TableCell>11 : 30 / August 25, 2023</TableCell>
                                <TableCell>
                                    <EditBtn
                                    onClick={() => {
                                    navigate("/client/coupon/editcoupon");
                                    }}
                                    >Edit</EditBtn>
                                </TableCell>
                                <TableCell>
                                    <DeleteBtn 
                                // onClick={() => handleDeleteRow(index)}
                                >
                                    Delete
                                </DeleteBtn>
                                </TableCell>
                                
                            </TableRow>
                           
                        </TableBody>
                </Table>
            <PostBtn
            //클릭시, PostNotice 페이지로 이동
            onClick={() => {
                navigate("/client/notice/postnotice");
            }}
            >Post</PostBtn>
        </Area>
      </Content>
    </>
  );
};


const TitleDetail1 = styled.div`
    color: #22232B;
    font-family: 'Poppins';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; 
    margin-bottom: 20px;
`;

const TitleDetail2 = styled.div`
    color: #22232B;
    font-family: 'Poppins';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; 
    margin-bottom: 20px;
    margin-top: 55px;
`;

const Title = styled.div`
  font-family: 'Poppins';
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #22232b;
  margin-top: 78px;
  margin-left: 35px;
`;

const Area = styled.div`
    height: 928px;
    width: 995px;
    margin-top: 54px;
    position: absolute;
    margin-top: 168px;
    margin-left: 354px;
`

const Content = styled.div`
  display: inline-flex;
`;


const Table = styled.table`
    border-collapse: collapse;
    width: 100%;
`;

const TableHeader = styled.thead`
    /* ... 테이블 헤더 관련 스타일링 */
    border-top: 1px solid #E4E4E4;
    border-bottom : 1px solid #E4E4E4;
    padding-top: 12px;
    padding-bottom: 12px;
    width: 995px; 
    color:  #838796;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
`;

const TableBody = styled.tbody`
    height: 187px;
    border-bottom : 1px solid #E4E4E4;
`;

const TableRow = styled.tr`
      
`;

const TableCell = styled.td`
    padding-top: 12px;
    padding-bottom: 12px;
    color: #000;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    height: 16px;
`;

const TableCellT = styled.td<{ isChecked: boolean }>`
    color: ${props => props.isChecked ? '#ED6A2C' : '#000'};
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: ${props => props.isChecked ? 600 : 400};
    line-height: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
`


const TableCellTitle = styled.td`
    color: #838796;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    padding-top: 12px;
    padding-bottom: 12px;   
`

const DeleteBtn = styled.div`
    width: 50px;
    height: 25px;
    display: inline-flex;
    /* padding: 5px 10px; */
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 3px;
    background: #121317;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    color: #FFFFFF;
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px; 
    cursor: pointer;
`

const EditBtn = styled.div`
    width: 43px;
    height: 25px;
    display: inline-flex;
    /* padding: 5px 10px; */
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 3px;
    border: 1px solid #9EA1AE;
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    color: var(--content-gray-900, #22232B);
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px; 
    cursor: pointer;
`

const StyledCheckbox = styled.input`
  appearance: none; /* 체크 박스의 기본 스타일 숨기기 */
  border: 1px solid #9EA1AE;
  border-radius: 3px;
  width: 20px;
  height: 20px;

  &:checked {
    background-color: #ED6A2C !important; 
    border: none;
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
  }
`;

const PostBtn = styled.div`
    display: inline-flex;
    /* padding: 8px 24px; */
    width: 81px;
    height: 38px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 3px;
    background: #121317;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    color: #FFF;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; 
    margin-top: 55px;
    margin-left: 902px;
`;







export default Notice;












