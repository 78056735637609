import React, { useState, useRef, useEffect } from 'react';
import styled from "styled-components";
import DriverSidebar from "../../../components/DriverSidebar";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import DropDown from "../../../images/DropDown.png";
import {
    adminTruckControllerCreate,
    adminTruckControllerFindAllTruckAreas, adminTruckControllerFindTruckSlotInfo
} from "../../../api/service/admin-trucks/admin-trucks";
import {CreateTruckDto, TruckArea, TruckSlotArray} from '../../../api/model';
import { initToken } from "../../../utils/setAuthToken";
import {useNavigate} from "react-router-dom";

interface AddTruckProps {

};
  

const AddTruck: React.FC<AddTruckProps> = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [licensePlateNumber, setLicensePlateNumber] = useState('');
    const [truckName, setTruckName] = useState('');
    const [isTruckTypeDropdownOpen, setIsTruckTypeDropdownOpen] = useState(false);
    const [isDrivingAreaDropdownOpen, setIsDrivingAreaDropdownOpen] = useState(false);
    const [isTruckSlotsDropdownOpen, setIsTruckSlotsDropdownOpen] = useState(false);
    const [isAutoWokDropdownOpen, setIsAutoWokDropdownOpen] = useState(false);
    const [selectedTruckType, setSelectedTruckType] = useState<string | null>(null);
    const [selectedDrivingArea, setSelectedDrivingArea] = useState<string | null>(null);
    const [selectedDrivingAreas, setSelectedDrivingAreas] = useState<{id: string, name: string}[]>([]);
    const [selectedTruckSlot, setSelectedTruckSlot] = useState<{id: string, row: number, col: number} | null>(null);
    const [selectedAutoWok, setSelectedAutoWok] = useState<string | null>(null);
    const truckTypeDropdownRef = useRef<HTMLDivElement>(null);
    const drivingAreaDropdownRef = useRef<HTMLDivElement>(null);
    const TruckSlotDropdownRef = useRef<HTMLDivElement>(null);
    const AutoWokDropdownRef = useRef<HTMLDivElement>(null);
    const [truckAreas, setTruckAreas] = useState<TruckArea[]>([]);
    const [truckSlotArrays, setTruckSlotArrays] = useState<TruckSlotArray[]>([]);

    const navigate = useNavigate()
    const initTokenAndGetToken = async () => {
        await initToken();
    }
    const closeDropdowns = () => {
          setIsOpen(false);
      };
    const handleSave = async () => {
        try {
            //TODO: 모든 항목을 입력하지 않으면 오류를 alert를 띄우고 리턴시킵니다
            if(!truckName || !licensePlateNumber || !selectedDrivingAreas || !selectedTruckSlot || !selectedAutoWok) {
                alert("모든 항목을 입력해주세요.");
                return
            } else if(truckName && licensePlateNumber && selectedDrivingAreas && selectedTruckSlot && selectedAutoWok){
                const createTruckDate: CreateTruckDto = {
                    licensePlateNumber: licensePlateNumber,
                    distance: 0,
                    name: truckName,
                    truckAreaIds: selectedDrivingAreas.map(area => area.id),
                    truckSlotArrayId: selectedTruckSlot.id,
                    usedWoks: Number(selectedAutoWok)
                }
                await adminTruckControllerCreate(createTruckDate);
                navigate("/driver/truckinfo")
            }
        } catch (error: any) {
            //TODO: 서버가 전달하는 오류 메시지를 그대로 보여주세요
            console.error('트럭 추가 오류:', error);
            alert('트럭 추가 실패');
        }
    }
    const handelTruckNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTruckName(event.target.value);
    }
    const handleLicensePlateNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLicensePlateNumber(event.target.value);
    }
    const fetchTruckAreas = async () => {
        try{
            const token = await initTokenAndGetToken();
            const response = await adminTruckControllerFindAllTruckAreas();
            const truckAreas = response.data;
            setTruckAreas(truckAreas);
        } catch (error) {
            console.error('트럭 지역 조회 오류:', error);
            alert('트럭 지역 조회 실패');
        }
    }
    const fetchTruckSlotArray = async () => {
        try{
            const token = await initTokenAndGetToken();
            const response = await adminTruckControllerFindTruckSlotInfo();
            const truckSlotArray = response.data;
            setTruckSlotArrays(truckSlotArray);
        } catch (error) {
            console.error('트럭 slot 조회 오류:', error);
            alert('트럭 slot 조회 실패');
        }
    }
  
    // 드롭다운 바깥 클릭 감지 함수
    const handleClickOutside = (event: MouseEvent) => {
        if (truckTypeDropdownRef.current && !truckTypeDropdownRef.current.contains(event.target as Node)) {
            setIsTruckTypeDropdownOpen(false);
        }
        if (drivingAreaDropdownRef.current && !drivingAreaDropdownRef.current.contains(event.target as Node)) {
            setIsDrivingAreaDropdownOpen(false);
        }
        if (TruckSlotDropdownRef.current && !TruckSlotDropdownRef.current.contains(event.target as Node)) {
            setIsTruckSlotsDropdownOpen(false);
        }
        if (AutoWokDropdownRef.current && !AutoWokDropdownRef.current.contains(event.target as Node)) {
            setIsAutoWokDropdownOpen(false);
        }
    };

      useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);


      useEffect(() => {
          document.addEventListener('click', closeDropdowns);
          return () => {
              document.removeEventListener('click', closeDropdowns);
          };
      }, []);

      useEffect(() => {
          fetchTruckAreas();
          fetchTruckSlotArray();
      }, []);
  
      const toggleTruckTypeDropdown = () => {
        setIsTruckTypeDropdownOpen(!isTruckTypeDropdownOpen);
        // 다른 드롭다운 닫기
        setIsDrivingAreaDropdownOpen(false);
        setIsTruckSlotsDropdownOpen(false);
        setIsAutoWokDropdownOpen(false);
    };
    
    const toggleDrivingAreaDropdown = () => {
        setIsDrivingAreaDropdownOpen(!isDrivingAreaDropdownOpen);
        // 다른 드롭다운 닫기
        setIsTruckTypeDropdownOpen(false);
        setIsTruckSlotsDropdownOpen(false);
        setIsAutoWokDropdownOpen(false);
    };

    const toggleTruckSlotsDropdown = () => {
        setIsTruckSlotsDropdownOpen(!isTruckSlotsDropdownOpen);
        // 다른 드롭다운 닫기
        setIsTruckTypeDropdownOpen(false);
        setIsDrivingAreaDropdownOpen(false);
        setIsAutoWokDropdownOpen(false);
    };

    const toggleAutoWokDropdown = () => {
        setIsAutoWokDropdownOpen(!isAutoWokDropdownOpen);
        // 다른 드롭다운 닫기
        setIsTruckTypeDropdownOpen(false);
        setIsDrivingAreaDropdownOpen(false);
        setIsTruckSlotsDropdownOpen(false);
    };
    
    const handleTruckTypeItemClick = (item: string) => {
        setSelectedTruckType(item);
        setIsTruckTypeDropdownOpen(false);
    };

    const handleTruckSlotClick = (item: {id: string, row: number, col: number}) => {
        setSelectedTruckSlot(item);
        setIsTruckSlotsDropdownOpen(false);
    };
  
    const handleAutoWokClick = (item: string) => {
        setSelectedAutoWok(item);
        setIsAutoWokDropdownOpen(false);
    };

    // const handleDrivingAreaItemClick = (item: string) => {
    //     setSelectedDrivingArea(item);
    //     setIsDrivingAreaDropdownOpen(false);
    // };

    const handleDrivingAreaItemClick = (item: {id: string, name: string}) => {
        if (!selectedDrivingAreas.includes(item)) {
            setSelectedDrivingAreas(prevAreas => [...prevAreas, item]);
        }
        setIsDrivingAreaDropdownOpen(false);
    };

    const removeDrivingArea = (item: {id: string, name: string}) => {
        setSelectedDrivingAreas(prevAreas => prevAreas.filter(area => area !== item));
    };

  return (
    <>
      <Header />
      <Sidebar />
      <Content>
        <DriverSidebar />
        <Title>Add Truck</Title>
        <Area>
                <TitleDetail>Truck Name</TitleDetail>
                <TextInput
                    placeholder="Enter truck name"
                    value={truckName}
                    onChange={handelTruckNameChange}
                />
                <TitleDetail>Truck license PlateNumber</TitleDetail>
                <TextInput
                    placeholder="Enter truck license PlateNumber"
                    value={licensePlateNumber}
                    onChange={handleLicensePlateNumberChange}
                />
                <TitleDetail>Driving Area</TitleDetail>
                <DropdownContainer ref={drivingAreaDropdownRef}>
                    <DropdownButton onClick={toggleDrivingAreaDropdown}>
                        <span>{selectedDrivingArea || "select"}</span>
                        <Image
                            src={DropDown}
                            alt='dropdown'
                            onClick={toggleDrivingAreaDropdown}
                        />
                    </DropdownButton>
                    <DropdownContent isOpen={isDrivingAreaDropdownOpen}>
                        {truckAreas.map((area, index) => (
                            <DropdownItem key={index} onClick={() => handleDrivingAreaItemClick({id: area.id, name: area.name})}>
                                {area.name}
                            </DropdownItem>
                        ))}
                    </DropdownContent>
                </DropdownContainer>
                <div>
                    <BadgeContainer>
                        {selectedDrivingAreas.map((area, index) => (
                            <Badge key={index}>
                                {area.name}
                                <Xbtn onClick={() => removeDrivingArea(area)}>X</Xbtn>
                            </Badge>
                        ))}
                    </BadgeContainer>
                    </div>
                <TitleDetail>Array of Truck Slots</TitleDetail>
                <DropdownContainer ref={TruckSlotDropdownRef}>
                    <DropdownButton onClick={toggleTruckSlotsDropdown}>
                        <span>{ selectedTruckSlot?` ${selectedTruckSlot.row}X${selectedTruckSlot.col}` : "select"}</span>
                        <Image src={DropDown} alt='dropdown' />
                    </DropdownButton>
                    <DropdownContent isOpen={isTruckSlotsDropdownOpen}>
                        {truckSlotArrays.map((slot, index) => (
                            <DropdownItem key={index} onClick={() => handleTruckSlotClick({id: slot.id, row: slot.row, col: slot.col})}>
                                {slot.row}X{slot.col}
                            </DropdownItem>
                        ))}
                    </DropdownContent>
                </DropdownContainer>
                <TitleDetail>Auto Wok</TitleDetail>
                <DropdownContainer ref={AutoWokDropdownRef}>
                    <DropdownButton onClick={toggleAutoWokDropdown}>
                        <span>{selectedAutoWok || "select"}</span>
                        <Image src={DropDown} alt='dropdown' />
                    </DropdownButton>
                    <DropdownContent isOpen={isAutoWokDropdownOpen}>
                        <DropdownItem onClick={() => handleAutoWokClick("1")}>1</DropdownItem>
                        <DropdownItem onClick={() => handleAutoWokClick("2")}>2</DropdownItem>
                        <DropdownItem onClick={() => handleAutoWokClick("3")}>3</DropdownItem>
                        <DropdownItem onClick={() => handleAutoWokClick("4")}>4</DropdownItem>
                    </DropdownContent>
                </DropdownContainer>
                <Button 
                  onClick={handleSave}
                >
                    Save
                </Button>
        </Area>
      </Content>
    </>
  );
};

const Content = styled.div`
  display: inline-flex;
`;

const Title = styled.div`
  font-family: 'Poppins';
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #22232b;
  margin-top: 78px;
  margin-left: 35px;
`;

const DropdownContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    margin-top: 8px;
    margin-bottom: 24px;
`;

const DropdownButton = styled.button`
    background-color: #f1f1f1;
    border: none;
    padding: 10px;
    cursor: pointer;
    color:  #83879d;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    width: 432px;
    height: 32px;
    display: flex; 
    align-items: center; 
    gap: 8px; 
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;

    span {
        white-space: nowrap; /
    }
`;

const DropdownContent = styled.div<{isOpen: boolean}>` 
    display: ${(props) => (props.isOpen ? 'block' : 'none')};
    position: absolute;
    top: 100%;  
    left: 0;   
    background-color: #fff;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
`;


const DropdownItem = styled.div`
    padding: 3px;
    width: 425px;
    cursor: pointer;
    &:hover {
        background-color: #f1f1f1;
    }
`;

const Image = styled.img`
    flex-shrink: 0;
    display: inline-block;
    padding-left: 386px;
    /* margin-left: 306px; */
    position: absolute;
`

const TextInput = styled.input`
    background-color: #f1f1f1;
    border: none;
    color:  #83879d;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    width: 420px;
    height: 32px;
    display: flex; 
    align-items: center; 
    padding-left: 12px;
    margin-bottom: 24px;

    &:focus {
        outline: none; 
        border: 2px solid #ED6A2C; 
    }
`

const TitleDetail = styled.div`
    color:  #838796;
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 8px;
`;

const Area = styled.div`
    height: 928px;
    width: 995px;
    margin-top: 54px;
    position: absolute;
    margin-top: 168px;
    margin-left: 354px;
`

const Button = styled.div`
    width: 86px;
    height: 38px;
    border-radius: 3px;
    background: #121317;
    display: inline-flex;
    /* padding: 8px 24px; */
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #FFF;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin-top: 47px;
    margin-left: 342px;
`
const BadgeContainer = styled.div`
    display: flex; 
    flex-wrap: wrap; 
    gap: 4px; 
`;

const Badge = styled.span`
  display: flex;
  justify-content: center; 
  align-items: center; 
  background-color: black;
  color: white;
  padding: 4px;
  padding-left: 8px;
  border-radius: 30px;
  margin-top: -8px;
  margin-bottom: 24px; 

  cursor: pointer;
  font-size: 12px;
  width: 101px;
  height: 24px;

  & > button {
    background: none;
    border: none;
    color: white;
    /* margin-left: 10px; */
    cursor: pointer;
    flex-shrink: 0; 
    justify-content: center; 
    align-items: center; 
  }
`;

const Xbtn = styled.div`
    background-color: #121317;
    color: #FFFFFF;
    width: 16px;
    height: 16px;
    margin-left: 8px;
    display: inline-block;
`



export default AddTruck;