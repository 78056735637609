import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'; 
import App from './App';
import Login from './pages/login/LogIn';
import reportWebVitals from './reportWebVitals';

const rootElement = document.getElementById('root') as HTMLElement;

const checkLoginStatus = () => {
  // 테스트를 위해 임시로 주석 처리
  // const isLoggedIn = false;
  // return isLoggedIn;
  return true;
};

const isLoggedIn = checkLoginStatus();

// 로그인 상태에 따라 다른 컴포넌트 렌더링
ReactDOM.render(
  <React.StrictMode>
    {/*테스트를 위해 임시로 주석 처리*/}
    {/* <BrowserRouter> */}
    {isLoggedIn ? <App /> : <Login />}
    {/* </BrowserRouter> */}
  </React.StrictMode>,
  rootElement
);

reportWebVitals();


