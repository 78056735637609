import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import driveron from "../images/driver_on.png";
import driver from "../images/driver.png";
import clienton from "../images/client_on.png";
import client from "../images/client.png";

const Sidebar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [tag, setTag] = useState<string>("");

  useEffect(() => {
    setTag(location.pathname);
  }, [location]);

  return (
    <SidebarWrapper>
      <SidebarContent>
        <DriverWrapper
          onClick={() => {
            setTag("/driver");
            navigate("/driver");
          }}
        >
          {tag.includes("driver") ? (
            <img src={driveron} width={40} height={60} alt={"driver"} />
          ) : (
            <img src={driver} width={40} height={60} alt={"driver"} />
          )}
        </DriverWrapper>
        <ClientWrapper
          onClick={() => {
            setTag("client");
            navigate("/client");
          }}
        >
          {tag.includes("client") ? (
            <img src={clienton} width={40} height={60} alt={"client"} />
          ) : (
            <img src={client} width={40} height={60} alt={"client"} />
          )}
        </ClientWrapper>
      </SidebarContent>
    </SidebarWrapper>
  );
};

const DriverWrapper = styled.div`
  height: 100%;
`;

const ClientWrapper = styled.div`
  height: 100%;
`;

const SidebarContent = styled.div`
  margin-top: 24px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 30px;
`;

const SidebarWrapper = styled.div`
  width: 68px;
  height: 100%;
  background-color: #d9d9d9;
  position: absolute;
  top: 56px;
`;

export default Sidebar;
