/**
 * Generated by orval v6.19.0 🍺
 * Do not edit manually.
 * OLHSO API
 * The OLHSO API description
 * OpenAPI spec version: 0.1
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  FileUrlReq,
  PreSignedUrl
} from '../../model'



  /**
 * @summary 파일 업로드를 위한 AWS S3 pre-signed url을 반환
 */
export const adminFileControllerCreatePreSignedUrl = <TData = AxiosResponse<PreSignedUrl[]>>(
    fileUrlReq: FileUrlReq, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `/olhso/admin/files/upload-urls`,
      fileUrlReq,options
    );
  }
/**
 * @summary S3 meta data 업데이트
 */
export const adminFileControllerUpdateMetadata = <TData = AxiosResponse<void>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.put(
      `/olhso/admin/files/meta`,undefined,options
    );
  }
/**
 * @summary 단일 파일 삭제
 */
export const adminFileControllerDeleteFile = <TData = AxiosResponse<void>>(
    id: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.delete(
      `/olhso/admin/files/${id}`,options
    );
  }
/**
 * @summary 다중 파일 삭제
 */
export const adminFileControllerDeleteFiles = <TData = AxiosResponse<void>>(
    adminFileControllerDeleteFilesBody: string[], options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.delete(
      `/olhso/admin/files`,{data:
      adminFileControllerDeleteFilesBody, ...options}
    );
  }
