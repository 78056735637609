/**
 * Generated by orval v6.19.0 🍺
 * Do not edit manually.
 * OLHSO API
 * The OLHSO API description
 * OpenAPI spec version: 0.1
 */

export type TrucDriverStatus = typeof TrucDriverStatus[keyof typeof TrucDriverStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TrucDriverStatus = {
  WORKING: 'WORKING',
  OFF: 'OFF',
} as const;
