/**
 * Generated by orval v6.19.0 🍺
 * Do not edit manually.
 * OLHSO API
 * The OLHSO API description
 * OpenAPI spec version: 0.1
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  AdminCreateEarnDto,
  AdminCreateUseDto,
  AdminPointEventControllerFindManyParams,
  ClientPointEventList,
  ClientPointRes,
  PointEvent
} from '../../model'



  /**
 * @summary 클라이언트 포인트 목록 조회하기
 */
export const adminPointEventControllerFindMany = <TData = AxiosResponse<ClientPointEventList>>(
    clientId: string,
    params?: AdminPointEventControllerFindManyParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/olhso/admin/bbq-coin/client/${clientId}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }
/**
 * @summary 클라이언트 포인트 목록 조회하기
 */
export const adminPointEventControllerGetRewardSum = <TData = AxiosResponse<ClientPointRes>>(
    clientId: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/olhso/admin/bbq-coin/client/${clientId}/reward-sum`,options
    );
  }
/**
 * @summary 포인트 적립 이벤트 생성
 */
export const adminPointEventControllerCreateEarnEvent = <TData = AxiosResponse<PointEvent>>(
    adminCreateEarnDto: AdminCreateEarnDto, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `/olhso/admin/bbq-coin/earn`,
      adminCreateEarnDto,options
    );
  }
/**
 * @summary 포인트 사용 이벤트 생성
 */
export const adminPointEventControllerCreateUseEvent = <TData = AxiosResponse<PointEvent>>(
    adminCreateUseDto: AdminCreateUseDto, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `/olhso/admin/bbq-coin/use`,
      adminCreateUseDto,options
    );
  }
