/**
 * Generated by orval v6.19.0 🍺
 * Do not edit manually.
 * OLHSO API
 * The OLHSO API description
 * OpenAPI spec version: 0.1
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  Recipe
} from '../../model'



  /**
 * @summary 레시피 목록 조회
 */
export const adminRecipeControllerFindMany = <TData = AxiosResponse<Recipe[]>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/olhso/admin/recipes`,options
    );
  }
