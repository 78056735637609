import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Switch from 'react-switch';
import styled from "styled-components";
import ClientSidebar from "../../../components/ClientSidebar";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";

interface ReviewProps {

};

const Review: React.FC<ReviewProps> = () => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [isTruckTypeDropdownOpen, setIsTruckTypeDropdownOpen] = useState(false);
    const [isDrivingAreaDropdownOpen, setIsDrivingAreaDropdownOpen] = useState(false);
    const [isToggleOn, setIsToggleOn] = useState(false); // 초기 상태는 false (비활성화)로 설정
    const truckTypeDropdownRef = useRef<HTMLDivElement>(null);
    const drivingAreaDropdownRef = useRef<HTMLDivElement>(null);
    const [data, setData] = useState([]);
    const [deletedRows, setDeletedRows] = useState<number[]>([]);
    const [isActive, setIsActive] = useState(false); //토글 버튼
    
    const closeDropdowns = () => {
          setIsOpen(false);
      };
  
    const handleClickOutside = (event: MouseEvent) => {
    const isClickedInsideTruckTypeDropdown = truckTypeDropdownRef.current?.contains(event.target as Node);
    const isClickedInsideDrivingAreaDropdown = drivingAreaDropdownRef.current?.contains(event.target as Node);
        
    if (!isClickedInsideTruckTypeDropdown && !isClickedInsideDrivingAreaDropdown) {
            setIsTruckTypeDropdownOpen(false);
            setIsDrivingAreaDropdownOpen(false);
        }
    };


    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);


      useEffect(() => {
          document.addEventListener('click', closeDropdowns);
          return () => {
              document.removeEventListener('click', closeDropdowns);
          };
      }, []);

      // useEffect(() => {
      //   // API 요청 함수 (예: fetchCouponData)를 호출하여 데이터를 가져옴
      //   fetchCouponData()
      //     .then((response) => {
      //       setData(response.data); // 데이터를 상태에 저장
      //     })
      //     .catch((error) => {
      //       console.error('API 요청 실패:', error);
      //     });
      // }, []); // 컴포넌트가 마운트될 때 한 번만 호출

      const handleDeleteRow = (index: number) => {
        // 해당 TableRow를 삭제하기 위해 삭제된 TableRow의 인덱스를 상태에 추가
        setDeletedRows([...deletedRows, index]);
      };


  return (
    <>
      <Header />
      <Sidebar />
      <Content>
        <ClientSidebar />
        <Title>Review Content</Title>
        <Area>
          <Table>
                    <TableHeader>
                        <TableCellTitle>Menu</TableCellTitle>
                        <TableCellTitle>Contents</TableCellTitle>
                        <TableCellTitle>State</TableCellTitle>
                        <TableCellTitle></TableCellTitle>
                        <TableCellTitle></TableCellTitle>
                    </TableHeader> 
                <TableBody>
                    {/* 데이터를 매핑하여 TableRow로 표시 */}
                    {/* {data.map((item, index) => (
          <TableRow key={index}>
            <TableCell>{item.name}</TableCell>
            <TableCell>{item.type}</TableCell>
            <TableCell>{item.applicableMenu}</TableCell>
            <TableCell>{item.state}</TableCell>
            <TableCell>
              <Switch
                // ...
              />
            </TableCell>
            <TableCell>
              <EditBtn
                onClick={() => {
                  navigate(`/client/coupon/editcoupon/${item.id}`);
                }}
              >
                Edit
              </EditBtn>
            </TableCell>
            <TableCell>
              <DeleteBtn>Delete</DeleteBtn>
            </TableCell>
          </TableRow>
                    ))} */}
                    <TableRow>
                            <TableCell>Yangnyum Galbi</TableCell>
                            <TableCell>2</TableCell>
                            <TableCell>
                            <ToggleSwitch>
                              <CheckBox
                                type="checkbox"
                                checked={isActive}
                                onChange={() => setIsActive(!isActive)}
                              />
                              <ToggleSlider />
                            </ToggleSwitch>
                            </TableCell>
                            <TableCell>
                                <EditBtn
                                onClick={() => {
                                  navigate("/client/coupon/editcoupon");
                                }}
                                >Edit</EditBtn>
                            </TableCell>
                            <TableCell>
                              <DeleteBtn 
                              // onClick={() => handleDeleteRow()}
                              >
                                Delete
                              </DeleteBtn>
                            </TableCell>
                    </TableRow>
                    </TableBody>
          </Table>
                <Button
                onClick={() => {
                  navigate("/client/review/addeditreview");
                }}
                >+Add Review Content</Button>
        </Area>
      </Content>
    </>
  );
};


const Content = styled.div`
  display: inline-flex;
`;

const Title = styled.div`
  font-family: 'Poppins';
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #22232b;
  margin-top: 78px;
  margin-left: 35px;
`;

const Area = styled.div`
    height: 928px;
    width: 995px;
    margin-top: 54px;
    position: absolute;
    margin-top: 168px;
    margin-left: 354px;
`

const Button = styled.div`
    width: 154px;
    height: 38px;
    display: inline-flex;
    /* padding: 8px 24px; */
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 3px;
    background-color: #121317;
    color: #FFFFFF;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; 
    margin-left: 750px;
    margin-right: 114px;
    margin-top: 411px;
`
const DeleteBtn = styled.div`
    width: 50px;
    height: 25px;
    display: inline-flex;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 3px;
    background: #121317;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    color: #FFFFFF;
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px; 
    cursor: pointer;
`

const EditBtn = styled.div`
    width: 43px;
    height: 25px;
    display: inline-flex;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 3px;
    border: 1px solid #9EA1AE;
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    color: var(--content-gray-900, #22232B);
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px; 
    cursor: pointer;
`

const Table = styled.table`
    border-collapse: collapse;
    width: 100%;
`;

const TableHeader = styled.thead`
    /* ... 테이블 헤더 관련 스타일링 */
    border-top: 1px solid #E4E4E4;
    border-bottom : 1px solid #E4E4E4;
    padding-top: 12px;
    padding-bottom: 12px;
    width: 995px; 
    color:  #838796;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
`;

const TableBody = styled.tbody`
 
`;

const TableRow = styled.tr`
      
`;

const TableCell = styled.td`
    padding-top: 24px;
    color: #000;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
`;

const TableCellTitle = styled.td`
    color: #838796;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    padding-top: 12px;
    padding-bottom: 12px;   
`
const ToggleSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 47.7px;
  height: 23.33px;
`;

const ToggleSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }
`;

const CheckBox = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${ToggleSlider} {
    background-color: #ED6A2C;
  }

  &:focus + ${ToggleSlider} {
    box-shadow: 0 0 1px #2196F3;
  }

  &:checked + ${ToggleSlider}:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
`;


export default Review;
