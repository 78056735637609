import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Switch from "react-switch";
import styled from "styled-components";
import ClientSidebar from "../../../components/ClientSidebar";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import { adminCouponControllerFindMany } from "../../../api/service/admin-coupons/admin-coupons";
import { adminCouponControllerUpdate } from "../../../api/service/admin-coupons/admin-coupons";
import { adminCouponControllerDelete } from "../../../api/service/admin-coupons/admin-coupons";
import CouponDeleteModal from "../../../components/CouponDeleteModal";
import { AdminCouponControllerFindManyParams } from "../../../api/model/adminCouponControllerFindManyParams";

interface CouponProps {}

interface Coupon {
  activated: boolean;
  id: string;
  title: string;
  discountType: string;
  discountAmount: number;
  discountRatio: number;
  promotionType: string;
  startDate: string;
  expiration: string;
  quantity: number;
}

const Coupon: React.FC<CouponProps> = () => {
  const navigate = useNavigate();
  const [coupons, setCoupons] = useState<Coupon[]>([]); //쿠폰 타입을 지정
  const [couponActivationStatus, setCouponActivationStatus] = useState<{
    [id: string]: boolean;
  }>({}); //쿠폰 활성화 여부
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedCouponId, setSelectedCouponId] = useState<string | null>(null);

  // 쿠폰 목록 조회 함수
  const fetchCoupons = async () => {
    try {
      const response = await adminCouponControllerFindMany();
      const newCoupons = response.data.items as Coupon[];

      // 새로운 활성화 상태 맵을 생성
      const newActivationStatus = newCoupons.reduce((acc, coupon) => {
        acc[coupon.id] = coupon.activated;
        return acc;
      }, {} as { [id: string]: boolean });

      setCoupons(newCoupons);
      setCouponActivationStatus(newActivationStatus);
      console.log("쿠폰 목록 조회 성공:", newCoupons);
    } catch (error) {
      console.error("쿠폰 목록 조회 에러:", error);
    }
  };

  useEffect(() => {
    fetchCoupons();
  }, []);

  // 쿠폰 활성화 상태 변경 함수
  const updateCouponActivation = async (
    couponId: string,
    activated: boolean
  ) => {
    try {
      await adminCouponControllerUpdate(couponId, { activated });
      console.log("쿠폰 상태 변경 완료", couponId, activated);
      alert("Complete coupon status change!");
    } catch (error) {
      console.error("쿠폰 활성화 변경 실패:", error);
      alert("Sorry, Failed to change coupon activation");
    }
  };

  // ToggleSwitch의 상태 변경 핸들러
  const handleToggleChange = (couponId: string) => {
    const newActivatedStatus = !couponActivationStatus[couponId];
    setCouponActivationStatus((prev) => ({
      ...prev,
      [couponId]: newActivatedStatus,
    }));

    updateCouponActivation(couponId, newActivatedStatus);
  };

  // 쿠폰 삭제 함수
  const deleteCoupon = async (couponId: string) => {
    try {
      await adminCouponControllerDelete(couponId);
      // 삭제 성공 후 상태 업데이트
      setCoupons((coupons) =>
        coupons.filter((coupon) => coupon.id !== couponId)
      );
      console.log("쿠폰 삭제 성공", couponId);
      alert("Coupon deleted successfully!");
    } catch (error) {
      console.error("쿠폰 삭제 에러", error);
      alert("Sorry, failed to delete coupon");
    } finally {
      setIsDeleteModalOpen(false);
    }
  };

  // 삭제 버튼 클릭 핸들러
  const handleDeleteClick = (couponId: string) => {
    setSelectedCouponId(couponId);
    setIsDeleteModalOpen(true);
  };

  // 모달의 'Yes' 버튼 클릭 핸들러
  const handleConfirmDelete = () => {
    if (selectedCouponId) {
      deleteCoupon(selectedCouponId);
    }
  };

  return (
    <>
      <Header />
      <Sidebar />
      <Content>
        <ClientSidebar />
        <Title>Coupon</Title>
        <Area>
          <Table>
            <TableHeader>
              {/* <TableCellTitle>ID</TableCellTitle> */}
              <TableCellTitle>Name</TableCellTitle>
              <TableCellTitle>Discount Type</TableCellTitle>
              <TableCellTitle>Discount</TableCellTitle>
              <TableCellTitle>Promotion Type</TableCellTitle>
              <TableCellTitle>Start Date</TableCellTitle>
              <TableCellTitle>Expiration</TableCellTitle>
              <TableCellTitle>Quantity</TableCellTitle>
              <TableCellTitle>Activated</TableCellTitle>
              <TableCellTitle></TableCellTitle>
              <TableCellTitle></TableCellTitle>
            </TableHeader>
            <TableBody>
              {coupons.length > 0 ? (
                coupons.map((coupon) => (
                  <TableRow key={coupon.id}>
                    {/* <TableCell>{coupon.id}</TableCell> */}
                    <TableCell>{coupon.title}</TableCell>
                    <TableCell>{coupon.discountType}</TableCell>
                    <TableCell>
                      {coupon.discountAmount || coupon.discountRatio}
                    </TableCell>
                    <TableCell>{coupon.promotionType}</TableCell>
                    <TableCell>{coupon.startDate}</TableCell>
                    <TableCell>{coupon.expiration}</TableCell>
                    <TableCell>{coupon.quantity}</TableCell>
                    <TableCell>
                      <ToggleSwitch>
                        <CheckBox
                          type="checkbox"
                          checked={couponActivationStatus[coupon.id]}
                          onChange={() => handleToggleChange(coupon.id)}
                        />
                        <ToggleSlider />
                      </ToggleSwitch>
                    </TableCell>
                    <TableCell>
                      <EditBtn
                        onClick={() => {
                          navigate("/client/coupon/editcoupon");
                        }}
                      >
                        Edit
                      </EditBtn>
                    </TableCell>
                    <TableCell>
                      <DeleteBtn onClick={() => handleDeleteClick(coupon.id)}>
                        Delete
                      </DeleteBtn>
                      <CouponDeleteModal
                        isOpen={isDeleteModalOpen}
                        onClose={() => setIsDeleteModalOpen(false)}
                        onConfirm={handleConfirmDelete}
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCellNoData colSpan={9}>
                    No coupons are currently registered.
                  </TableCellNoData>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <Button
            onClick={() => {
              navigate("/client/coupon/addcoupon");
            }}
          >
            + Add Coupon
          </Button>
        </Area>
      </Content>
    </>
  );
};

const Content = styled.div`
  display: inline-flex;
`;

const Title = styled.div`
  font-family: "Poppins";
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #22232b;
  margin-top: 78px;
  margin-left: 35px;
`;

const Area = styled.div`
  height: 928px;
  width: 995px;
  margin-top: 54px;
  position: absolute;
  margin-top: 168px;
  margin-left: 354px;
`;

const Button = styled.div`
  width: 154px;
  height: 38px;
  display: inline-flex;
  /* padding: 8px 24px; */
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 3px;
  background-color: #121317;
  color: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  font-family: "Poppins";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  margin-left: 750px;
  margin-right: 114px;
  margin-top: 411px;
`;
const DeleteBtn = styled.div`
  width: 50px;
  height: 25px;
  display: inline-flex;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 3px;
  background: #121317;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  color: #ffffff;
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  cursor: pointer;
`;

const EditBtn = styled.div`
  width: 43px;
  height: 25px;
  display: inline-flex;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 3px;
  border: 1px solid #9ea1ae;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  color: var(--content-gray-900, #22232b);
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  cursor: pointer;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

const TableHeader = styled.thead`
  /* ... 테이블 헤더 관련 스타일링 */
  border-top: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 995px;
  color: #838796;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

const TableBody = styled.tbody``;

const TableRow = styled.tr``;

const TableCell = styled.td`
  padding-top: 24px;
  color: #000;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const TableCellNoData = styled.td`
  //가운데 정렬
  text-align: center;
  padding-top: 100px;
  color: #838796;
`;

const TableCellTitle = styled.td`
  color: #838796;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
`;
const ToggleSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 47.7px;
  height: 23.33px;
`;

const ToggleSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

const CheckBox = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${ToggleSlider} {
    background-color: #ed6a2c;
  }

  &:focus + ${ToggleSlider} {
    box-shadow: 0 0 1px #2196f3;
  }

  &:checked + ${ToggleSlider}:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
`;

export default Coupon;
