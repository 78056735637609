import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import ClientSidebar from "../../components/ClientSidebar";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import DropDown from "../../images/DropDown.png";
import Driving from "../../images/Driving.png";
import Cancled from  "../../images/Cancled.png";
import Completed from "../../images/Completed.png";

interface TotalOrdersProps {

};

interface PageButtonProps {
  onClick: () => void;
  isActive: boolean;
  children: any;
};

const TotalOrders: React.FC = () => {
  const navigate = useNavigate();
  const [selectedDateType, setSelectedDateType] = useState<string | null>(null);
  const [isDateTypeDropdownOpen, setIsDateTypeDropdownOpen] = useState(false);
  const [isOrderFilterDropdownOpen, setIsOrderFilterDropdownOpen] = useState(false); // 새로운 드롭다운 상태 추가
  const [selectedOrderFilter, setSelectedOrderFilter] = useState<string | null>("");
  const dateTypeDropdownRef = useRef<HTMLDivElement>(null);
  const orderFilterDropdownRef = useRef<HTMLDivElement>(null); 
  const [currentPage, setCurrentPage] = useState(1);

  const toggleDateTypeDropdown = () => {
    setIsDateTypeDropdownOpen(!isDateTypeDropdownOpen);
    setIsOrderFilterDropdownOpen(false);
  };

  const toggleOrderFilterDropdown = () => {
    setIsOrderFilterDropdownOpen(!isOrderFilterDropdownOpen);
    setIsDateTypeDropdownOpen(false);
  };

  const handleDateTypeItemClick = (item: string) => {
    setSelectedDateType(item);
    setIsDateTypeDropdownOpen(false);
  };

  const handleOrderFilterItemClick = (item: string) => {
    setSelectedOrderFilter(item); // 선택한 Order Filter를 업데이트
    setIsOrderFilterDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        orderFilterDropdownRef.current &&
        !orderFilterDropdownRef.current.contains(event.target as Node) &&
        dateTypeDropdownRef.current &&
        !dateTypeDropdownRef.current.contains(event.target as Node)
      ) {
        // 클릭된 요소가 드롭다운 내부가 아니면 드롭다운을 닫음
        setIsDateTypeDropdownOpen(false);
        setIsOrderFilterDropdownOpen(false);
      }
    };

    // 문서 전체에서 클릭 이벤트 감지
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // 컴포넌트 언마운트 시 이벤트 리스너 제거
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // 데이터 예시
  const data:any = [
    // 데이터 객체들
  ];

  const rowsPerPage = 12;
  const maxPage = Math.ceil(data.length / rowsPerPage);

  // 현재 페이지의 데이터를 가져오는 함수
  const getCurrentPageData = () => {
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return data.slice(startIndex, endIndex);
  };

  const currentData = getCurrentPageData();

  // 이전 페이지로 이동하는 함수
  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // 다음 페이지로 이동하는 함수
  const goToNextPage = () => {
    const maxPage = Math.ceil(data.length / rowsPerPage);
    if (currentPage < maxPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const PageButton: React.FC<PageButtonProps> = ({ onClick, isActive, children }) => {
    return (
      <button onClick={onClick} className={isActive ? 'active' : ''}>
        {children}
      </button>
    );
  };

  // 페이지 버튼을 렌더링하는 함수
 // renderPageButtons 함수
const renderPageButtons = () => {
  const buttons = [];
  for (let i = 1; i <= maxPage; i++) {
    buttons.push(
      <PageButton
        key={i}
        onClick={() => setCurrentPage(i)}
        isActive={i === currentPage}
      >
        {i}
      </PageButton>
    );
  }
  return buttons;
};


  return (
    <>
      <Header />
      <Sidebar />
      <Content>
        <ClientSidebar />
        <Title>Total Orders</Title>
        <Area>
          <FilterContainer>
          {/* Date 드롭다운 메뉴 */}
          <DropdownContainer ref={dateTypeDropdownRef}>
            <DropdownButton onClick={toggleDateTypeDropdown}>
              <span>{selectedDateType || "Date"}</span>
              <Image src={DropDown} alt='dropdown' />
            </DropdownButton>
            <DropdownContent isOpen={isDateTypeDropdownOpen}>
              <DropdownItem onClick={() => handleDateTypeItemClick("하루")}>하루</DropdownItem>
              <DropdownItem onClick={() => handleDateTypeItemClick("일주일")}>일주일</DropdownItem>
              <DropdownItem onClick={() => handleDateTypeItemClick("한달")}>한달</DropdownItem>
              <DropdownItem onClick={() => handleDateTypeItemClick("전체기간")}>전체기간</DropdownItem>
            </DropdownContent>
          </DropdownContainer> 
          {/* Order Filter 드롭다운 메뉴 */}
          <DropdownContainer ref={orderFilterDropdownRef}>
            <DropdownButton onClick={toggleOrderFilterDropdown}>
              <span>{selectedOrderFilter || "Order Filter"}</span> 
              <Image src={DropDown} alt='dropdown' />
            </DropdownButton>
            <DropdownContent isOpen={isOrderFilterDropdownOpen}>
              <DropdownItem onClick={() => handleOrderFilterItemClick("주문 번호")}>주문 번호</DropdownItem>
              <DropdownItem onClick={() => handleOrderFilterItemClick("트럭 번호")}>트럭 번호</DropdownItem>
              <DropdownItem onClick={() => handleOrderFilterItemClick("주문자 이메일")}>주문자 이메일</DropdownItem>
              <DropdownItem onClick={() => handleOrderFilterItemClick("주문자 전화번호")}>주문자 전화번호</DropdownItem>
              <DropdownItem onClick={() => handleOrderFilterItemClick("주문 상태")}>주문 상태</DropdownItem>
            </DropdownContent>
          </DropdownContainer>
          {/* 검색창 */}
          <TextInput1 placeholder='Search' />
          {/* 검색 버튼 */}
          <SearchBtn>Search</SearchBtn>
          </FilterContainer>
          {/* 검색 내용 영역*/}
          <TableContainer>
          <Table>
            <TableHeader>
                        <TableCellTitle>No</TableCellTitle>
                        <TableCellTitle>Date</TableCellTitle>
                        <TableCellTitle>Order Number</TableCellTitle>
                        <TableCellTitle>Truck Number</TableCellTitle>
                        <TableCellTitle>Menu</TableCellTitle>
                        <TableCellTitle>Status</TableCellTitle>
                        <TableCellTitle></TableCellTitle>
              </TableHeader> 
            <TableBody>
                        <TableRow>
                            <TableCell>1</TableCell>
                            <TableCell>August 25,2023</TableCell>
                            <TableCell>13S42G342E</TableCell>
                            <TableCell>MV23RE</TableCell>
                            <TableCellMenu>Yangnyum Galbi x1, Japch...</TableCellMenu>
                            <TableCell>
                              <StatusIcon
                                  src={Driving}
                                  alt="Driving"
                                />
                              Driving
                            </TableCell>
                            <TableCell><SearchBtn>Cancel Order</SearchBtn></TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell>1</TableCell>
                            <TableCell>August 25,2023</TableCell>
                            <TableCell>13S42G342E</TableCell>
                            <TableCell>MV23RE</TableCell>
                            <TableCellMenu>Yangnyum Galbi x1, Japche, Noodle, Gimchi, Rice, Taco</TableCellMenu>
                            <TableCell>
                              <StatusIcon
                                  src={Cancled}
                                  alt="Cancled"
                                />
                              Cancled
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>1</TableCell>
                            <TableCell>August 25,2023</TableCell>
                            <TableCell>13S42G342E</TableCell>
                            <TableCell>MV23RE</TableCell>
                            <TableCellMenu>Yangnyum Galbi x1, Japch...</TableCellMenu>
                            <TableCell>
                              <StatusIcon
                                  src={Completed}
                                  alt="Completed"
                                />
                              Completed
                            </TableCell>
                            <TableCell><SearchBtn>Cancel Order</SearchBtn></TableCell>
                        </TableRow>
                    </TableBody>
          </Table>
          </TableContainer>
           {/* 페이지네이션 */}
           <PaginationContainer>
            <PaginationButton onClick={goToPreviousPage}>Previous</PaginationButton>
            {renderPageButtons()}
            <PaginationButton onClick={goToNextPage}>Next</PaginationButton>
          </PaginationContainer>
        </Area>
      </Content>
    </>
  );
};

const Content = styled.div`
  display: inline-flex;
`;

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 353px;
`;

const Title = styled.div`
  font-family: 'Poppins';
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #22232b;
  margin-top: 78px;
  margin-left: 35px;
`;

const Area = styled.div`
    height: 928px;
    width: 995px;
    margin-top: 54px;
    position: absolute;
    margin-top: 168px;
    margin-left: 354px;
`;

const StatusIcon = styled.img`
    width: 8px;
    height: 8px;
    flex-shrink: 0;
    margin-right: 8px;
`

const TextInput1 = styled.input`
  background-color: #F1F1F4;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  color:  #838796;
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
  width: 300px;
  height: 32px;
  display: flex; 
  align-items: center; 
  gap: 8px;
  padding-left: 12px;
  margin-top: -14px;

  &:focus {
        outline: none; 
        border: 2px solid #ED6A2C; 
    }
`;

const SearchBtn = styled.div`
  width: 101px;
  height: 25px;
  display: inline-flex;
  /* padding: 5px 10px; */
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 3px;
  border: 1px solid #9EA1AE;
  background: #FFF;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  color: var(--content-gray-900, #22232B);
  font-family: 'Poppins';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px; 
  cursor: pointer;
  align-items: center;
  margin-top: -12px;
`;

const DropdownContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    margin-top: 8px;
    margin-bottom: 24px;
`;

const DropdownButton = styled.button`
    background-color: #f1f1f1;
    border: none;
    padding: 10px;
    cursor: pointer;
    color:  #83879d;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    width: 130px;
    height: 32px;
    display: flex; 
    align-items: center; 
    gap: 8px; 
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;

    span {
        //내용 한줄로 보이게
        white-space: nowrap; /
    }
`;

const DropdownContent = styled.div<{isOpen: boolean}>` 
    display: ${(props) => (props.isOpen ? 'block' : 'none')};
    position: absolute;
    background-color: #fff;
    min-width: 130px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
`;

const DropdownItem = styled.div`
    padding: 3px;
    width: 130px;
    cursor: pointer;
    &:hover {
        background-color: #f1f1f1;
    }
`;

const Image = styled.img`
    flex-shrink: 0;
    display: inline-block;
    padding-left: 85px;
    /* margin-left: 306px; */
    position: absolute;
`

const TableContainer = styled.div`
  height: 546px;
  //아래 테두리 표시
  border-bottom: 1px solid #1213171A;
`;

const Table = styled.table`
    border-collapse: collapse;
    width: 100%;
`;

const TableHeader = styled.thead`
    /* ... 테이블 헤더 관련 스타일링 */
    border-top: 1px solid #E4E4E4;
    border-bottom : 1px solid #E4E4E4;
    padding-top: 12px;
    padding-bottom: 12px;
    width: 995px; 
    color:  #838796;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
`;

const TableBody = styled.tbody`
 
`;

const TableRow = styled.tr`
      
`;

const TableCell = styled.td`
    padding-top: 24px;
    color: #000;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
`;

const TableCellMenu = styled.td`
  width: 191px;
  padding-top: 24px;
  color: #000;
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
  overflow: hidden; /* 넘치는 내용 숨김 */
  white-space: nowrap; /* 내용을 한 줄로 표시 */
  text-overflow: ellipsis; /* 넘치는 내용에 "..." 표시 */
  max-width: 191px; /* 셀의 최대 너비를 설정 */
  padding-right: 75px;
`;

const TableCellTitle = styled.td`
    color: #838796;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    padding-top: 12px;
    padding-bottom: 12px;   
`

const PageButton = styled.button`
 
`;

const PaginationContainer = styled.div`
  
`;

const PaginationButton = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
`;



export default TotalOrders;
