import styled from "styled-components";
import DriverSidebar from "../../../components/DriverSidebar";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";



const DriverInfo: React.FC = () => {
  return (
    <>
      <Header />
      <Sidebar />
      <Content>
        <DriverSidebar />
        <Title>Driver Info</Title>
      </Content>
    </>
  );
};

const Content = styled.div`
  display: inline-flex;
`;

const Title = styled.div`
  font-family: 'Poppins';
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #22232b;
  padding-top: 68px;
  padding-left: 20px;
`;

export default DriverInfo;