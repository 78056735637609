import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import ClientSidebar from "../../../components/ClientSidebar";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import DropDown from "../../../images/DropDown.png";

interface EditCouponProps {

};

const EditCoupon: React.FC<EditCouponProps> = () => {
    const [isOpen, setIsOpen] = useState(false);
    // 첫 번째 Dropdown
    const [isTruckTypeDropdownOpen, setIsTruckTypeDropdownOpen] = useState(false);
    const [selectedTruckType, setSelectedTruckType] = useState<string | null>(null);

    // 두 번째 Dropdown
    const [isDrivingAreaDropdownOpen, setIsDrivingAreaDropdownOpen] = useState(false);
    const [selectedDrivingArea, setSelectedDrivingArea] = useState<string | null>(null);

    // 세 번째 Dropdown
    const [isApplicableMenuDropdownOpen, setIsApplicableMenuDropdownOpen] = useState(false);
    const [selectedApplicableMenu, setSelectedApplicableMenu] = useState<string | null>(null);

    const truckTypeDropdownRef = useRef<HTMLDivElement>(null);
    const drivingAreaDropdownRef = useRef<HTMLDivElement>(null);
    const applicableMenuDropdownRef = useRef<HTMLDivElement>(null);

  
    const closeDropdowns = () => {
        setIsOpen(false);
    };
  
    const handleClickOutside = (event: MouseEvent) => {
    const isClickedInsideTruckTypeDropdown = truckTypeDropdownRef.current?.contains(event.target as Node);
    const isClickedInsideDrivingAreaDropdown = drivingAreaDropdownRef.current?.contains(event.target as Node);
        
    if (!isClickedInsideTruckTypeDropdown && !isClickedInsideDrivingAreaDropdown) {
        setIsTruckTypeDropdownOpen(false);
        setIsDrivingAreaDropdownOpen(false);
        }
    };

      useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);


      useEffect(() => {
          document.addEventListener('click', closeDropdowns);
          return () => {
              document.removeEventListener('click', closeDropdowns);
          };
      }, []);
  
      const toggleTruckTypeDropdown = () => {
        setIsTruckTypeDropdownOpen(!isTruckTypeDropdownOpen);
        setIsDrivingAreaDropdownOpen(false);
        setIsApplicableMenuDropdownOpen(false);
    };
    
    const toggleDrivingAreaDropdown = () => {
        setIsDrivingAreaDropdownOpen(!isDrivingAreaDropdownOpen);
        setIsTruckTypeDropdownOpen(false);
        setIsApplicableMenuDropdownOpen(false);
    };
    
    const toggleApplicableMenuDropdown = () => {
        setIsApplicableMenuDropdownOpen(!isApplicableMenuDropdownOpen);
        setIsTruckTypeDropdownOpen(false);
        setIsDrivingAreaDropdownOpen(false);
    };
    
    const handleCouponDateClick = (item: string) => {
        setSelectedTruckType(item);
        setIsTruckTypeDropdownOpen(false);
    };
    
    const handleCouponTypeClick = (item: string) => {
        setSelectedDrivingArea(item);
        setIsDrivingAreaDropdownOpen(false);
    };
    
    const handleApplicableMenuItemClick = (item: string) => {
        setSelectedApplicableMenu(item);
        setIsApplicableMenuDropdownOpen(false);
    };
    
   

  return (
    <>
      <Header />
      <Sidebar />
      <Content>
        <ClientSidebar />
        <Title>Edit Coupon</Title>
        <Area>
                <TitleDetail>Coupon Name</TitleDetail>
                <TextInput1 placeholder='text' />
                <TitleDetail>Description</TitleDetail>
                <DessriptionArea placeholder='text' />
                <TitleDetail>Date</TitleDetail>
                {/*드롭다운 메뉴 */}
                <DropdownContainer 
                    placeholder='select date'
                    ref={truckTypeDropdownRef}>
                    <DropdownButton>
                        <span>{selectedTruckType || "select"}</span>
                        <Image
                            src={DropDown}
                            alt='dropdown'
                            onClick={toggleTruckTypeDropdown}
                        />
                    </DropdownButton>
                    <DropdownContent isOpen={isTruckTypeDropdownOpen}>
                        <DropdownItem onClick={() => handleCouponDateClick("day")}>day</DropdownItem>
                        <DropdownItem onClick={() => handleCouponDateClick("week")}>week</DropdownItem>
                        <DropdownItem onClick={() => handleCouponDateClick("month")}>month</DropdownItem>
                    </DropdownContent>
                </DropdownContainer>
                <TitleDetail>Type</TitleDetail>
                {/*드롭다운 메뉴 */}
                <DropdownContainer 
                    placeholder='select area'
                    ref={drivingAreaDropdownRef}>
                    <DropdownButton>
                        <span>{selectedDrivingArea || "select"}</span>
                        <Image
                            src={DropDown}
                            alt='dropdown'
                            onClick={toggleDrivingAreaDropdown}
                        />
                    </DropdownButton>
                    <DropdownContent isOpen={isDrivingAreaDropdownOpen}>
                        <DropdownItem onClick={() => handleCouponTypeClick("all")}>all</DropdownItem>
                        <DropdownItem onClick={() => handleCouponTypeClick("one-menu")}>one-menu</DropdownItem>
                    </DropdownContent>
                </DropdownContainer>
                <TextInput placeholder='% / $' />
                <TitleDetail>Quantity limit</TitleDetail>
                <TextInput placeholder='quantity limit' />
                <TitleDetail>Applicable menu</TitleDetail>
                {/*드롭다운 메뉴 */}
                <DropdownContainer 
                    placeholder='select menu'
                    ref={applicableMenuDropdownRef}>
                    <DropdownButton>
                        <span>{selectedApplicableMenu || "select"}</span>
                        <Image
                            src={DropDown}
                            alt='dropdown'
                            onClick={toggleApplicableMenuDropdown}
                        />
                    </DropdownButton>
                    <DropdownContent isOpen={isApplicableMenuDropdownOpen}>
                        <DropdownItem onClick={() => handleApplicableMenuItemClick("?")}>?</DropdownItem>
                        <DropdownItem onClick={() => handleApplicableMenuItemClick("??")}>??</DropdownItem>
                    </DropdownContent>
                </DropdownContainer>
                {/* 완료 버튼  */}
                <Button>
                  Save
                </Button>
        </Area>
      </Content>
    </>
  );
};

const Title = styled.div`
  font-family: 'Poppins';
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #22232b;
  margin-top: 78px;
  margin-left: 35px;
`;

const Area = styled.div`
    height: 928px;
    width: 995px;
    margin-top: 54px;
    position: absolute;
    margin-top: 168px;
    margin-left: 354px;
`

const Content = styled.div`
  display: inline-flex;
`;

const TextInput = styled.input`
    background-color: #F1F1F4;
    border: none;
    cursor: pointer;
    color:  #838796;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    width: 522px;
    height: 32px;
    display: flex; 
    align-items: center; 
    gap: 8px;
    padding-left: 12px;
    margin-top: 8px;

    &:focus {
        outline: none; 
        border: 2px solid #ED6A2C; 
    }
`;

const TextInput1 = styled.input`
    background-color: #F1F1F4;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  color:  #838796;
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
  width: 522px;
  height: 32px;
  display: flex; 
  align-items: center; 
  gap: 8px;
  padding-left: 12px;
  margin-top: 8px;

  &:focus {
        outline: none; 
        border: 2px solid #ED6A2C; 
    }
`;

const DessriptionArea = styled.textarea`
    background-color: #F1F1F4;
    border: none;
    cursor: pointer;
    color:  #22232B;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    width: 522px;
    height: 141px;
    display: flex; 
    gap: 8px;
    margin-top: 8px;
    padding-top: 12px;
    align-items: flex-start;
    resize: vertical;
    padding: 8px;
    border-radius: 3px;

    &:focus {
        outline: none; 
        border: 2px solid #ED6A2C; 
    }

    &::placeholder {
        color: #838796; 
    }
`;

const DropdownContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    margin-top: 8px;
    margin-bottom: 24px;
    width: 535px;
    border-radius: 3px;
    /* padding: 4 12px; */
`;

const DropdownButton = styled.button`
    background-color: #f1f1f1;
    border: none;
    padding: 10px;
    cursor: pointer;
    color:  #83879d;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    width: 545px;
    height: 32px;
    display: flex; 
    align-items: center; 
    gap: 8px; 
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;

    span {
        //내용 한줄로 보이게
        white-space: nowrap; /
    }
`;

const DropdownContent = styled.div<{isOpen: boolean}>` 
    display: ${(props) => (props.isOpen ? 'block' : 'none')};
    position: absolute;
    background-color: #fff;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    width: 535px;
`;

const DropdownItem = styled.div`
    padding: 3px;
    width: 535px;
    cursor: pointer;
    &:hover {
        background-color: #f1f1f1;
    }
`;

const Image = styled.img`
    flex-shrink: 0;
    display: inline-block;
    padding-left: 496px;
    /* margin-left: 306px; */
    position: absolute;
`

const TitleDetail = styled.div`
    color:  #838796;
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-top: 24px;
    margin=bottom: 8px;
`;

const Button = styled.div`
    width: 86px;
    height: 38px;
    border-radius: 3px;
    background: #121317;
    display: inline-flex;
    /* padding: 8px 24px; */
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #FFF;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin-top: 47px;
    margin-left: 445px;
`

export default EditCoupon;
