import React, { useState,  useEffect, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import DriverSidebar from "../../../components/DriverSidebar";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import DropDown from "../../../images/DropDown.png";
import { TruckDriver } from "../../../api/model/truckDriver";
import { adminTruckDriverControllerFindMany, adminTruckDriverControllerAssignTruck } from "../../../api/service/admin-truck-drivers/admin-truck-drivers";
import { truckControllerFindMany } from "../../../api/service/trucks/trucks";
import {DrivingStatus, Truck, VehicleStatus} from "../../../api/model";



  
interface TruckProps {

};


const TruckAssignment: React.FC<TruckProps> = () => {
  const [drivers, setDrivers] = useState<TruckDriver[]>([]); 
  const [loading, setLoading] = useState(false);
  const [trucks, setTrucks] = useState<Truck[]>([]); //트럭 리스트
  const [dropdownOpenStates, setDropdownOpenStates] = useState<{ [key: string]: boolean }>({});
  const [selectedTruckDetails, setSelectedTruckDetails] = useState<{ [driverId: string]: { id: string; licensePlateNumber: string } }>({});
  const [driverDistances, setDriverDistances] = useState<{ [driverId: string]: number }>({});
  const navigate = useNavigate();

  // Modify fetchTruckDrivers to initialize dropdownOpenStates
  useEffect(() => {
    const initialDropdownStates = drivers.reduce((acc, driver) => {
      acc[driver.id] = false;
      return acc;
    }, {} as { [key: string]: boolean });
    setDropdownOpenStates(initialDropdownStates);
  }, [drivers]);

  // Toggle dropdown for a specific driver
  const toggleDropdown = (driverId: string) => {
    setDropdownOpenStates((prevStates) => ({
      ...prevStates,
      [driverId]: !prevStates[driverId]
    }));
  };

  // Handle truck selection
  const handleTruckSelection = (driverId: string, truck: Truck) => {
    // Log the selected truck's ID
    console.log(`Selected truck ID for driver :`, truck.id, truck.licensePlateNumber);

    setSelectedTruckDetails((prevDetails) => ({
      ...prevDetails,
      [driverId]: { id: truck.id, licensePlateNumber: truck.licensePlateNumber }
    }));
    toggleDropdown(driverId);
};
  


  // 트럭 운전자 목록 조회(수정 전)
  // const fetchTruckDrivers = useCallback(async () => {
  //   setLoading(true);
  //   try {
  //     const { data } = await adminTruckDriverControllerFindMany();
  //     setDrivers(data.items); 
  //     console.log("truck assignment에서 트럭 운전자 목록 조회:", 
  //     data.items,
  //     );
  //   } catch (error) {
  //     console.error('truck assignment에서 트럭 운전자 목록 조회 에러:', error);
  //   }
  //   setLoading(false);
  // }, []);

  // 트럭 운전자 목록 조회(트럭 운전자에게 트럭 배정 시, assginment 값이 null인 데이터만 보여주기)
  const fetchTruckDrivers = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await adminTruckDriverControllerFindMany();
      // driver.assignment 값이 null인 운전자만 필터링
      const availableDrivers = data.items.filter(driver => driver.assignment === null);
      setDrivers(availableDrivers); 
      console.log("트럭 운전자 목록 조회:", availableDrivers);
    } catch (error) {
      console.error('트럭 운전자 목록 조회 에러:', error);
    }
    setLoading(false);
  }, []);

useEffect(() => {
  fetchTruckDrivers();
}, [fetchTruckDrivers]);



  // 트럭 운전자에게 트럭 배정 
  const assignTruckToDriver = async (driverId: string) => {
    const selectedTruck = selectedTruckDetails[driverId];
    const startDistance = driverDistances[driverId];
  
    if (!selectedTruck || !startDistance) {
      alert("Please select a truck and enter start distance");
      return;
    }
  
    try {
      const truckAssignmentDto = {
        startDistance: Number(startDistance),
        truckId: selectedTruck.id,
      };
      const response = await adminTruckDriverControllerAssignTruck(driverId, truckAssignmentDto);
      console.log("트럭 배정 성공:", response);
      navigate("/driver/driverinfo/start")
      alert("Truck assigned successfully");
    } catch (error) {
      //alert((error as Error).message);
      console.error("트럭 배정 에러:", (error as Error).name, (error as Error).message );
      alert("Failed to assign truck");
    }
  };
  

  // 트럭 목록 조회 
  const fetchTrucks = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await truckControllerFindMany();
      setTrucks(data.items); 
      console.log("트럭 목록 조회 성공", data.items);
    } catch (error) {
      console.error("트럭 목록 조회 에러", error);
    }
    setLoading(false);
  }, []);

  
  
  useEffect(() => {
    fetchTrucks();
  }, [fetchTrucks]);
  



  return (
    <>
      <Header />
      <Sidebar />
      <Content>
        <DriverSidebar />
        <Title>Truck Assignment</Title>
        <Area>
          <p style={{ fontSize: '20px', color: '#D9534F', marginTop: '4px' }}>
            * Matching is only possible if the truck's driving status is 'IDLE' and its availability is 'AVAILABLE'.<br />
            If you cannot match the truck you want, please check the truck's status on the Truck Delivery Status screen.
          </p>
                <Table>
                    <TableHeader>
                        <TableCellTitle>Driver ID</TableCellTitle>
                        <TableCellTitle>Driver</TableCellTitle>
                        <TableCellTitle>Truck Number</TableCellTitle>
                        <TableCellTitle>Dashboard</TableCellTitle>
                        <TableCellTitle></TableCellTitle>
                    </TableHeader> 
                <TableBody>
                {drivers.map((driver) => (
                        <TableRow key={driver.id}>
                            <TableCell>{driver.id}</TableCell>
                            <TableCell>{driver.firstName + ' ' + driver.lastName}</TableCell>
                            
                            <TableCell>
                            <DropdownContainer>
                            <DropdownButton onClick={() => toggleDropdown(driver.id)}>
                              <span style={{ color: '#000' }}>{selectedTruckDetails[driver.id]?.licensePlateNumber || "Select"}</span>
                              <Image src={DropDown} alt='dropdown' />
                            </DropdownButton>
                            {dropdownOpenStates[driver.id] && (
                              <DropdownContent isOpen>
                                {trucks.map((truck) => (
                                    (truck.drivingStatus === DrivingStatus.IDLE && truck.vehicleStatus === VehicleStatus.AVAILABLE) ? <DropdownItem key={truck.id} onClick={() => handleTruckSelection(driver.id, truck)}>
                                      {truck.licensePlateNumber}
                                    </DropdownItem> : null
                                ))}
                              </DropdownContent>
                            )}
                            </DropdownContainer>
                            </TableCell>
                            <TableCell>
                                {/* textinput 보여주기 */}
                                <TextInput 
                                  type="text" 
                                  value={driverDistances[driver.id] || ''} 
                                  onChange={(e) => {
                                    const distance = Number(e.target.value);
                                    if (!isNaN(distance)) {
                                      setDriverDistances({ ...driverDistances, [driver.id]: distance });
                                    }
                                  }} 
                                  placeholder="mile"
                                />
                            </TableCell>
                            <TableCell>
                                <SaveBtn onClick={() => assignTruckToDriver(driver.id)}>Save</SaveBtn>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                </Table>
            </Area>
      </Content>
    </>
  );
};

const Content = styled.div`
  display: inline-flex;
`;

const Title = styled.div`
  font-family: 'Poppins';
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #22232b;
  margin-top: 78px;
  margin-left: 35px;
`;

const Area = styled.div`
    height: 928px;
    width: 995px;
    margin-top: 54px;
    position: absolute;
    margin-top: 168px;
    margin-left: 354px;
`

const DropdownContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    margin-top: 18px;
    margin-bottom: 24px;
`;

const DropdownButton = styled.button`
    background-color: #F1F1F4;
    border: none;
    padding: 10px;
    cursor: pointer;
    color:  #838796;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    min-width: 340px;
    height: 32px;
    display: flex; 
    align-items: center; 
    gap: 8px; 
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;

    span {
        //내용 한줄로 보이게
        white-space: nowrap; /
    }
`;

const DropdownContent = styled.div<{ isOpen: boolean }>`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  position: absolute;
  top: 100%;
  left: 0;
  width: 240px; // 드롭다운 버튼의 너비와 동일하게 설정
  background-color: #fff;
  min-width: 340px;
  /* height: 32px; */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 4px;
  overflow: hidden;
`;

// DropdownItem의 너비를 DropdownButton과 동일하게 맞추기 위해 수정합니다.
const DropdownItem = styled.div`
  margin-left: 12px;
  margin-top: 12px;
  color: #838796;
  background-color: red
  padding: 3px;
  min-width: 340px;
  height: 32px;
  cursor: pointer;
  &:hover {
    background-color: #f1f1f1;
  }
`;


const Image = styled.img`
    flex-shrink: 0;
    display: inline-block;
    padding-left: 296px;
    /* margin-left: 306px; */
    position: absolute;
    color: #2FAF4B; 
`

const Button = styled.div`
    width: 135px;
    height: 38px;
    display: inline-flex;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 3px;
    background-color: #121317;
    color: #FFFFFF;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; 
    margin-left: 1191px;
    margin-right: 114px;
    margin-top: 411px;
`
const SaveBtn = styled.div`
    width: 50px;
    height: 25px;
    display: inline-flex;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 3px;
    background: #121317;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    color: #FFFFFF;
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px; 
    cursor: pointer;
`

const Table = styled.table`
    border-collapse: collapse;
    width: 100%;
`;

const TableHeader = styled.thead`
    /* ... 테이블 헤더 관련 스타일링 */
    border-top: 1px solid #E4E4E4;
    border-bottom : 1px solid #E4E4E4;
    padding-top: 12px;
    padding-bottom: 12px;
    width: 995px; 
    color:  #838796;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
`;

const TableBody = styled.tbody`
 
`;

const TableRow = styled.tr`
      
`;

const TableCell = styled.td`
    padding-top: 24px;
    color: #000;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
`;

const TableCellTitle = styled.td`
    color: #838796;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    padding-top: 12px;
    padding-bottom: 12px;   
`

const TextInput = styled.input`
  width: 132px;
  height: 32px;
  border: none;
  background-color: #F1F1F4;
  border-radius: 4px; 
  font-size: 14px;
  color: #000; 
  padding-left: 12px;

  ::placeholder {
    color: #C0C0C0;
  }
  
  :focus {
    outline: none;
    border: 1px solid #ED6A2C;
  }
`;


export default TruckAssignment;