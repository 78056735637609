import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Auth } from "aws-amplify";

interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const navigate = useNavigate();

  // 드롭다운 열기/닫기
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // 로그아웃 모달 열기
  // const handleLogoutModal = () => {
  //   setIsLogoutModalOpen(true); // 모달 열기
  //   setIsDropdownOpen(false); // 드롭다운 닫기
  // };

  // // 로그아웃 모달 닫기
  // const closeLogoutModal = () => {
  //   setIsLogoutModalOpen(false); // 모달 닫기
  // };

  // 로그아웃 처리
  const handleLogout = async () => {
    try {
      await Auth.signOut(); // AWS Cognito에서 로그아웃
      console.log("로그아웃 성공");
      alert("Successfully logged out");
      navigate("/login");
    } catch (error) {
      console.error("로그아웃 실패:", error);
      alert("로그아웃 실패, 잠시 후 다시 시도하세요");
    }
  };

  return (
    <HeaderWrapper>
      <LoginWrapper onClick={toggleDropdown}>
        <LoginImage />
        <LoginUser>Admin</LoginUser>
        {isDropdownOpen && (
          <Dropdown>
            <DropdownItem onClick={handleLogout}>Logout</DropdownItem>
          </Dropdown>
        )}
      </LoginWrapper>

      {/* {isLogoutModalOpen && ( // 모달이 열릴 때만 모달 컴포넌트를 보여줍니다.
        <Modal>
          <ModalContent>
            <ModalText>로그아웃 하시겠습니까?</ModalText>
            <ModalButtons>
              <CancelButton onClick={closeLogoutModal}>취소</CancelButton>
              <ConfirmButton onClick={handleLogout}>로그아웃</ConfirmButton>
            </ModalButtons>
          </ModalContent>
        </Modal>
        )} */}
    </HeaderWrapper>
  );
};

const Modal = styled.div``;

const ModalContent = styled.div``;

const ModalText = styled.div``;

const ModalButtons = styled.div``;

const CancelButton = styled.div``;

const ConfirmButton = styled.div``;

const Dropdown = styled.div``;

const DropdownItem = styled.div`
  /* color: #000; */
  color: #e7e7e7;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

//드롭다운 아이콘
const LoginMore = styled.div`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid black;
  display: inline-block;
`;

const LoginUser = styled.div`
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
`;

const LoginImage = styled.div`
  width: 32px;
  height: 32px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
`;

const LoginWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 10px;
  margin-right: 25px;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  height: 48px;
  background-color: #a3a3a3;
  display: inline-flex;
  justify-content: flex-end;
  position: absolute;
`;

export default Header;
