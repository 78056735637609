import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

const DriverSidebar: React.FC = () => {
  const driverMenuList = [
    "Truck Info",
    "Truck Delivery Status",
    "Driver Info",
    "Inventory Registration",
    "Inventory",
  ];

  const driverSubMenuList = [
    "Drivers",
    "Commute",
    "Truck Assignment",
    "Release Assignment",
  ];

  const navigate = useNavigate();
  const location = useLocation();

  const [driverMenu, setDriverMenu] = useState<string>("");
  const [subDriverMenu, setSubDriverMenu] = useState<string>("");

  useEffect(() => {
    // 경로의 전체 일치 확인
    const path = location.pathname.replace("/driver/", "");

    if (path.startsWith("driverInfo/drivers")) {
      setSubDriverMenu("Drivers");
      setDriverMenu("driverinfo/drivers");
    } else if (path.startsWith("driverInfo/work")) {
      setSubDriverMenu("Commute");
      setDriverMenu("driverinfo/work");
    } else if (path.startsWith("driverInfo/truck")) {
      setSubDriverMenu("Truck Assignment");
      setDriverMenu("driverinfo/truck");
    } else if (path.startsWith("driverInfo/start")) {
      setSubDriverMenu("Release Assignment");
      setDriverMenu("driverinfo/start");
    } else {
      // 각 메뉴 항목에 대해 정확한 경로로 상태를 설정
      setDriverMenu(path);
      setSubDriverMenu("");
    }
  }, [location]);

  return (
    <DriverSideMenuWrapper>
      {driverMenuList.map((menu) => {
        const diffMenu = menu.toLocaleLowerCase().replace(/\s+/g, "");
        const isMenuActive = location.pathname.endsWith(`/${diffMenu}`);

        if (diffMenu !== "driverinfo") {
          return (
            <DriverSideMenuContent
              key={menu}
              clicked={isMenuActive}
              onClick={() => {
                setDriverMenu(diffMenu);
                setSubDriverMenu("");
                navigate("/driver/" + diffMenu);
              }}
            >
              {menu}
            </DriverSideMenuContent>
          );
        } else {
          return (
            <DriverSideMenuContent
              key={menu}
              // clicked={driverMenu.includes(diffMenu)}
              clicked={driverMenu === diffMenu} //includes에서 정확한 일치로 변경
              onClick={() => {
                setDriverMenu(diffMenu);
              }}
            >
              {menu}
              {driverMenu.includes("driverinfo") ? (
                <div>
                  {driverSubMenuList.map((subMenu) => {
                    let loc = "";
                    if (subMenu === "Drivers") {
                      loc = "drivers";
                    } else if (subMenu === "Commute") {
                      loc = "work";
                    } else if (subMenu === "Truck Assignment") {
                      loc = "truck";
                    } else {
                      loc = "start";
                    }
                    return (
                      <DriverSubSideMenuContent
                        key={subMenu}
                        clicked={subDriverMenu.includes(subMenu)}
                        onClick={() => {
                          setSubDriverMenu(subMenu);
                          navigate("/driver/driverInfo/" + loc);
                        }}
                      >
                        {subMenu}
                      </DriverSubSideMenuContent>
                    );
                  })}
                </div>
              ) : (
                <></>
              )}
            </DriverSideMenuContent>
          );
        }
      })}
    </DriverSideMenuWrapper>
  );
};

interface StyledButtonProps {
  clicked: boolean;
}

const DriverSubSideMenuContent = styled.div<StyledButtonProps>`
  color: ${({ clicked }) => (clicked ? "#22232B" : "#838796")};
  font-weight: ${({ clicked }) => (clicked ? 700 : 400)};

  margin: 24px 0px 24px 20px;
`;

const DriverSideMenuContent = styled.div<StyledButtonProps>`
  color: ${({ clicked }) => (clicked ? "#22232B" : "#838796")};
  font-weight: ${({ clicked }) => (clicked ? 700 : 400)};

  margin: 24px 0px 24px 20px;
`;

const DriverSideMenuWrapper = styled.div`
  padding-top: 48px;
  padding-left: 68px;
  width: 250px;
  height: 100vh;
  background-color: #eeeeee;
`;

export default DriverSidebar;
