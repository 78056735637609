/**
 * Generated by orval v6.19.0 🍺
 * Do not edit manually.
 * OLHSO API
 * The OLHSO API description
 * OpenAPI spec version: 0.1
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  AdminTruckDriverControllerFindManyParams,
  CreateTruckDriverDto,
  TruckAssignmentDto,
  TruckDriver,
  TruckDriverAttendance,
  TruckDriverDriving,
  TruckDriverList,
  TruckUnAssignmentDto,
  UpdateTruckDriverDto
} from '../../model'



  /**
 * @summary 트럭 운전자 생성
 */
export const adminTruckDriverControllerCreate = <TData = AxiosResponse<TruckDriver>>(
    createTruckDriverDto: CreateTruckDriverDto, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `/olhso/admin/truck-drivers`,
      createTruckDriverDto,options
    );
  }
/**
 * @summary 트럭 운전자 목록 조회
 */
export const adminTruckDriverControllerFindMany = <TData = AxiosResponse<TruckDriverList>>(
    params?: AdminTruckDriverControllerFindManyParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/olhso/admin/truck-drivers`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }
/**
 * @summary 단일 트럭 운전자 조회
 */
export const adminTruckDriverControllerFindOne = <TData = AxiosResponse<TruckDriver>>(
    id: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/olhso/admin/truck-drivers/${id}`,options
    );
  }
/**
 * @summary 트럭 운전자 정보 업데이트
 */
export const adminTruckDriverControllerUpdate = <TData = AxiosResponse<TruckDriver>>(
    id: string,
    updateTruckDriverDto: UpdateTruckDriverDto, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.put(
      `/olhso/admin/truck-drivers/${id}`,
      updateTruckDriverDto,options
    );
  }
/**
 * @summary 트럭 운전자 비활성화
 */
export const adminTruckDriverControllerRemove = <TData = AxiosResponse<TruckDriver>>(
    id: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.delete(
      `/olhso/admin/truck-drivers/${id}`,options
    );
  }
/**
 * @summary 트럭 운전자 출근
 */
export const adminTruckDriverControllerCreateAttendance = <TData = AxiosResponse<TruckDriverAttendance>>(
    id: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `/olhso/admin/truck-drivers/${id}/attendances`,undefined,options
    );
  }
/**
 * @summary 트럭 운전자 퇴근
 */
export const adminTruckDriverControllerRemoveAttendance = <TData = AxiosResponse<TruckDriverAttendance>>(
    id: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.delete(
      `/olhso/admin/truck-drivers/${id}/attendances`,options
    );
  }
/**
 * @summary 트럭 운전자에게 트럭 배정
 */
export const adminTruckDriverControllerAssignTruck = <TData = AxiosResponse<TruckDriverDriving>>(
    id: string,
    truckAssignmentDto: TruckAssignmentDto, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `/olhso/admin/truck-drivers/${id}/truck-assignments`,
      truckAssignmentDto,options
    );
  }
/**
 * @summary 트럭 운전자에게 배정된 트럭 해제
 */
export const adminTruckDriverControllerUnAssignTruck = <TData = AxiosResponse<TruckDriverDriving>>(
    id: string,
    truckUnAssignmentDto: TruckUnAssignmentDto, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.put(
      `/olhso/admin/truck-drivers/${id}/truck-assignments`,
      truckUnAssignmentDto,options
    );
  }
/**
 * @summary 트럭 운전자 운행 시작
 */
export const adminTruckDriverControllerStartDriving = <TData = AxiosResponse<TruckDriverDriving>>(
    id: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.put(
      `/olhso/admin/truck-drivers/${id}/driving`,undefined,options
    );
  }
/**
 * @summary 트럭 운전자 운행 종료
 */
export const adminTruckDriverControllerFinishDriving = <TData = AxiosResponse<TruckDriverDriving>>(
    id: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.delete(
      `/olhso/admin/truck-drivers/${id}/driving`,options
    );
  }
