import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import DriverSidebar from "../../components/DriverSidebar";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import DropDown from "../../images/DropDown.png";
import InventoryModal from '../../components/InventoryModal';
import XBtn from "../../images/XBtn.png";
import {Recipe, Truck, TruckStockForTruck, UpdateTruckStocksBulkForTruckDto} from "../../api/model";
import {
    adminTruckStockControllerFindOneByTruckId, adminTruckStockControllerUpdate, 
    // AdminTruckStockControllerUpdateResult // orval 없다고 떠서 임시로 주석 처리 
} from "../../api/service/admin-trucks-stock-for-truck/admin-trucks-stock-for-truck";
import {initToken} from "../../utils/setAuthToken";
import {adminTruckControllerFindMany} from "../../api/service/admin-trucks/admin-trucks";
import {adminRecipeControllerFindMany} from "../../api/service/admin-recipes/admin-recipes";
import {AxiosError} from "axios";

interface InventoryRegistrationProps {

};

interface ScheduledOrder {
    recipeId: string,
    recipeName: string,
    menuQty: number
  }
  interface truckStockForRequestBodyDto {
    slotNum: string,
      menuQty: number,
      recipeId?: string,
      truckSlotArrId: string
  }
interface reserveOrderStockForRequestBodyDto {
    recipeId: string,
    menuQty: number
}


const InventoryRegistration: React.FC<InventoryRegistrationProps> = () => {
    const [recipes, setRecipes] = useState<Recipe[]>([])
    const [inventorys, setInventorys] = useState<TruckStockForTruck[]>([])
    const [trucks, setTrucks] = useState<Truck[]>([])
    const [selectedTruck, setSelectedTruck] = useState<{id: string, licensePlateNumber: string, truckArray: string} | null>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isTruckTypeDropdownOpen, setIsTruckTypeDropdownOpen] = useState(false);
    const [isDrivingAreaDropdownOpen, setIsDrivingAreaDropdownOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isNewDropdownOpen, setIsNewDropdownOpen] = useState(false);
    const [isModalMenuDropdownOpen, setIsModalMenuDropdownOpen] = useState(false);
    const [selectedNewOption, setSelectedNewOption] = useState<{id: string, name: string}>({id: "", name: ""});
    const truckTypeDropdownRef = useRef<HTMLDivElement>(null);
    const drivingAreaDropdownRef = useRef<HTMLDivElement>(null);
    const menuSelectDropdownRef = useRef<HTMLDivElement>(null);
    const [selectedInventory, setSelectedInventory] = useState<string | null>(null);
    const inventoryDropdownRef = useRef<HTMLDivElement>(null);
    const [isDropdown1Active, setIsDropdown1Active] = useState(false); // 배열 드롭을 선택해야 트럭 넘버 활성화되도록
    const [selectedInventoryBox, setSelectedInventoryBox] = useState<TruckStockForTruck | null>(null); // 선택한 InventoryBox의 이름을 저장할 상태 추가
    const [selectedInventoryBoxIndex, setSelectedInventoryBoxIndex] = useState<number>(0); // 선택한 InventoryBox의 인덱스를 저장할 상태 추가
    const [modalQuantity, setModalQuantity] = useState<string>("0");
    const [scheduledOrders, setScheduledOrders] = useState<ScheduledOrder[]>([]);

    const navigate = useNavigate()
    const handleModalQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const numericQuantity = value === '' ? 0 : Math.min(Math.max(parseInt(value, 10), 0), 4);
        setModalQuantity(numericQuantity.toString());
    };


    const closeDropdowns = () => {
          setIsOpen(false);
      };

    const initTokenAndGetToken = async () => {
        await initToken()
    }
    const fetchTrucks = async () => {
        try {
            const responseGetTrucks = await adminTruckControllerFindMany();
            const truck = responseGetTrucks.data.items;
            setTrucks(truck)
        } catch (error) {
            console.error('트럭 데이터를 불러오는 중 에러 발생:', error);
            alert('Server Error, Please try again later.');
        }
    }
    const returnRecipes = async () => {
        try{
            const responseGetRecipes = await adminRecipeControllerFindMany()
            const recipes = responseGetRecipes.data
            return recipes
        } catch (error) {
            console.error('레시피 데이터를 불러오는 중 에러 발생:', error);
            alert('Server Error, Please try again later.');
        }
    }
    const makeInitScheduleOrders = (recipes: Recipe[]) => {
        const initScheduleOrders: ScheduledOrder[] = []
        recipes.forEach(recipe => {
            initScheduleOrders.push({
                recipeId: recipe.id,
                recipeName: recipe.name,
                menuQty: 0
            })
        })
        setScheduledOrders(initScheduleOrders)
    }
    const TruckStockInit = (stocks: TruckStockForTruck[]) => {
        const initStocks: TruckStockForTruck[] = stocks.map(stock => {
            stock.recipe = undefined
            stock.menuQty = 0
            return stock
        })
        return initStocks
    }
    const handleGetTruckStocks= async (truckId:string) => {
        try {
            const response = await adminTruckStockControllerFindOneByTruckId(truckId)
            const initStocks = TruckStockInit(response.data)
            setInventorys(initStocks) //TODO: 트럭의 재고 배열정보를 state에 저장
        } catch (error) {
            console.error('트럭 재고를 불러오는 중 에러 발생:', error);
            alert('Server Error, Please try again later.');
        }
    }

      const handleClickOutside = (event: MouseEvent) => {
        const isClickedInsideTruckTypeDropdown = truckTypeDropdownRef.current?.contains(event.target as Node);
        const isClickedInsideDrivingAreaDropdown = drivingAreaDropdownRef.current?.contains(event.target as Node);
        
        if (!isClickedInsideTruckTypeDropdown && !isClickedInsideDrivingAreaDropdown) {
            setIsTruckTypeDropdownOpen(false);
            setIsDrivingAreaDropdownOpen(false);
        }
    };

      useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

      useEffect(() => {
          document.addEventListener('click', closeDropdowns);
          return () => {
              document.removeEventListener('click', closeDropdowns);
          };
      }, []);

    //TODO:화면 로딩시 최초 1회만 트럭데이터 불러오기 및 트럭 넘버 드롭다운 활성화
    useEffect(() => {
        const fetchData = async () => {
            const token = await initTokenAndGetToken();
            const recipesData = await returnRecipes();
            if (recipesData) {
                setRecipes(recipesData);
                makeInitScheduleOrders(recipesData)
            }
            await fetchTrucks();
            setIsDropdown1Active(true);
        };
        fetchData();
    }, []);


    const toggleTruckTypeDropdown = () => {
        setIsTruckTypeDropdownOpen(!isTruckTypeDropdownOpen);
        setIsDrivingAreaDropdownOpen(false);
    };

    
    const handleTruckTypeItemClick = (item: {id: string, licensePlateNumber: string, truckArray: string }) => {
        setSelectedTruck(item); // TODO: 선택된 트럭의 licensePlateNumber를 보여주기 위해 state에 저장
        setSelectedInventory(item.truckArray) // TODO: 선택된 트럭의 배열 정보를 보여주기 위해 state에 저장
        handleGetTruckStocks(item.id) // TODO: 선택된 트럭의 재고 정보를 불러오기 위해 함수 호출
        setIsTruckTypeDropdownOpen(false);
    };
    // 새로운 드롭다운을 토글하는 함수
    const toggleInventoryDropdown = () => {
      setIsNewDropdownOpen(!isNewDropdownOpen);
    };

    const handleModalMenuDropdownItemClick = (item: {id: string, name: string}) => {
      setSelectedNewOption(item);
      setIsModalMenuDropdownOpen(false); // 아이템을 클릭하면 드롭다운을 닫습니다.
    };

    // 새로운 드롭다운을 토글하는 함수
    const toggleModalMenuyDropdown = () => {
      setIsModalMenuDropdownOpen(!isModalMenuDropdownOpen);
    };

    //재고 입력 모달 열기 함수 
    const openModal = () => {
      setIsModalOpen(true);
    };

    //재고 입력 모달 닫기 함수 
    const closeModal = () => {
      setIsModalOpen(false);
    };

    const InventoryBox: React.FC<{ onClick: () => void; name: string, menuQty: number, idx: number }> = ({
      onClick,
      name, idx
    }) => (
      <StyledInventoryBox onClick={onClick}>
        <div style={{ fontWeight: "500", fontSize: "14px", lineHeight: "20px", color: "#9EA1AE" }}>
          {inventorys[idx].recipe?.name || "Empty"}
        </div>
        <div style={{ marginTop: "70px", color: "#838796", fontWeight: "bold", fontSize: "14px" }}>
          {inventorys[idx].menuQty}
        </div>
      </StyledInventoryBox>
    );

    //모달의 SaveBtn 클릭 시, 모달에서 선택한 DropdownItem 값과 입력한 QuantityTextInput 값을 InventoryBox에 전달 후 모달을 닫는 함수
    const handleModalSaveBtnClick = () => {
        const updatedInventorys = inventorys.map((stock, index) => {
            if (index === selectedInventoryBoxIndex) {
                return {
                    ...stock,
                    recipe: recipes.find(recipe => recipe.id === selectedNewOption.id),
                    menuQty: parseInt(modalQuantity)
                };
            }
            return stock;
        });
        setInventorys(updatedInventorys);
        setModalQuantity("0")
        setSelectedNewOption({id: "", name: ""})
        setIsModalOpen(false);
    };
    const makeTruckStocksArr = (inventorys: TruckStockForTruck[]) => {
        const truckStocksArr: truckStockForRequestBodyDto[] = []
        inventorys.forEach(inventory => {
            inventory.recipe? truckStocksArr.push({
                slotNum: inventory.slotNum,
                menuQty: inventory.menuQty,
                recipeId: inventory.recipe.id,
                truckSlotArrId: inventory.id
            }) :
                truckStocksArr.push({
                slotNum: inventory.slotNum,
                menuQty: inventory.menuQty,
                truckSlotArrId: inventory.id
            })
        })
        return truckStocksArr
    }
    const makeReserveOrderStocksArr = (scheduledOrders: ScheduledOrder[]) => {
        const reserveOrderStocksArr: reserveOrderStockForRequestBodyDto[] = []
        scheduledOrders.forEach(scheduledOrder => {
            reserveOrderStocksArr.push({
                recipeId: scheduledOrder.recipeId,
                menuQty: scheduledOrder.menuQty
            })
        })
        return reserveOrderStocksArr
    }
    const handleSaveBtnClick = async () => {
        try {
            if(!selectedTruck) {
                alert('Please select a truck');
                return;
            } else {
                const updatedStocks: UpdateTruckStocksBulkForTruckDto = {
                    truckStocksArr: makeTruckStocksArr(inventorys),
                    reserveOrderStocksArr: makeReserveOrderStocksArr(scheduledOrders)
                }
                await adminTruckStockControllerUpdate(selectedTruck.id, updatedStocks)
                navigate("/driver/inventory", {state: { selectedTruck }})
            }
        } catch (error: any) {
        // orval 없다고 떠서 임시로 주석 처리 
        // (error: AxiosError<AdminTruckStockControllerUpdateResult> | any) {
            console.error('트럭 재고를 저장하는 중 에러 발생:', error);
            console.log("error", error.response)
            alert('Error handleSaveBtnClick, Please try again later.');
        }
    }
    const handleInventoryBoxClick = (index: number) => {
      setSelectedInventoryBox(inventorys[index]);
      setSelectedInventoryBoxIndex(index);
      openModal();
    };

    //예약 주문 관련 메뉴 항목 수량 변경하는 함수
    const handleQuantityChange = (recipeId: string, quantity: string) => {
        const numericQuantity = quantity === '' ? 0 : Math.min(Math.max(parseInt(quantity, 10), 0), 50);
        setScheduledOrders(prevOrders => prevOrders.map(order => {
            if (order.recipeId === recipeId) {
                return { ...order, menuQty: numericQuantity };
            }
            return order;
        }));
    };

    return (
    <>
      <Header />
      <Sidebar />
      <Content>
        <DriverSidebar />
        <Title>Inventory Registration</Title>
        <Area>
            <p style={{ fontSize: '20px', color: '#D9534F', marginTop: '4px' }}>
                * Inventory cannot be changed while the truck is driving.
            </p>
                <TitleDetail>Truck Number</TitleDetail>
                {/*트럭 넘버 드롭다운 메뉴 */}
                <DropdownContainer1 ref={truckTypeDropdownRef}>
                    <DropdownButton>
                        <SelectedItemText>{selectedTruck ? selectedTruck.licensePlateNumber : "Select Truck"}</SelectedItemText>
                        <DropDownImage
                            src={DropDown}
                            alt="dropdown"
                            onClick={toggleTruckTypeDropdown}
                        />
                    </DropdownButton>
                    <DropdownContent1 isOpen={isTruckTypeDropdownOpen && isDropdown1Active}> {/* isActive 여부에 따라 활성화 */}
                        {trucks.map((truck, index) => (
                            <DropdownItem1 key={index} onClick={() => handleTruckTypeItemClick({id: truck.id, licensePlateNumber: truck.licensePlateNumber, truckArray: `${truck.slotArray?.row}X${truck.slotArray?.col}`})}>
                                {truck.licensePlateNumber}
                            </DropdownItem1>
                        ))}
                    </DropdownContent1>
                </DropdownContainer1>

                <TitleDetail>Select Arrays</TitleDetail> 
                {/*배열 드롭다운 메뉴 */}
                <DropdownContainer ref={inventoryDropdownRef}>
                <DropdownButton>
                  <SelectedItemText>{selectedInventory || "A truck has not been selected yet"}</SelectedItemText>
                </DropdownButton>
                </DropdownContainer>

                 {/* 재고 입력 박스 */}
                <InventoryBoxes>
                  {inventorys.map((stock, index) => (
                    <InventoryBox
                      key={stock.id}
                      onClick={() => handleInventoryBoxClick(index)}
                      name={stock.recipe?.name || "Empty"}
                      menuQty={stock.menuQty}
                      idx={index}
                    />
                  ))}
                </InventoryBoxes>
                {/* 예약 주문 재고 입력 영역 */}
                <ScheduledOrderContainer>
                <ScheduledOrderTitle>Scheduled Order</ScheduledOrderTitle>
                <TitleDetailContainer>
                    <TitleDetail>Menu</TitleDetail>
                    <TitleDetail>Quantity</TitleDetail>
                </TitleDetailContainer>
                <Line />
                    {scheduledOrders.map((scheduledOrder, index) => (
                        <ScheduledOrderItem key={scheduledOrder.recipeId}>
                            <MenuItem>{scheduledOrder.recipeName}</MenuItem>
                            <QuantityInput
                                min="0"
                                max="50"
                                value={scheduledOrder.menuQty.toString()}
                                onChange={(e) => handleQuantityChange(scheduledOrder.recipeId, e.target.value)}
                            />
                        </ScheduledOrderItem>
                    ))}
                </ScheduledOrderContainer>
                {/* 완료 버튼  */}
                <Button
                    onClick={handleSaveBtnClick}
                >
                  Save
                </Button>
                {/* ----------------- 모달 컴포넌트 렌더링 ----------------*/}
                <InventoryModal isOpen={isModalOpen} onClose={closeModal}>
                  {/* 모달 내용 */}
                  <ModalContainer>
                    <XBtnImage
                      src={XBtn}
                      alt="xbtn"
                      onClick={closeModal} // X 버튼 클릭 시 모달 닫기
                    />
                    <ModalTitle>Section {selectedInventoryBox?.slotNum}</ModalTitle>
                    <ModalArea>
                      {/* 모달 내용 */}
                      <TruckNumber>Truck Number</TruckNumber>
                      <TruckNumberTextInput>{selectedTruck ? selectedTruck.licensePlateNumber : "Select Truck"}</TruckNumberTextInput>
                      <Menu>Menu</Menu>
                      {/* 모달 속 드롭다운 메뉴*/}
                      <DropdownContainer ref={menuSelectDropdownRef}>
                        <DropdownButton2 onClick={toggleModalMenuyDropdown}> {/* 클릭 시 드롭다운을 열거나 닫도록 수정 */}
                          <span>{selectedNewOption?.name || "select"}</span>
                          <Image src={DropDown} alt="dropdown" />
                        </DropdownButton2>
                        <DropdownContent2 isOpen={isModalMenuDropdownOpen}>
                            {scheduledOrders.map((scheduledOrder) => {
                                return (
                                    <DropdownItem2 key={scheduledOrder.recipeId} onClick={() => handleModalMenuDropdownItemClick({id: scheduledOrder.recipeId, name: scheduledOrder.recipeName})}>
                                        {scheduledOrder.recipeName}
                                    </DropdownItem2>
                                )
                            })}
                        </DropdownContent2>
                      </DropdownContainer>
                      <Quantity>Quantity</Quantity>
                      <QuantityTextInput
                        placeholder='0'
                        min="0"
                        max="4"
                        value={modalQuantity}
                        onChange={handleModalQuantityChange} // 입력값이 변경될 때 호출될 함수 설정
                      />
                      <SaveBtn
                        onClick={handleModalSaveBtnClick}
                      >Save</SaveBtn>
                    </ModalArea>
                  </ModalContainer>
                </InventoryModal>
                {/* ----------------- 모달 컴포넌트 렌더링 끝 ---------------- */}
        </Area>
      </Content>
    </>
  );
};

const SelectedItemText = styled.span`
  color: #22232B;
`;

const StyledInventoryBox = styled.div`
  width: 120px;
  height: 120px;
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  border-radius: 4px;
  border: 1px solid rgba(18, 19, 23, 0.10);
  background: #F1F1F4;
  color: #9EA1AE;
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; 
  letter-spacing: -0.07px;
  padding-top: 16px;
  padding-left: 16px;
`;

const Content = styled.div`
  display: inline-flex;
`;

const Title = styled.div`
  font-family: 'Poppins';
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #22232b;
  margin-top: 78px;
  margin-left: 35px;
`;

const Area = styled.div`
    height: 928px;
    width: 995px;
    margin-top: 54px;
    position: absolute;
    margin-top: 168px;
    margin-left: 354px;
`

const ModalArea = styled.div`
  margin-left: 30px;
  margin-top: 43px;
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */
`

const InventoryBoxes = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4개의 열로 배치 */
  grid-gap: 20px; /* 박스 간의 간격 설정 */
  width: 540px;
`;

const DropdownContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    margin-top: 8px;
    margin-bottom: 24px;
`;

const DropdownContainer1 = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    margin-top: 8px;
    margin-bottom: 24px;
`;

const DropdownButton = styled.button`
    background-color: #f1f1f1;
    border: none;
    padding: 10px;
    cursor: pointer;
    color:  #83879d;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    width: 610px;
    height: 32px;
    display: flex; 
    align-items: center; 
    gap: 8px; 
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;

    span {
        //내용 한줄로 보이게
        white-space: nowrap; /
    }
`;

const DropdownButton2 = styled.button`
    background-color: #f1f1f1;
    border: none;
    padding: 10px;
    cursor: pointer;
    color:  #83879d;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    width: 545px;
    height: 32px;
    display: flex; 
    align-items: center; 
    gap: 8px; 
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;

    span {
        //내용 한줄로 보이게
        white-space: nowrap; /
    }
`;

const DropdownContent = styled.div<{ isOpen: boolean }>`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: calc(100%); /* DropdownContainer 바로 아래에 위치하도록 설정하고 간격 추가 */
  left: 0;  width: 610px; 

`;


const DropdownItem = styled.div`
  padding: 8px; 
  cursor: pointer;
  width: 595px;
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis; 
  &:hover {
    background-color: #f1f1f1;
  }
    color: #838796; 
  &.selected {
    color: #22232B;
  }
`;


const DropdownContent1 = styled.div<{ isOpen: boolean }>`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: calc(100%); 
  left: 0;
  width: 610px; 
`;

const DropdownItem1 = styled.div`
  padding: 8px; 
  cursor: pointer;
  width: 595px;
  white-space: nowrap;
  overflow: hidden; 
  text-overflow: ellipsis; 
  &:hover {
    background-color: #f1f1f1;
  }
`;

const DropdownContent2 = styled.div<{ isOpen: boolean }>`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: calc(100%); 
  left: 0;
  width: 545px;
`;

const DropdownItem2 = styled.div`
  padding: 8px; 
  cursor: pointer;
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis; 
  &:hover {
    background-color: #f1f1f1;
  }
`;

const DropDownImage = styled.img`
    flex-shrink: 0;
    display: inline-block;
    padding-left: 560px;
    position: absolute;
`

const XBtnImage = styled.img`
    flex-shrink: 0;
    display: flex;
    /* padding-left: 496px; */
    position: absolute;
    margin-top: 24px;
    margin-right: 32px;
    margin-left: 544px;
`

const Image = styled.img`
    flex-shrink: 0;
    display: inline-block;
    padding-left: 500px;
    /* margin-left: 100px; */
    position: absolute;
`

const TitleDetail = styled.div`
    color:  #838796;
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 8px;
`;

const Button = styled.div`
    width: 86px;
    height: 38px;
    border-radius: 3px;
    background: #121317;
    display: inline-flex;
    /* padding: 8px 24px; */
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #FFF;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin-top: 47px;
    margin-left: 525px;
`

const ModalTitle = styled.div`
  color: #22232B;
  font-family: 'Poppins';
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; 
  letter-spacing: -0.192px;
  margin-top: 50px;
  margin-left: 30px;
`;

const TruckNumber = styled.div`
  color: #838796;
  font-family: 'Poppins';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; 
`;

const TruckNumberTextInput = styled.div`
  display: flex;
  /* padding: 6px 12px; */
  align-items: center;
  gap: 10px;
  border-radius: 3px;
  background: #F1F1F4;
  border: none;
  width: 536px;
  height: 32px;
  text-align: center;
  align-self: center;
  position: relative;
  border-radius: 3px;
  margin-top: 8px;
  margin-bottom: 8px;
  //입력하지 못하도록
  pointer-events: none;
  color: #838796;
  font-family: 'Poppins';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; 
  padding-left: 8px;

  &:focus {
        outline: none; 
        border: 2px solid #ED6A2C; 
    }
`;

const QuantityTextInput = styled.input`
  display: flex;
  gap: 10px;
  border-radius: 3px;
  background: #F1F1F4;
  border: none;
  width: 536px;
  height: 32px;
  position: relative;
  border-radius: 3px;
  margin-top: 8px;
  margin-bottom: 8px;
  padding-left: 8px;

  &:focus {
        outline: none; 
        border: 2px solid #ED6A2C; 
    }
`;



const Menu = styled.div`
  color: #838796;
  font-family: 'Poppins';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; 
  margin-top: 24px;
`;

const Quantity = styled.div`
  color: #838796;
  font-family: 'Poppins';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; 
`;

const SaveBtn = styled.button`
  display: inline-flex;
  width: 86px;
  height: 38px;
  /* padding: 8px 24px; */
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 3px;
  background: #121317;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  color: #FFF;
  font-family: 'Poppins';
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; 
  margin-left: 462px;
  margin-top: 21px;
`
const ScheduledOrderContainer = styled.div`
    margin-top: 24px;
`;

const ScheduledOrderTitle = styled.h2`
    font-size: 20px;
    margin-bottom: 16px;
    margin-top: 60px;
`;

const TitleDetailContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    margin-bottom: 8px;
    width: 470px;
`;

const ScheduledOrderItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    width: 600px;
`;

const MenuItem = styled.span`
    flex: 1;
`;

const QuantityInput = styled.input`
    display: flex;
    flex: 0 0 162px; // 고정된 너비를 가지도록 설정
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 3px;
    background: #F1F1F4;
    border: none;
    /* width: 536px;
    height: 32px; */
    /* position: relative; */

    &:focus {
            outline: none; 
            border: 2px solid #ED6A2C; 
    }
`;

const Line = styled.div`
    border-bottom: 1px solid #ddd; // 실선 추가
    width: 600px;
    margin-bottom: 24px;
`

export default InventoryRegistration;
