import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import ClientSidebar from "../../components/ClientSidebar";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import Search from "../../images/Search.png";
import { adminPointEventControllerCreateEarnEvent } from "../../api/service/admin-bbq-coin/admin-bbq-coin";
import { adminPointEventControllerCreateUseEvent } from "../../api/service/admin-bbq-coin/admin-bbq-coin";

interface BBQCoinProps {}

const BBQCoin: React.FC<BBQCoinProps> = () => {
  // 코인 지급 관련 상태
  const [earnClientId, setEarnClientId] = useState("");
  const [earnCoins, setEarnCoins] = useState(0);

  // 코인 회수 관련 상태
  const [useClientId, setUseClientId] = useState("");
  const [useCoins, setUseCoins] = useState(0);

  // 코인 지급 입력 변경 핸들러
  const handleEarnInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "earnClientId") {
      setEarnClientId(value);
      console.log("earnClientId는", earnClientId);
    } else if (name === "earnCoins") {
      setEarnCoins(Number(value));
      console.log("earnCoins는", earnCoins);
    }
  };

  // 코인 회수 입력 변경 핸들러
  const handleUseInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "useClientId") {
      setUseClientId(value);
      console.log("useClientId는", useClientId);
    } else if (name === "useCoins") {
      setUseCoins(Number(value));
      console.log("useCoins는", useCoins);
    }
  };

  // 코인 지급 요청 핸들러
  // const handleEarnSubmit = async () => {
  //   try {
  //     const adminCreateEarnDto = { clientId: earnClientId, point: earnCoins };
  //     await adminPointEventControllerCreateEarnEvent(adminCreateEarnDto);
  //     console.log("adminCreateEarnDto는", adminCreateEarnDto);
  //     alert("BBQCoin 지급 완료");
  //   } catch (error) {
  //     console.error("Error in earning BBQCoin:", error);
  //     alert("BBQCoin 지급 실패");
  //   }
  // };

  // 코인 지급 요청 핸들러
  const handleEarnSubmit = async () => {
    // 입력 유효성 검사
    if (!earnClientId) {
      alert("Client ID를 입력하세요.");
      return;
    }
    if (earnCoins <= 0) {
      alert("지급할 BBQCoin은 0보다 커야 합니다.");
      return;
    }

    try {
      const adminCreateEarnDto = { clientId: earnClientId, point: earnCoins };
      await adminPointEventControllerCreateEarnEvent(adminCreateEarnDto);
      console.log("BBQCoin 지급 요청 데이터:", adminCreateEarnDto);
      alert("BBQCoin 지급 완료");
    } catch (error) {
      console.error("BBQCoin 지급 실패:", error);
      alert("BBQCoin 지급 실패");
    }
  };

  // 코인 회수 요청 핸들러
  const handleUseSubmit = async () => {
    try {
      const adminCreateUseDto = { clientId: useClientId, point: useCoins };
      await adminPointEventControllerCreateUseEvent(adminCreateUseDto);
      console.log("adminCreateUseDto는", adminCreateUseDto);
      alert("BBQCoin 회수 완료");
    } catch (error) {
      console.error("Error in using BBQCoin:", error);
      alert("BBQCoin 회수 실패");
    }
  };

  return (
    <>
      <Header />
      <Sidebar />
      <Content>
        <ClientSidebar />
        <Title>BBQCoin</Title>
        <Area>
          {/* 코인 지급 영역*/}
          <SubTitle>BBQCoin 지급 </SubTitle>
          <TitleDetail>Client ID</TitleDetail>
          <TextInputWrapper>
            <TextInput1
              name="earnClientId"
              placeholder="BBQCoin을 지급할 client의 id를 입력하세요"
              onChange={handleEarnInputChange}
            />
          </TextInputWrapper>
          <TitleDetail>지급할 BBQCoin</TitleDetail>
          <TextInput1
            name="earnCoins"
            placeholder="지급할 BBQCoin을 입력하세요"
            onChange={handleEarnInputChange}
            type="number"
          />
          <Button onClick={handleEarnSubmit}>지급</Button>
          {/* 코인 회수 영역*/}
          <SubTitle>BBQCoin 회수 </SubTitle>
          <TitleDetail>Client ID</TitleDetail>
          <TextInputWrapper>
            <TextInput1
              name="useClientId"
              placeholder="BBQCoin을 회수할 client의 id를 입력하세요"
              onChange={handleUseInputChange}
            />
          </TextInputWrapper>
          <TitleDetail>회수할 BBQCoin</TitleDetail>
          <TextInput1
            name="useCoins"
            placeholder="회수할 BBQCoin을 입력하세요"
            onChange={handleUseInputChange}
            type="number"
          />
          {/* <TitleDetail>Reason</TitleDetail>
                <TextInput2 placeholder='text' /> */}
          {/* 완료 버튼  */}
          <Button onClick={handleUseSubmit}>회수</Button>
        </Area>
      </Content>
    </>
  );
};

const TextInputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  font-family: "Poppins";
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #22232b;
  margin-top: 78px;
  margin-left: 35px;
`;

const SubTitle = styled.h2`
  font-size: 20px;
  margin-bottom: 16px;
  margin-top: 60px;
`;

const Area = styled.div`
  height: 928px;
  width: 995px;
  margin-top: 54px;
  position: absolute;
  margin-top: 108px;
  margin-left: 354px;
`;

const Content = styled.div`
  display: inline-flex;
`;

const TextInput1 = styled.input`
  background-color: #f1f1f4;
  border: none;
  cursor: pointer;
  color: #838796;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  width: 522px;
  height: 32px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 12px;

  &:focus {
    outline: none;
    border: 2px solid #ed6a2c;
  }
`;

const TitleDetail = styled.div`
  color: #838796;
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-top: 24px;
  margin=bottom: 8px;
`;

const Button = styled.div`
  width: 86px;
  height: 38px;
  border-radius: 3px;
  background: #121317;
  display: inline-flex;
  /* padding: 8px 24px; */
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fff;
  font-family: "Poppins";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  margin-top: 47px;
  margin-left: 460px;
`;

export default BBQCoin;
