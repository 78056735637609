import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import ClientSidebar from "../../../components/ClientSidebar";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import DropDown from "../../../images/DropDown.png";
import XBtn from "../../../images/XBtn.png";

interface AddMenuuProps {

};

const AddMenu: React.FC<AddMenuuProps> = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isTruckTypeDropdownOpen, setIsTruckTypeDropdownOpen] = useState(false);
    const [isDrivingAreaDropdownOpen, setIsDrivingAreaDropdownOpen] = useState(false);
    const [isOptionTitleDropdownOpen, setIsOptionTitleDropdownOpen] = useState(false);
    const [selectedTruckType, setSelectedTruckType] = useState<string | null>(null);
    const [selectedOption, setSelectedOption] = useState<string | null>(null);
    const truckTypeDropdownRef = useRef<HTMLDivElement>(null);
    const drivingAreaDropdownRef = useRef<HTMLDivElement>(null);
    const optionTitleDropdownRef = useRef<HTMLDivElement>(null);
    const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
    const [optionWrappers, setOptionWrappers] = useState<number[]>([1]);

      const closeDropdowns = () => {
          setIsOpen(false);
      };
  
      const handleClickOutside = (event: MouseEvent) => {
        const isClickedInsideTruckTypeDropdown = truckTypeDropdownRef.current?.contains(event.target as Node);
        const isClickedInsideOptionTitleDropdown = optionTitleDropdownRef.current?.contains(event.target as Node);
        const isClickedInsideDrivingAreaDropdown = drivingAreaDropdownRef.current?.contains(event.target as Node);
      
        if (!isClickedInsideTruckTypeDropdown && !isClickedInsideDrivingAreaDropdown && !isClickedInsideOptionTitleDropdown) {
          closeDropdowns();
        }
      };
      

      const toggleTruckTypeDropdown = () => {
        setIsTruckTypeDropdownOpen(!isTruckTypeDropdownOpen);
        setIsDrivingAreaDropdownOpen(false);
        setIsOptionTitleDropdownOpen(false);  
      };

      const handleTruckTypeItemClick = (item: string) => {
        setSelectedTruckType(item);
        setIsTruckTypeDropdownOpen(false);
      };

      const toggleOptionTitleDropdown = () => {
        setIsOptionTitleDropdownOpen(!isOptionTitleDropdownOpen);
        setIsTruckTypeDropdownOpen(false);
      };

      const handleOptionTitleItemClick = (item: string) => {
        setSelectedOption(item);
        setIsOptionTitleDropdownOpen(false);
      };

      //add photo 관련
      const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = event.target.files;
        setSelectedFiles(selectedFiles);
      };

      const fileInputRef = useRef<HTMLInputElement | null>(null);

      //option 삭제 관련
      const handleRemoveOptionWrapper = (indexToRemove: number) => {
        setOptionWrappers((prevWrappers) => prevWrappers.filter((_, index) => index !== indexToRemove));
      };
      
      //option 추가 관련
      const handleAddOptionWrapper = () => {
        setOptionWrappers((prevWrappers) => [...prevWrappers, 1]); // 1을 임의로 추가합니다.
      };


      useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
      }, []);
      


      useEffect(() => {
          document.addEventListener('click', closeDropdowns);
          return () => {
              document.removeEventListener('click', closeDropdowns);
          };
        }, []);
  
     
  return (
    <>
      <Header />
      <Sidebar />
      <Content>
        <ClientSidebar />
        <Title>Add Menu</Title>
        <Area>
                <TitleDetail>Menu Name</TitleDetail>
                <TextInput1 placeholder='text' />
                <TitleDetail>Type</TitleDetail>
                {/* 드롭다운 메뉴 */}
                <DropdownContainer 
                placeholder='select date'
                ref={truckTypeDropdownRef}>
                    <DropdownButton>
                        <span>{selectedTruckType || "select"}</span>
                        <Image
                            src={DropDown}
                            alt='dropdown'
                            onClick={toggleTruckTypeDropdown}
                        />
                    </DropdownButton>
                    <DropdownContent isOpen={isTruckTypeDropdownOpen}>
                        <DropdownItem onClick={() => handleTruckTypeItemClick("Main Menu")}>Main Menu</DropdownItem>
                        <DropdownItem onClick={() => handleTruckTypeItemClick("Side Menu")}>Side Menu</DropdownItem>
                        <DropdownItem onClick={() => handleTruckTypeItemClick("etc")}>etc</DropdownItem>
                    </DropdownContent>
                </DropdownContainer>
                <TitleDetail>Price</TitleDetail>
                <TextInput1 placeholder='text' />
                <TitleDetail>Photos</TitleDetail>
                {/* 사진 추가 영역 */}
                <input
                  type="file"
                  accept="image/*" 
                  multiple // 여러 파일 선택 가능하도록 설정 (선택 사항)
                  style={{ display: 'none' }}
                  onChange={handleFileUpload}
                  ref={fileInputRef}
                />
                <PhotoWrapper>
                <AddPhotoArea onClick={() => fileInputRef.current?.click()}>
                  + Add Photos
                </AddPhotoArea>
                {selectedFiles && (
                  <PreviewArea>
                    {Array.from(selectedFiles).map((file, index) => (
                      <ImagePreview key={index}>
                        <img src={URL.createObjectURL(file)} alt={`Preview ${index}`} />
                      </ImagePreview>
                    ))}
                  </PreviewArea>
                )}
                </PhotoWrapper>
                {/* 옵션 관련 항목들 */}
                {optionWrappers.map((_, index) => (
                <OptionWrapper key={index}>
                  <OTWrapper>
                  <TitleDetail>Option Title</TitleDetail>
                    {/* 대분류 선택 드롭다운 */}
                    <DropdownContainer2 
                    placeholder='대분류 선택'
                    ref={optionTitleDropdownRef}>
                    <DropdownButton>
                        <span>{selectedOption || "대분류 선택"}</span>
                        <Image2
                            src={DropDown}
                            alt='dropdown'
                            onClick={toggleOptionTitleDropdown}
                        />
                    </DropdownButton>
                    <DropdownContent2 isOpen={isOptionTitleDropdownOpen}>
                        <DropdownItem2 onClick={() => handleOptionTitleItemClick("굽기")}>굽기</DropdownItem2>
                        <DropdownItem2 onClick={() => handleOptionTitleItemClick("맵기")}>맵기</DropdownItem2>
                        <DropdownItem2 onClick={() => handleOptionTitleItemClick("토핑")}>토핑</DropdownItem2>
                    </DropdownContent2>
                    </DropdownContainer2>
                  </OTWrapper>
                  <OWrapper>
                    <TitleDetail>Options</TitleDetail>
                    <TextInput3 placeholder='소분류 내용' />
                  </OWrapper>
                    <Xbtn 
                      src={XBtn} 
                      alt='xbtn'
                      onClick={() => handleRemoveOptionWrapper(index)} 
                    />
                </OptionWrapper>
                ))}
                <AddOption onClick={handleAddOptionWrapper}>+ Add Option</AddOption>
                <TitleDetail>Description</TitleDetail>
                <TextInput2 placeholder='text' />
                {/* 완료 버튼 */}
                <Button>
                  Save
                </Button>
        </Area>
      </Content>
    </>
  );
};

const Content = styled.div`
  display: inline-flex;
`;

const Title = styled.div`
  font-family: 'Poppins';
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #22232b;
  margin-top: 78px;
  margin-left: 35px;
`;

const Area = styled.div`
    height: 928px;
    width: 995px;
    margin-top: 54px;
    position: absolute;
    margin-top: 168px;
    margin-left: 354px;
`

const PhotoWrapper = styled.div`
  display: inline-flex;
  /* flex-wrap: nowrap; */
`;

const OptionWrapper = styled.div`
  display: flex;
`;

const OTWrapper = styled.div`
`;

const OWrapper = styled.div`
`;

const AddPhotoArea = styled.div`
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 3px;
  background: #F1F1F4;
  width: 110px;
  height: 110px;
  color: #838796;
  margin-top: 8px;
`

const PreviewArea = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
  margin-top: 8px;
  padding: 6px 12px;
  border-radius: 3px;
  align-items: center;
  white-space: nowrap;
`;

const ImagePreview = styled.div`
  width: 113px;
  height: 113px;
  gap: 10px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const AddOption = styled.div`
  color: #22232B;
  font-family: 'Poppins';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; 
  margin-left: 452px;
  margin-top: 12px;
`;

const DropdownContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    margin-top: 8px;
    margin-bottom: 24px;
`;

const DropdownContainer2 = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    margin-top: 8px;
    /* margin-bottom: 24px; */
    width: 152px;
    margin-right: 18px;
`;

const DropdownButton = styled.button`
    background-color: #f1f1f1;
    border: none;
    padding: 10px;
    cursor: pointer;
    color:  #83879d;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    width: 535px;
    height: 32px;
    display: flex; 
    align-items: center; 
    gap: 8px; 
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;

    span {
        //내용 한줄로 보이게
        white-space: nowrap; /
    }
`;

const DropdownContent = styled.div<{isOpen: boolean}>` 
    display: ${(props) => (props.isOpen ? 'block' : 'none')};
    position: absolute;
    background-color: #fff;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
`;

const DropdownContent2 = styled.div<{isOpen: boolean}>` 
    display: ${(props) => (props.isOpen ? 'block' : 'none')};
    position: absolute;
    background-color: #fff;
    min-width: 152px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
`;

const DropdownItem = styled.div`
    padding: 3px;
    width: 522px;
    cursor: pointer;
    &:hover {
        background-color: #f1f1f1;
    }
`;

const DropdownItem2 = styled.div`
    padding: 3px;
    width: 152px;
    cursor: pointer;
    &:hover {
        background-color: #f1f1f1;
    }
`;

const TitleDetail = styled.div`
    color:  #838796;
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-top: 24px;
    margin=bottom: 8px;
`;

const TextInput1 = styled.input`
    background-color: #F1F1F4;
    border: none;
    cursor: pointer;
    /* color:  #838796; */
    color:  #22232B;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    width: 522px;
    height: 32px;
    display: flex; 
    align-items: center; 
    gap: 8px;
    padding-left: 12px;
    margin-top: 8px;

    &:focus {
        outline: none; 
        border: 2px solid #ED6A2C; 
    }

    &::placeholder {
        color: #838796; 
    }

`;

const TextInput2 = styled.textarea`
    background-color: #F1F1F4;
    border: none;
    cursor: pointer;
    /* color:  #838796; */
    color:  #22232B;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    width: 522px;
    height: 141px;
    display: flex; 
    gap: 8px;
    padding-left: 12px;
    margin-top: 8px;
    padding-top: 12px;
    align-items: flex-start;
    resize: vertical;

    &:focus {
        outline: none; 
        border: 2px solid #ED6A2C; 
    }

    &::placeholder {
        color: #838796; 
    }
`;

const TextInput3 = styled.input`
    background-color: #F1F1F4;
    border: none;
    cursor: pointer;
    color:  #22232B;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    width: 342px;
    height: 32px;
    display: flex; 
    align-items: center; 
    gap: 8px;
    padding-left: 12px;
    margin-top: 8px;

    &:focus {
        outline: none; 
        border: 2px solid #ED6A2C; 
    }

    &::placeholder {
        color: #838796; 
    }
`;

const Image = styled.img`
    flex-shrink: 0;
    display: inline-block;
    padding-left: 490px;
    /* margin-left: 306px; */
    position: absolute;
`

const Image2 = styled.img`
    flex-shrink: 0;
    display: inline-block;
    padding-left: 100px;
    /* margin-left: 306px; */
    position: absolute;
    margin-left: 12px;
`

const Xbtn = styled.img`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  margin-top: 50px;
  margin-left: 12px;
`

const Button = styled.div`
    width: 86px;
    height: 38px;
    border-radius: 3px;
    background: #121317;
    display: inline-flex;
    /* padding: 8px 24px; */
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #FFF;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin-top: 47px;
    margin-left: 460px;
`

export default AddMenu;

