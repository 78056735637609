import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";

const Driver: React.FC = () => {
  const driverMenuList = ["Truck Info", "Driver Info", "Inventory"];
  const navigate = useNavigate();
  const location = useLocation();

  const [driverMenu, setDriverMenu] = useState<string>("");

  useEffect(() => {
    setDriverMenu(location.pathname);
  }, [location]);

  return (
    <>
      <Header />
      <Sidebar />
      <DriverSideMenuWrapper>
        {driverMenuList.map((menu) => {
          const diffMenu = menu.toLocaleLowerCase().replace(/\s+/g, "");
          return (
            <DriverSideMenuContent
              key={menu}
              clicked={driverMenu === diffMenu}
              onClick={() => {
                setDriverMenu(diffMenu);
                navigate(
                  "/driver/" + menu.toLocaleLowerCase().replace(/\s+/g, "")
                );
              }}
            >
              {menu}
            </DriverSideMenuContent>
          );
        })}
      </DriverSideMenuWrapper>
    </>
  );
};

interface StyledButtonProps {
  clicked: boolean;
}

const DriverSideMenuContent = styled.div<StyledButtonProps>`
  color: ${({ clicked }) => (clicked ? "#22232B" : "#838796")};
  font-weight: ${({ clicked }) => (clicked ? 700 : 400)};

  margin: 24px 0px 24px 20px;
`;

const DriverSideMenuWrapper = styled.div`
  padding-top: 48px;
  padding-left: 68px;
  width: 250px;
  height: 100vh;
  background-color: #eeeeee;
`;

export default Driver;
