import React from 'react';
import { BeatLoader } from 'react-spinners';

const LoadingComponent: React.FC = () => {
  return (
    <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
      <BeatLoader loading size={15} color="#ED6A2C" />
    </div>
  );
};

export default LoadingComponent;
