import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Switch from "react-switch";
import styled from "styled-components";
import ClientSidebar from "../../../components/ClientSidebar";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import {
  adminNotiControllerFindMany,
  adminNotiControllerUpdate,
  adminNotiControllerDelete,
} from "../../../api/service/admin-noti/admin-noti";
import { AdminNotiControllerFindManyParams } from "../../../api/model/adminNotiControllerFindManyParams";
import LoadingComponent from "../../../components/Loading";

interface PushNotificationProps {}

const PushNotification: React.FC<PushNotificationProps> = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isTruckTypeDropdownOpen, setIsTruckTypeDropdownOpen] = useState(false);
  const [isDrivingAreaDropdownOpen, setIsDrivingAreaDropdownOpen] =
    useState(false);
  const [isToggleOn, setIsToggleOn] = useState(false); // 초기 상태는 false (비활성화)로 설정
  const truckTypeDropdownRef = useRef<HTMLDivElement>(null);
  const drivingAreaDropdownRef = useRef<HTMLDivElement>(null);
  const [data, setData] = useState<AdminNotiControllerFindManyParams[]>([]);
  // const [data, setData] = useState([]);
  const [deletedRows, setDeletedRows] = useState<number[]>([]);
  const [isActive, setIsActive] = useState(false); //토글 버튼
  const [loading, setLoading] = useState(false);

  const closeDropdowns = () => {
    setIsOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    const isClickedInsideTruckTypeDropdown =
      truckTypeDropdownRef.current?.contains(event.target as Node);
    const isClickedInsideDrivingAreaDropdown =
      drivingAreaDropdownRef.current?.contains(event.target as Node);

    if (
      !isClickedInsideTruckTypeDropdown &&
      !isClickedInsideDrivingAreaDropdown
    ) {
      setIsTruckTypeDropdownOpen(false);
      setIsDrivingAreaDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("click", closeDropdowns);
    return () => {
      document.removeEventListener("click", closeDropdowns);
    };
  }, []);

  // 노티 목록 조회하기 함수
  const fetchNotificationData = async () => {
    setLoading(true);
    try {
      const response = await adminNotiControllerFindMany();
      console.log("노티 목록 조회:", response.data);
      setData(response.data.items);
    } catch (error) {
      console.error("Failed to fetch notification data:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchNotificationData(); // Fetch data when the component mounts
  }, []);

  const handleDeleteRow = (index: number) => {
    setDeletedRows([...deletedRows, index]);
  };

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <>
      <Header />
      <Sidebar />
      <Content>
        <ClientSidebar />
        <Title>Push Notification</Title>
        <Area>
          <Table>
            <TableHeader>
              <TableCellTitle>Date</TableCellTitle>
              <TableCellTitle>Time</TableCellTitle>
              <TableCellTitle>Title</TableCellTitle>
              <TableCellTitle>state</TableCellTitle>
              <TableCellTitle></TableCellTitle>
              <TableCellTitle></TableCellTitle>
            </TableHeader>
            <TableBody>
              {data.map((notification, index) => (
                <TableRow>
                  <TableCell>
                    {notification.startDate && notification.endDate
                      ? `${notification.startDate} ~ ${notification.endDate}`
                      : "No data"}
                  </TableCell>
                  <TableCell>
                    {notification.time ? notification.time : "No data"}
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    {notification.isActive}
                    {/* <ToggleSwitch>
                              <CheckBox
                                type="checkbox"
                                checked={isActive}
                                onChange={() => setIsActive(!isActive)}
                              />
                              <ToggleSlider />
                            </ToggleSwitch> */}
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    <EditBtn
                      onClick={() => {
                        navigate("/client/coupon/editcoupon");
                      }}
                    >
                      Edit
                    </EditBtn>
                  </TableCell>
                  <TableCell>
                    <DeleteBtn
                    // onClick={() => handleDeleteRow()}
                    >
                      Delete
                    </DeleteBtn>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Button
            onClick={() => {
              navigate("/client/pushnotifi/addnotifi");
            }}
          >
            +Add Push Notification
          </Button>
        </Area>
      </Content>
    </>
  );
};

const Content = styled.div`
  display: inline-flex;
`;

const Title = styled.div`
  font-family: "Poppins";
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #22232b;
  margin-top: 78px;
  margin-left: 35px;
`;

const Area = styled.div`
  height: 928px;
  width: 995px;
  margin-top: 54px;
  position: absolute;
  margin-top: 168px;
  margin-left: 354px;
`;

const Button = styled.div`
  width: 154px;
  height: 38px;
  display: inline-flex;
  /* padding: 8px 24px; */
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 3px;
  background-color: #121317;
  color: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  font-family: "Poppins";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  margin-left: 750px;
  margin-right: 114px;
  margin-top: 411px;
  padding: 4px 8px;
`;
const DeleteBtn = styled.div`
  width: 50px;
  height: 25px;
  display: inline-flex;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 3px;
  background: #121317;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  color: #ffffff;
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  cursor: pointer;
`;

const EditBtn = styled.div`
  width: 43px;
  height: 25px;
  display: inline-flex;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 3px;
  border: 1px solid #9ea1ae;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  color: var(--content-gray-900, #22232b);
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  cursor: pointer;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

const TableHeader = styled.thead`
  /* ... 테이블 헤더 관련 스타일링 */
  border-top: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 995px;
  color: #838796;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

const TableBody = styled.tbody``;

const TableRow = styled.tr``;

const TableCell = styled.td`
  padding-top: 24px;
  color: #000;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const TableCellTitle = styled.td`
  color: #838796;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
`;
const ToggleSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 47.7px;
  height: 23.33px;
`;

const ToggleSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

const CheckBox = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${ToggleSlider} {
    background-color: #ed6a2c;
  }

  &:focus + ${ToggleSlider} {
    box-shadow: 0 0 1px #2196f3;
  }

  &:checked + ${ToggleSlider}:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
`;

export default PushNotification;
