import axios, { AxiosRequestConfig } from "axios";
import { getNewRefreshTokenData } from "../utils/cognito";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { BASE_URL } from "../constants/axios";
import { useNavigate } from "react-router-dom";

axios.defaults.baseURL = BASE_URL;

// 초기 토큰 설정
export const initToken = () => {
  const accessToken = localStorage.getItem("accessToken");

  // console.log("initToken called:", {
  //   accessToken: accessToken || "No accessToken found", // null이면 'No accessToken found'를 보여줌
  // });
  if (accessToken) {
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    return accessToken;
  } else {
    return "";
  }
};

// 토큰 저장
export const setAuthHeader = async ({
  accessToken,
  refreshToken,
  idToken,
}: {
  accessToken: string;
  refreshToken: string;
  idToken: string | null;
}) => {
  if (accessToken !== null && accessToken !== "") {
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  }

  localStorage.setItem("@id_token", idToken || "");
  localStorage.setItem("@access_token", accessToken);
  localStorage.setItem("@refresh_token", refreshToken);

  // console.log("setAuthHeader called:", { accessToken, refreshToken, idToken });
};

// 토큰 가져오기
export const getAuthHeader = () => {
  const it = localStorage.getItem("@id_token");
  const at = localStorage.getItem("@access_token");
  const rt = localStorage.getItem("@refresh_token");

  // console.log("getAuthHeader called:", {
  //   accessToken: at,
  //   refreshToken: rt,
  //   idToken: it,
  // });

  return { accessToken: at, refreshToken: rt, idToken: it };
};

// 토큰 삭제
export const clearAuthHeader = () => {
  localStorage.clear();
  delete axios.defaults.headers.common.Authorization;

  console.log("clearAuthHeader called");

  return;
};

// 초기 토큰 설정
// export const initToken = () => {
//     const accessToken = localStorage.getItem('@access_token');
//     console.log('initToken called:', { accessToken })
//     if (accessToken) {
//       axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
//       return accessToken;
//     } else {
//       clearAuthHeader();
//       return '';
//     }
// };

// refresh token이 재기능을 하는지 체크
const checkToken = async (session: CognitoUserSession, navigate: any) => {
  const { accessToken, refreshToken, idToken } = getAuthHeader(); //현재 저장된 토큰 가져오고
  const isEqualRefreshToken =
    session.getRefreshToken().getToken() === refreshToken; //세션과 로컬스토리지의 리프레시 토큰이 같은지 확인
  const isEqualIdToken = session.getIdToken().getJwtToken() === idToken; //세션과 로컬스토리지의 아이디 토큰이 같은지 확인
  const isEqualAccessToken =
    session.getAccessToken().getJwtToken() === accessToken; //세션과 로컬스토리지의 액세스 토큰이 같은지 확인

  // console.log("checkToken called:", {
  //   isEqualRefreshToken,
  //   isEqualIdToken,
  //   isEqualAccessToken,
  // });

  navigate("/your-target-route");
};

// 토큰 만료시 처리
axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config as AxiosRequestConfig & {
      _retry?: boolean;
    };
    const navigate = useNavigate();
    // axios error가 아닌 경우
    if (!axios.isAxiosError(error)) return Promise.reject(error);

    // 토큰이 만료되었는지 확인
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      console.log(
        "axios.interceptors.response.use: Token expired, refreshing..."
      );

      // refreshToken을 사용하여 accessToken 갱신
      const token = localStorage.getItem("@refresh_token");
      // console.log("refreshToken을 사용하여 accessToken 갱신", token);
      // refreshToken이 없는 경우 로그아웃 처리
      if (!token) {
        alert("Your login has expired. Please log in again.");
        // return clearAuthHeader();
        clearAuthHeader();
        navigate("/login");
        return;
      }
      const session: CognitoUserSession | null = await getNewRefreshTokenData(
        token
      ).catch((e) => {
        console.error(e);
        return null;
      });

      // 회원이 존재하지 않는 경우 로그아웃 처리
      if (session === null) {
        alert("Your login has expired. Please log in again.");
        // return clearAuthHeader();
        clearAuthHeader();
        navigate("/login");
        return;
      }

      //  await checkToken(session);
      await checkToken(session, navigate);

      //initToken 함수의 반환값을 사용하여 토큰 설정
      const refreshedIdToken = await initToken();

      // 토큰 저장 및 헤더 업데이트
      setAuthHeader({
        accessToken: session.getAccessToken().getJwtToken(),
        refreshToken: session.getRefreshToken().getToken(),
        // idToken: session.getIdToken().getJwtToken(),
        // 변경: initToken 함수에서 반환한 토큰 사용
        idToken: refreshedIdToken,
      });

      console.log(
        "axios.interceptors.response.use: Token refreshed and request retried"
      );

      // 원래의 요청 재시도
      return axios(originalRequest);
    }
    // 기타 axios 오류 처리
    return Promise.reject(error);
  }
);
