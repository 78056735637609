import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserSession,
  CognitoRefreshToken,
} from "amazon-cognito-identity-js";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";

interface PoolData {
  UserPoolId: string;
  ClientId: string;
}

// dev 개발 환경
// const poolData: PoolData = {
//   UserPoolId: "us-east-1_2z7n1Bpsj",
//   ClientId: "29u24vpghb2a44qqv29p5b302k",
// };

// // staging 개발 환경
// const poolData: PoolData = {
//   UserPoolId: "us-east-1_sYVY61HB9",
//   ClientId: "607l5ra4kqf65ekruj94c91flh",
// };

// prod 개발 환경
// const poolData: PoolData = {
//   UserPoolId: "us-east-1_L8o3Y2t92",
//   ClientId: "20lij1bhqpj1cktqqm4lhokho6",
// };

const poolData: PoolData = {
  UserPoolId: process.env.REACT_APP_USER_POOL_ID as string, // 'as string'을 추가하여 TypeScript에게 이 변수가 반드시 존재한다는 것을 알림
  ClientId: process.env.REACT_APP_CLIENT_ID as string, // 환경변수가 제대로 설정되지 않았을 경우 에러가 발생할 수 있으므로, 실제 배포 전에 이를 확인하는 것이 중요합니다.
};

const userPool = new CognitoUserPool(poolData);

//로그인 요청 함수
function logIn(
  username: string,
  password: string
): Promise<CognitoUserSession> {
  const authenticationData = {
    Username: username,
    Password: password,
  };

  const authenticationDetails = new AuthenticationDetails(authenticationData);
  const userData = {
    Username: username,
    Pool: userPool,
  };

  const cognitoUser = new CognitoUser(userData);

  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (session) => resolve(session),
      onFailure: (err) => reject(err),
    });
  });
}

// refreshToken으로 새로운 토큰 요청 함수
export function getNewRefreshTokenData(
  refreshTokenString: string
): Promise<AmazonCognitoIdentity.CognitoUserSession> {
  const cognitoUser = userPool.getCurrentUser();
  if (cognitoUser == null)
    return Promise.reject("No user is currently authenticated.");
  const refreshToken = new CognitoRefreshToken({
    RefreshToken: refreshTokenString,
  });
  return new Promise((resolve, reject) => {
    cognitoUser.refreshSession(refreshToken, (err, session) =>
      err ? reject(err) : resolve(session)
    );
  });
}

export default logIn;
