import React, { useState, useRef } from 'react';
import styled from "styled-components";
import DriverSidebar from "../../../components/DriverSidebar";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import { adminTruckDriverControllerCreate } from "../../../api/service/admin-truck-drivers/admin-truck-drivers";
import { adminFileControllerCreatePreSignedUrl } from "../../../api/service/admin-files/admin-files";
import { initToken } from "../../../utils/setAuthToken";
import axios from 'axios';
import { PreSignedUrl } from '../../../api/model';


interface AddDriversProps {

};

const AddDrivers: React.FC<AddDriversProps> = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [imageUrls, setImageUrls] = useState<string[]>([]);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [presignedData, setPresignedData] = useState<any>(null);
    const [profileImageId, setProfileImageId] = useState<string | null>(null);
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    // 토큰을 초기화하고, 초기화된 토큰 값을 반환하는 함수
    // const initTokenAndGetToken = async () => {
    //   await initToken();
    //   console.log("initToken:", initToken);
    // };


    // 드라이버 이미지 등록 함수(수정 후)
    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
      const selectedFiles = event.target.files;
      if (imageUrls.length >= 1) {
        alert("Sorry, You can only upload one image.");
        return;
      }

      //1. 먼저 add photo 클릭시, 별도의 API 요청 없이 이미지를 선택 후 미리보기 및 상태 저장
      if (selectedFiles && selectedFiles.length > 0) {
        const image = selectedFiles[0];
        const previewUrl = URL.createObjectURL(image);
        console.log("previewUrl:", previewUrl);
        setImageUrls([previewUrl]); // 미리보기 URL 저장
        setUploadSuccess(true); // 업로드 성공 상태 설정
        console.log("File uploaded:", image);

        // 2. 성공시, 파일 업로드를 위한 AWS S3 pre-signed url 요청
        try {
          const fileUrlReq = { count: 1, directory: "drivers" };
          const presignedResponse = await adminFileControllerCreatePreSignedUrl(fileUrlReq);
          const presignedData = presignedResponse.data[0];
          
          console.log("Presigned URL response:", presignedData);
          setPresignedData(presignedData);
          setProfileImageId(presignedData.id); // profileImageId 상태 업데이트

          // 3. 성공시, 생성된 AWS S3 pre-signed url에 이미지 파일을 업로드
          // FormData 객체를 생성하고 파일을 추가합니다.
          const formData = new FormData();
          formData.append("file", image); // 'file'은 S3 presigned URL이 예상하는 필드 이름입니다.

          // Fetch를 사용하여 파일을 업로드합니다.
          const response = await fetch(presignedData.url, {
            method: "PUT",
            body: formData,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          if (response.ok) {
            console.log("File successfully uploaded to S3", presignedData.url);
          } else {
            throw new Error("File upload failed");
          }

          console.log("File successfully uploaded to S3", presignedData.url);
        } catch (presignedError) {
          console.error("Error generating pre-signed URL or uploading to S3:", presignedError);
        }
      }
    };

  // 드라이버 생성 함수
  const handleCreateDriver = async () => {
    console.log("Save button clicked"); // 확인용 

    if (!firstName || !lastName || !phoneNumber || !email || !password || !profileImageId) {
      alert("Sorry, Please fill in all fields.");
      return;
    }

    try {
      const createTruckDriverDto = {
        firstName,
        lastName,
        phoneNum: phoneNumber,
        email,
        password,
        profileImageId, 
      };

      console.log("Sending request with data:", createTruckDriverDto); 

      const response = await adminTruckDriverControllerCreate(createTruckDriverDto).catch //이렇게
      console.log("드라이버 생성 응답", response); 
      alert("Truck driver created successfully!");
      // if (typeof window != "undefined") window.location.href = "/driver/driverinfo/drivers";
    } catch (error) {
      console.error("Error creating truck driver:", error); 
      alert("Failed to create truck driver.");
    }
  };



      

  return (
    <>
      <Header />
      <Sidebar />
      <Content>
        <DriverSidebar />
        <Title>Add Driver</Title>
        <Area>
                {/* <TitleDetail>Driver Number</TitleDetail>
                <TextInput placeholder="text"/> */}
                <NameWrapper>
                <NameWrapper1>
                <TitleDetailName>First Name</TitleDetailName>
                  <TextInputName
                    placeholder="Jay"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </NameWrapper1>
                <NameWrapper2>
                <TitleDetailName>Last Name</TitleDetailName>
                  <TextInputName
                    placeholder="Shin"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </NameWrapper2>
                </NameWrapper>
                <TitleDetail>Phone Number</TitleDetail>
                <TextInput
                  placeholder="+12324561890"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
                <TitleDetail>Email</TitleDetail>
                <TextInput
                  placeholder="j@shinstarr.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                 <TitleDetail>Password</TitleDetail>
                 <TextInput
                  placeholder="at least 8 characters, 1 lowercase letter, 1 uppercase letter, 1 number, 1 special character"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {/* 사진 영역 */}
                <TitleDetail>Photos(Only 1)</TitleDetail>
                <input
                  type="file"
                  accept="image/*" 
                  multiple={false}
                  style={{ display: 'none' }}
                  onChange={handleFileUpload}
                  ref={fileInputRef}
                />
                <PhotoWrapper>
                  <AddPhotoArea onClick={() => fileInputRef.current?.click()}> 
                    + Add Photos
                  </AddPhotoArea>
                  {imageUrls.map((url, index) => (
                    <PreviewArea key={index}>
                      <ImagePreview>
                        <img src={url} alt={`Preview ${index}`} />
                      </ImagePreview>
                    </PreviewArea>
                  ))}
                </PhotoWrapper>
        </Area>
        <Button onClick={handleCreateDriver}>
                Save
              </Button>
      </Content>
    </>
  );
};

const Content = styled.div`
  display: inline-flex;
`;

const Title = styled.div`
  font-family: 'Poppins';
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #22232b;
  margin-top: 78px;
  margin-left: 35px;
`;

const NameWrapper = styled.div`
    display: flex;
    margin-bottom: 12px;
`;

const NameWrapper1 = styled.div`
    width: 252px;
`;

const NameWrapper2 = styled.div`
    width: 252px;
    margin-left: 18px;
`;

const TextInput = styled.input`
    background-color: #f1f1f1;
    border: none;
    color: #22232B; /* 입력된 글씨 색깔 변경 */
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    width: 522px;
    height: 32px;
    display: flex; 
    align-items: center; 
    padding-left: 12px;
    margin-bottom: 24px;
    border-radius: 3px;

    &:focus {
        outline: none; 
        border: 2px solid #ED6A2C; 
    }
`

const TextInputName = styled.input`
    background-color: #f1f1f1;
    border: none;
    color: #22232B; 
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    width: 252px;
    height: 32px;
    display: flex; 
    align-items: center; 
    padding-left: 12px;
    margin-top: 8px;
    margin-bottom: 12px;
    border-radius: 3px;

    &:focus {
        outline: none; 
        border: 2px solid #ED6A2C; 
    }
`;

const TitleDetail = styled.div`
    color:  #838796;
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 8px;
`;

const TitleDetailName = styled.div`
    color:  #838796;
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 8px;
    width: 100%;
`;

const Area = styled.div`
    height: 928px;
    width: 995px;
    margin-top: 54px;
    position: absolute;
    margin-top: 168px;
    margin-left: 354px;
`

const Button = styled.div`
    z-index: 100; 
    width: 86px;
    height: 38px;
    border-radius: 3px;
    background: #121317;
    display: inline-flex;
    /* padding: 8px 24px; */
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #FFF;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin-top: 678px;
    margin-left: 320px;
`

const PhotoWrapper = styled.div`
  display: inline-flex;
  /* flex-wrap: nowrap; */
`;

const AddPhotoArea = styled.div`
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 3px;
  background: #F1F1F4;
  width: 110px;
  height: 110px;
  color: #838796;
  margin-top: 8px;
`

const PreviewArea = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
  margin-top: 8px;
  padding: 6px 12px;
  border-radius: 3px;
  align-items: center;
  white-space: nowrap;
`;

const ImagePreview = styled.div`
  width: 113px;
  height: 113px;
  gap: 10px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;



export default AddDrivers;