import React, { useState, useRef, useEffect } from 'react';
import styled from "styled-components";
import DriverSidebar from "../../../components/DriverSidebar";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import DropDown from "../../../images/DropDown.png";

interface EditDriversProps {

};

const EditDrivers: React.FC<EditDriversProps> = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isTruckTypeDropdownOpen, setIsTruckTypeDropdownOpen] = useState(false);
    const [isDrivingAreaDropdownOpen, setIsDrivingAreaDropdownOpen] = useState(false);
    const [selectedDrivingArea, setSelectedDrivingArea] = useState<string | null>(null);
    const truckTypeDropdownRef = useRef<HTMLDivElement>(null);
    const drivingAreaDropdownRef = useRef<HTMLDivElement>(null);
    const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
    //add photo 관련
    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = event.target.files;
        setSelectedFiles(selectedFiles);
    };

    const fileInputRef = useRef<HTMLInputElement | null>(null);
        const closeDropdowns = () => {
            setIsOpen(false);
        };
  
    const handleClickOutside = (event: MouseEvent) => {
        const isClickedInsideTruckTypeDropdown = truckTypeDropdownRef.current?.contains(event.target as Node);
        const isClickedInsideDrivingAreaDropdown = drivingAreaDropdownRef.current?.contains(event.target as Node);
        
        if (!isClickedInsideTruckTypeDropdown && !isClickedInsideDrivingAreaDropdown) {
            setIsTruckTypeDropdownOpen(false);
            setIsDrivingAreaDropdownOpen(false);
        }
    };

      useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);


      useEffect(() => {
          document.addEventListener('click', closeDropdowns);
          return () => {
              document.removeEventListener('click', closeDropdowns);
          };
      }, []);

  

  return (
    <>
      <Header />
      <Sidebar />
      <Content>
        <DriverSidebar />
        <Title>Edit Driver</Title>
        <Area>
                <TitleDetail>Driver Number</TitleDetail>
                <TextInput placeholder="text"/>
                <NameWrapper>
                <NameWrapper1>
                <TitleDetailName>First Name</TitleDetailName>
                <TextInputName placeholder="text"/>
                </NameWrapper1>
                <NameWrapper2>
                <TitleDetailName>Last Name</TitleDetailName>
                <TextInputName placeholder="text"/>
                </NameWrapper2>
                </NameWrapper>
                <TitleDetail>Phone Number</TitleDetail>
                <TextInput placeholder="text"/>
                <TitleDetail>Email</TitleDetail>
                <TextInput placeholder="text"/>
                {/* 사진 영역 */}
                <TitleDetail>Photos</TitleDetail>
                <input
                  type="file"
                  accept="image/*" 
                  multiple // 여러 파일 선택 가능하도록 설정 (선택 사항)
                  style={{ display: 'none' }}
                  onChange={handleFileUpload}
                  ref={fileInputRef}
                />
                <PhotoWrapper>
                <AddPhotoArea onClick={() => fileInputRef.current?.click()}>
                  + Add Photos
                </AddPhotoArea>
                {selectedFiles && (
                  <PreviewArea>
                    {Array.from(selectedFiles).map((file, index) => (
                      <ImagePreview key={index}>
                        <img src={URL.createObjectURL(file)} alt={`Preview ${index}`} />
                      </ImagePreview>
                    ))}
                  </PreviewArea>
                )}
                </PhotoWrapper>
        </Area>
            <Button
            >Save
            </Button>
      </Content>
    </>
  );
};

const Content = styled.div`
  display: inline-flex;
`;

const Title = styled.div`
  font-family: 'Poppins';
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #22232b;
  margin-top: 78px;
  margin-left: 35px;
`;

const NameWrapper = styled.div`
    display: flex;
    margin-bottom: 12px;
`;

const NameWrapper1 = styled.div`
    width: 252px;
`;

const NameWrapper2 = styled.div`
    width: 252px;
    margin-left: 18px;
`;

const TextInput = styled.input`
    background-color: #f1f1f1;
    border: none;
    color:  #83879d;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    width: 522px;
    height: 32px;
    display: flex; 
    align-items: center; 
    padding-left: 12px;
    margin-bottom: 24px;
    border-radius: 3px;

    &:focus {
        outline: none; 
        border: 2px solid #ED6A2C; 
    }
`

const TextInputName = styled.input`
    background-color: #f1f1f1;
    border: none;
    color:  #83879d;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    width: 252px;
    height: 32px;
    display: flex; 
    align-items: center; 
    padding-left: 12px;
    margin-top: 8px;
    margin-bottom: 12px;
    border-radius: 3px;

    &:focus {
        outline: none; 
        border: 2px solid #ED6A2C; 
    }
`;

const TitleDetail = styled.div`
    color:  #838796;
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 8px;
`;

const TitleDetailName = styled.div`
    color:  #838796;
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 8px;
    width: 100%;
`;

const Area = styled.div`
    height: 928px;
    width: 995px;
    margin-top: 54px;
    position: absolute;
    margin-top: 168px;
    margin-left: 354px;
`

const Button = styled.div`
    width: 86px;
    height: 38px;
    border-radius: 3px;
    background: #121317;
    display: inline-flex;
    /* padding: 8px 24px; */
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #FFF;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin-top: 678px;
    margin-left: 320px;
`

const PhotoWrapper = styled.div`
  display: inline-flex;
  /* flex-wrap: nowrap; */
`;

const AddPhotoArea = styled.div`
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 3px;
  background: #F1F1F4;
  width: 110px;
  height: 110px;
  color: #838796;
  margin-top: 8px;
`

const PreviewArea = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
  margin-top: 8px;
  padding: 6px 12px;
  border-radius: 3px;
  align-items: center;
  white-space: nowrap;
`;

const ImagePreview = styled.div`
  width: 113px;
  height: 113px;
  gap: 10px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;



export default EditDrivers;