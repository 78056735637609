import React from 'react';
import styled from 'styled-components';
import XBtn from '../images/XBtn.png';


interface InventoryModalProps {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode; 
  }
  

const InventoryModal: React.FC<InventoryModalProps> = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

// 모달 외부를 클릭한 경우 또는 X 버튼을 클릭한 경우에 모달을 닫습니다.
const handleModalOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
  if (event.target === event.currentTarget) {
    onClose(); // 모달 닫는 함수 호출
  }
};
    

    
    return (
        <ModalOverlay onClick={handleModalOverlayClick}>
          <ModalContent>
            {children}
            {/* <SaveBtn onClick={onClose}>Save</SaveBtn> */}
          </ModalContent>
        </ModalOverlay>
      );
    };
    
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 600px;
  height: 470px;
`;

export default InventoryModal;