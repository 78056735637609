import React, { useState, useRef, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import DriverSidebar from "../../../components/DriverSidebar";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import { adminTruckDriverControllerFindMany } from "../../../api/service/admin-truck-drivers/admin-truck-drivers";
import { TruckDriver } from "../../../api/model/truckDriver";
import { adminTruckDriverControllerUnAssignTruck } from "../../../api/service/admin-truck-drivers/admin-truck-drivers";
import { TruckUnAssignmentDto } from "../../../api/model/truckUnAssignmentDto";
import { DrivingStatus } from "../../../api/model";
import { adminTruckDriverDrivingControllerFindOne } from "../../../api/service/admin-truck-drivers-driving/admin-truck-drivers-driving";

interface StartProps {}

const Start: React.FC<StartProps> = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [drivers, setDrivers] = useState<TruckDriver[]>([]);
  const [finishedDistances, setFinishedDistances] = useState<{
    [key: string]: number;
  }>({});

  // 트럭 운전자 목록 조회(트럭 운전자에게 배정된 트럭 해제 시에는 assginment 값이 존재하는 데이터만 보여주기)
  const fetchTruckDrivers = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await adminTruckDriverControllerFindMany();
      // driver.assignment 값이 null이 아닌 운전자만 필터링
      const assignedDrivers = data.items.filter(
        (driver) => driver.assignment !== null
      );
      setDrivers(assignedDrivers);
      console.log("트럭 운전자 목록 조회 (할당된 드라이버):", assignedDrivers);
    } catch (error) {
      console.error("트럭 운전자 목록 조회 에러:", error);
    }
    setLoading(false);
  }, []);

  // 주행 거리 입력값 업데이트
  const handleDistanceChange = (driverId: string, value: number) => {
    setFinishedDistances((prev) => ({
      ...prev,
      [driverId]: value,
    }));
  };

  // 트럭 운전자에게 배정된 트럭 해제
  // const unassignTruck = async (driverId: string) => {
  //     const finishedDistance = finishedDistances[driverId];

  //     if (finishedDistance === undefined || finishedDistance === null) {
  //       alert("Please enter the finished distance");
  //       return;
  //     }
  //     try {
  //       const truckUnAssignmentDto: TruckUnAssignmentDto = {
  //         finishedDistance
  //       };
  //       await adminTruckDriverControllerUnAssignTruck(driverId, truckUnAssignmentDto);
  //       console.log("트럭 운전자에게 배정된 트럭 해제 완료", truckUnAssignmentDto)
  //       navigate("/driver/driverinfo/truck")
  //       alert("Completed releasing a truck assigned to a truck driver!");
  //       // 상태 업데이트나 페이지 새로고침 등의 추가적인 동작
  //     } catch (error) {
  //       console.error("트럭 운전자에게 배정된 트럭 해제 실패:", error);
  //       alert("Sorry, Failed to release a truck assigned to a truck driver");
  //     }
  // };
  const unassignTruck = async (
    driverId: string,
    drivingId: any,
    drivingStatus: any
  ) => {
    const finishedDistance = finishedDistances[driverId];
    const driverDrivingData = await adminTruckDriverDrivingControllerFindOne(
      driverId,
      drivingId
    );
    console.log("driving data:", driverDrivingData.data);
    if (finishedDistance === undefined || finishedDistance === null) {
      alert("Please enter the finished distance");
      return;
    }
    if (drivingId && drivingStatus) {
      if (drivingStatus === DrivingStatus.DRIVING) {
        alert("Matching cannot be released while the truck is Driving");
        return;
      }
      if (driverDrivingData.data.endTime === null) {
        alert(
          "The driver has not completed the 'Close for the Day' process yet."
        );
        return;
      }
    }
    try {
      const truckUnAssignmentDto: TruckUnAssignmentDto = {
        finishedDistance,
      };
      await adminTruckDriverControllerUnAssignTruck(
        driverId,
        truckUnAssignmentDto
      );
      navigate("/driver/driverinfo/truck");
      alert("Completed releasing a truck assigned to a truck driver!");
      // 상태 업데이트나 페이지 새로고침 등의 추가적인 동작
    } catch (error) {
      console.error("트럭 운전자에게 배정된 트럭 해제 실패:", error);
      alert("unassignTruck failed. Please try again.");
    }
  };

  useEffect(() => {
    fetchTruckDrivers();
  }, [fetchTruckDrivers]);

  return (
    <>
      <Header />
      <Sidebar />
      <Content>
        <DriverSidebar />
        <Title>Release Assignment</Title>
        <Area>
          <p style={{ fontSize: "20px", color: "#D9534F", marginTop: "4px" }}>
            * You cannot release the truck if the driver has not used the 'Close
            for the Day' function.
            <br />
            Be sure to release the truck only after the driver has completed the
            'Close for the Day' process.
          </p>
          <Table>
            <TableHeader>
              <TableCellTitle>Driver</TableCellTitle>
              <TableCellTitle>Truck Number</TableCellTitle>
              <TableCellTitle>Dashboard</TableCellTitle>
              <TableCellTitle></TableCellTitle>
            </TableHeader>
            <TableBody>
              {drivers.map((driver) => (
                <TableRow key={driver.id}>
                  <TableCell>
                    {driver.firstName} {driver.lastName}
                  </TableCell>
                  <TableCell>
                    <TextInput
                      value={driver.assignment?.truck?.licensePlateNumber || ""} // 트럭 넘버
                      disabled
                    />
                  </TableCell>
                  <TableCell>
                    <TextInput
                      type="number"
                      value={finishedDistances[driver.id] || ""}
                      onChange={(e) =>
                        handleDistanceChange(driver.id, Number(e.target.value))
                      }
                      placeholder="mile"
                    />
                  </TableCell>
                  <TableCell>
                    {/* <SaveBtn onClick={() => unassignTruck(driver.id)}>
                      Save
                    </SaveBtn> */}
                    <SaveBtn
                      onClick={() =>
                        unassignTruck(
                          driver.id,
                          driver.assignment?.drivingId,
                          driver.assignment?.truck.drivingStatus
                        )
                      }
                    >
                      Save
                    </SaveBtn>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Area>
      </Content>
    </>
  );
};

const Content = styled.div`
  display: inline-flex;
`;

const Title = styled.div`
  font-family: "Poppins";
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #22232b;
  margin-top: 78px;
  margin-left: 35px;
`;

const Area = styled.div`
  height: 928px;
  width: 995px;
  margin-top: 54px;
  position: absolute;
  margin-top: 168px;
  margin-left: 354px;
`;

const TextInput = styled.input`
  background-color: #f1f1f4;
  border: none;
  cursor: pointer;
  color: #838796;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  width: 148px;
  height: 32px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 12px;

  &:focus {
    outline: none;
    border: 2px solid #ed6a2c;
  }
`;

const DropdownContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  margin-top: 18px;
  margin-bottom: 24px;
`;

const DropdownButton = styled.button`
    background-color: #F1F1F4;
    border: none;
    padding: 10px;
    cursor: pointer;
    color:  #838796;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    width: 148px;
    height: 32px;
    display: flex; 
    align-items: center; 
    gap: 8px; 
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;

    span {
        //내용 한줄로 보이게
        white-space: nowrap; /
    }
`;

const DropdownContent = styled.div<{ isOpen: boolean }>`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

const DropdownItem = styled.div`
  padding: 3px;
  width: 425px;
  cursor: pointer;
  &:hover {
    background-color: #f1f1f1;
  }
`;

const Image = styled.img`
  flex-shrink: 0;
  display: inline-block;
  padding-left: 106px;
  /* margin-left: 306px; */
  position: absolute;
  color: #2faf4b;
`;

const Button = styled.div`
  width: 135px;
  height: 38px;
  display: inline-flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 3px;
  background-color: #121317;
  color: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  font-family: "Poppins";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  margin-left: 1191px;
  margin-right: 114px;
  margin-top: 411px;
`;
const SaveBtn = styled.div`
  width: 50px;
  height: 25px;
  display: inline-flex;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 3px;
  background: #121317;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  color: #ffffff;
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  cursor: pointer;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

const TableHeader = styled.thead`
  /* ... 테이블 헤더 관련 스타일링 */
  border-top: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 995px;
  color: #838796;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

const TableBody = styled.tbody``;

const TableRow = styled.tr``;

const TableCell = styled.td`
  padding-top: 24px;
  color: #000;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const TableCellTitle = styled.td`
  color: #838796;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
`;

export default Start;
