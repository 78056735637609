import React, { useState, useRef, useEffect } from 'react';
import styled from "styled-components";
import ClientSidebar from "../../../components/ClientSidebar";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import DropDown from "../../../images/DropDown.png";
import XBtn from "../../../images/XBtn.png";

interface PostNoticeProps {

};



const PostNotice: React.FC<PostNoticeProps> = () => {
  const [isChecked, setIsChecked] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [selectedDateType, setSelectedDateType] = useState<string | null>(null);
  const [isDateTypeDropdownOpen, setIsDateTypeDropdownOpen] = useState(false);
  const [isOrderFilterDropdownOpen, setIsOrderFilterDropdownOpen] = useState(false); // 새로운 드롭다운 상태 추가
  const [selectedOrderFilter, setSelectedOrderFilter] = useState<string | null>("");
  const dateTypeDropdownRef = useRef<HTMLDivElement>(null);
  const orderFilterDropdownRef = useRef<HTMLDivElement>(null); 
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
  const [imageFiles, setImageFiles] = useState<File[]>([]);

  const toggleDateTypeDropdown = () => {
    setIsDateTypeDropdownOpen(!isDateTypeDropdownOpen);
    setIsOrderFilterDropdownOpen(false);
  };

  const toggleOrderFilterDropdown = () => {
    setIsOrderFilterDropdownOpen(!isOrderFilterDropdownOpen);
    setIsDateTypeDropdownOpen(false);
  };

  const handleDateTypeItemClick = (item: string) => {
    setSelectedDateType(item);
    setIsDateTypeDropdownOpen(false);
  };

  const handleOrderFilterItemClick = (item: string) => {
    setSelectedOrderFilter(item); // 선택한 Order Filter를 업데이트
    setIsOrderFilterDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        orderFilterDropdownRef.current &&
        !orderFilterDropdownRef.current.contains(event.target as Node) &&
        dateTypeDropdownRef.current &&
        !dateTypeDropdownRef.current.contains(event.target as Node)
      ) {
        // 클릭된 요소가 드롭다운 내부가 아니면 드롭다운을 닫음
        setIsDateTypeDropdownOpen(false);
        setIsOrderFilterDropdownOpen(false);
      }
    };

    // 문서 전체에서 클릭 이벤트 감지
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // 컴포넌트 언마운트 시 이벤트 리스너 제거
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // 데이터 예시
  const data:any = [
    // 데이터 객체들
  ];

  const rowsPerPage = 12;
  const maxPage = Math.ceil(data.length / rowsPerPage);

  // 현재 페이지의 데이터를 가져오는 함수
  const getCurrentPageData = () => {
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return data.slice(startIndex, endIndex);
  };

  // 이미지 파일 추가
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
  const selectedFiles = event.target.files;
  if (selectedFiles) {
    // const filesArray = Array.from(selectedFiles);
    // setImageFiles((prevFiles) => [...prevFiles, ...filesArray]);
    setSelectedFiles(selectedFiles);
  }
  };

  // 이미지 파일 삭제
  const handleRemoveImage = (index: number) => {
    setSelectedFiles((prevSelectedFiles) => {
      if (prevSelectedFiles) {
        const updatedFiles = Array.from(prevSelectedFiles);
        updatedFiles.splice(index, 1);
        const updatedFileList = new DataTransfer();
        updatedFiles.forEach((file) => {
          updatedFileList.items.add(file);
        });
        return updatedFileList.files.length > 0 ? updatedFileList.files : null;
      }
      return prevSelectedFiles;
    });
  };
  
  
  
  



  return (
    <>
      <Header />
      <Sidebar />
      <Content>
        <ClientSidebar />
        <Title>Notice </Title>
        <Area>
        <FirstDetailWrapper>
          <>
          <NoticeTitle>Title</NoticeTitle>
          <TitleTextInput
            placeholder="text"
          />
          </>
          <>
          <Type>Type</Type>
          {/* Type DropDown */}
          <DropdownContainer ref={dateTypeDropdownRef}>
            <DropdownButton onClick={toggleDateTypeDropdown}>
              <span>{selectedDateType || "select"}</span>
              <Image src={DropDown} alt='dropdown' />
            </DropdownButton>
            <DropdownContent isOpen={isDateTypeDropdownOpen}>
              <DropdownItem onClick={() => handleDateTypeItemClick("notice")}>notice</DropdownItem>
              <DropdownItem onClick={() => handleDateTypeItemClick("event")}>event</DropdownItem>
            </DropdownContent>
          </DropdownContainer> 
          <Date>Date</Date>
          {/* Date DropDown */}
          <DropdownContainer ref={orderFilterDropdownRef}>
            <DropdownButton onClick={toggleOrderFilterDropdown}>
              <span>{selectedOrderFilter || "select"}</span> 
              <Image src={DropDown} alt='dropdown' />
            </DropdownButton>
            <DropdownContent isOpen={isOrderFilterDropdownOpen}>
              <DropdownItem onClick={() => handleOrderFilterItemClick("week")}>week</DropdownItem>
              <DropdownItem onClick={() => handleOrderFilterItemClick("month")}>month</DropdownItem>
              <DropdownItem onClick={() => handleOrderFilterItemClick("year")}>year</DropdownItem>
            </DropdownContent>
          </DropdownContainer>
          </>
        </FirstDetailWrapper>
         <input
                  type="file"
                  accept="image/*" 
                  multiple // 여러 파일 선택 가능하도록 설정
                  style={{ display: 'none' }}
                  onChange={handleFileUpload}
                  ref={fileInputRef}
                />
                {/* <PhotoWrapper>
                <AddPhotos>Add Photos</AddPhotos>
                <AddPhotoArea onClick={() => fileInputRef.current?.click()}>+ Add Photos</AddPhotoArea>
                {selectedFiles && (
                  <PreviewArea>
                    {Array.from(selectedFiles).map((file, index) => (
                      <SelectImgList key={index}>
                      <SelectImgXBtn 
                        onClick={() => handleRemoveImage(index)}
                      >X</SelectImgXBtn>
                      <SelectImg src={URL.createObjectURL(file)} alt={`Preview ${index}`} />
                      <SelectImgName>{file.name}</SelectImgName> 
                    </SelectImgList>
                    
                    ))}
                  </PreviewArea>
                )}

                </PhotoWrapper> */}
                <PhotoWrapper>
                  <AddPhotos>Add Photos</AddPhotos>
                  <AddPhotoArea onClick={() => fileInputRef.current?.click()}>+ Add Photos</AddPhotoArea>
                  {selectedFiles && (
                    <PreviewArea>
                      {Array.from(selectedFiles).map((file, index) => (
                        <SelectImgList key={index}>
                          <SelectImgXBtn onClick={() => handleRemoveImage(index)}>X</SelectImgXBtn>
                          <SelectImg src={URL.createObjectURL(file)} alt={`Preview ${index}`} />
                          <SelectImgName>{file.name}</SelectImgName>
                        </SelectImgList>
                      ))}
                    </PreviewArea>
                  )}
                </PhotoWrapper>
          {/* Add Photos 추가 예정 */}
        <Contents>Contents</Contents>
        <ContentsTextArea
          placeholder="text"
        />
          {/* Contents TextArea 추가 예정 */}
        <DetailWrapper>
        <TopFixing>
        <StyledCheckbox
          type="checkbox"
          checked={isChecked}
          onChange={() => setIsChecked(!isChecked)}
        />
          Top Fixing
        </TopFixing>
        <PostBtn>Post</PostBtn>
        </DetailWrapper>
        </Area>
      </Content>
    </>
  );
};

  const SelectImgList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 5px; 
  `;

  const SelectImgXBtn = styled.button`
  border: none;
  margin-left: 5px;
  margin-right: 5px;
  border: none;
  `;

  const SelectImg = styled.img`
  margin-right: 5px;
  width: 18px;
  height: 18px;
  `;

  const SelectImgName = styled.div`
  color: #22232B;
  font-family: 'Poppins';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #22232B;

  `;

  const PreviewArea = styled.div`
  display: flex;
  flex-direction: column; 
  gap: 10px; 
  white-space: nowrap;
  width: 893px;
  background: #F1F1F4;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 12px;
  `;

  const Content = styled.div`
    display: inline-flex;
  `;

  const Title = styled.div`
    font-family: 'Poppins';
    font-size: 28px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: #22232b;
    margin-top: 78px;
    margin-left: 35px;
  `;

  const Area = styled.div`
      height: 928px;
      width: 995px;
      margin-top: 54px;
      position: absolute;
      margin-top: 168px;
      margin-left: 354px;
  `

  const PostBtn = styled.div`
      display: inline-flex;
      /* padding: 8px 24px; */
      width: 81px;
      height: 38px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 3px;
      background: #121317;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
      color: #FFF;
      font-family: 'Poppins';
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; 
      margin-top: 20px;
      margin-left: 820px;
  `;

  const StyledCheckbox = styled.input`
    appearance: none; /* 체크 박스의 기본 스타일 숨기기 */
    border: 1px solid #9EA1AE;
    border-radius: 3px;
    width: 20px;
    height: 20px;

    &:checked {
      background-color: #ED6A2C !important; 
      border: none;
      background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
    }
  `;

  const TitleTextInput = styled.input`
    width: 544px;
    height: 32px;
    background: #F1F1F4;
    color: #838796;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    border: none;
    border-radius: 3px;
    cursor: pointer;
    margin-top: 24px;
    margin-left: -20px;
    display: flex;
    padding-left: 10px;
    
    &:focus {
        outline: none; 
        border: 2px solid #ED6A2C; 
    }

    &::placeholder {
        color: #838796; 
    }
  `

  const TopFixing = styled.div`
    color: #22232B;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    display: inline-flex;
    margin-left: 815px;
    margin-top: 18px;
    justify-content: center;
    align-items: center;
  `;

  const FirstDetailWrapper = styled.div`
    display: inline-flex;
    width : 1500px;
  `;

  const DetailWrapper = styled.div`
  `;

  const NoticeTitle = styled.div`
    color: #838796;
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  `;

  const Type = styled.div`
    color: #838796;
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-left: 18px;
  `;

  const Date = styled.div`
    color: #838796;
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  `;

  const AddPhotos = styled.div`
    color: #838796;
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-top: 18px;
  `

  const Contents = styled.div`
    color: #838796;
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-top: 18px;
  `;

  const DropdownContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    margin-top: 24px;
    width: 164px;
    padding: 2px 12px;
    margin-left: -32px;
  `;

  const DropdownButton = styled.button`
    background-color: #f1f1f1;
    border: none;
    padding: 10px;
    cursor: pointer;
    color:  #83879d;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    width: 145px;
    /* width: 164px; */
    height: 32px;
    display: flex; 
    align-items: center; 
    gap: 8px; 
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;

  span {
      //내용 한줄로 보이게
      white-space: nowrap; /
  }
  `;

  const DropdownContent = styled.div<{isOpen: boolean}>` 
    display: ${(props) => (props.isOpen ? 'block' : 'none')};
    position: absolute;
    background-color: #fff;
    /* min-width: 130px; */
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    width: 145px;
  `;

  const DropdownItem = styled.div`
    padding: 3px;
    width: 145px;
    cursor: pointer;
    &:hover {
        background-color: #f1f1f1;
    }
  `;

  const Image = styled.img`
      flex-shrink: 0;
      display: inline-block;
      padding-left: 85px;
      margin-left: 16px;
      position: absolute;
  `

  const ContentsTextArea = styled.textarea`
      background-color: #F1F1F4;
      border: none;
      cursor: pointer;
      color:  #22232B;
      font-family: 'Poppins';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; 
      width: 882px;
      height: 348px;
      display: flex; 
      gap: 8px;
      margin-top: 8px;
      padding-top: 12px;
      align-items: flex-start;
      resize: vertical;
      padding: 13px;
      border-radius: 3px;

      &:focus {
          outline: none; 
          border: 2px solid #ED6A2C; 
      }

      &::placeholder {
          color: #838796; 
      }
  `;

const PhotoWrapper = styled.div`
  display: flex;
  flex-direction: column; 
  gap: 10px;
  margin-top: 8px;
  border-radius: 3px;
  width: 882px;
  color: #838796;
`;

const AddPhotoArea = styled.div`
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%; /* 변경: 가로 폭을 100%로 설정하여 부모 요소 너비에 맞게 확장합니다. */
  height: 50px;
  border-radius: 3px;
  background: #F1F1F4;
  color: #838796;
`;

export default PostNotice;
