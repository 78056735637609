/**
 * Generated by orval v6.19.0 🍺
 * Do not edit manually.
 * OLHSO API
 * The OLHSO API description
 * OpenAPI spec version: 0.1
 */

export type VehicleStatus = typeof VehicleStatus[keyof typeof VehicleStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VehicleStatus = {
  AVAILABLE: 'AVAILABLE',
  UNAVAILABLE: 'UNAVAILABLE',
} as const;
