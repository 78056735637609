import React from "react";
import ClientSidebar from "../../components/ClientSidebar";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";

const Client: React.FC = () => {
  return (
    <>
      <Header />
      <Sidebar />
      <ClientSidebar />
    </>
  );
};

export default Client;
