import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import DriverSidebar from "../../../components/DriverSidebar";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import Driving from "../../../images/Driving.png";
import NotDriving from "../../../images/NotDriving.png";
import Pause from "../../../images/Pause.png";
import { adminTruckControllerFindMany } from "../../../api/service/admin-trucks/admin-trucks";
import { initToken } from '../../../utils/setAuthToken';
import { Truck } from '../../../api/model/truck';
import EmergencyStopModal from '../../../components/EmergencyStopModal';
import { adminTruckControllerUpdateEmergency } from "../../../api/service/admin-trucks/admin-trucks";
import {adminTruckControllerUpdateDeactivate, adminTruckControllerUpdateActivate} from "../../../api/service/admin-trucks/admin-trucks";
import ConfirmModal from '../../../components/ConfirmModal';

interface TruckDeliveryStatusProps {

};

interface StatusTableCellProps {
  drivingStatus: string; // 이 부분을 DrivingStatus 타입으로 변경할 수도 있습니다.
};

interface TruckStatus {
  [id: string]: boolean; // 키는 트럭의 id, 값은 stopStatus의 boolean 값
}

const TruckDeliveryStatus: React.FC<TruckDeliveryStatusProps> = () => {
  const navigate = useNavigate();
  const [trucks, setTrucks] = useState<Truck[]>([]);
  const [stopStatus, setStopStatus] = useState(false); //stop 버튼 상태 관리
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedTruckId, setSelectedTruckId] = useState<string | null>(null);
  const [truckStopStatus, setTruckStopStatus] = useState<TruckStatus>({});
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [currentTruckId, setCurrentTruckId] = useState<string | null>(null);

   // 'Change' 버튼 클릭 핸들러
  //  const handleChangeClick = (truckId: string) => {
  //   setCurrentTruckId(truckId);
  //   setConfirmModalOpen(true);
  // };

  // 'Change' 버튼 클릭 핸들러
const handleChangeClick = async (truckId: string) => {
  setCurrentTruckId(truckId);
  try {
    const truck = trucks.find(t => t.id === truckId);
    if (truck) {
      let response;
      if (truck.vehicleStatus === "AVAILABLE") {
        response = await adminTruckControllerUpdateDeactivate(truckId);
      } else {
        response = await adminTruckControllerUpdateActivate(truckId);
      }

      // 에러 코드 409 처리
      if (response.status === 409) {
        alert("The truck is currently in operation. It cannot be changed. Please try again later.");
      } else {
        // 성공적인 상태 업데이트
        fetchTrucks();
      }
    }
  } catch (error) {
    console.error('Error during truck status change:', error);
    // alert('Failed to change truck status. Please try again later.');
    alert("The truck is currently in operation. It cannot be changed. Please try again later.");
  }
};


  // ConfirmModal의 onConfirm 핸들러
  const handleConfirmChange = async () => {
    if (currentTruckId) {
      const truck = trucks.find(t => t.id === currentTruckId);
      if (truck) {
        if (truck.vehicleStatus === "AVAILABLE") {
          await adminTruckControllerUpdateDeactivate(currentTruckId);
          console.log("트럭 디엑티베이트:",currentTruckId)
        } else {
          await adminTruckControllerUpdateActivate(currentTruckId);
          console.log("트럭 액티베이트:",currentTruckId)
        }
        // 트럭 상태 업데이트
        fetchTrucks();
        console.log("트럭 상태 업데이트:",currentTruckId)
      }
    }
    setConfirmModalOpen(false);
  };

  // 토큰을 초기화하고, 초기화된 토큰 값을 반환하는 함수
  const initTokenAndGetToken = async () => {
    await initToken(); 
    console.log("initToken:",initToken)
    // const token = getAccessToken(); /
    // return token;
  };
  
  const fetchTrucks = async () => {
    try {
      const response = await adminTruckControllerFindMany();
      const initialStatus: TruckStatus = {};
      response.data.items.forEach(truck => {
        initialStatus[truck.id] = false;
      });
      setTrucks(response.data.items);
      setTruckStopStatus(initialStatus);
    } catch (error) {
      console.error('Error fetching trucks:', error);
      alert('Error fetching trucks, Please check process & try again later.');
    }
  };

  const handleStopClick = (truckId: string) => {
    setSelectedTruckId(truckId); // Stop 클릭 시 트럭 ID 저장
    console.log("저장한 트럭 아이디:",truckId )
    setModalIsOpen(true);
  };
  
  const handleCloseModal = () => {
    setModalIsOpen(false);
  };
  
  // 토글 스위치 변경 핸들러
  // const handleToggleChange = async (truckId: string, isActive: boolean) => {
  //   try {
  //     if (isActive) {
  //       await adminTruckControllerUpdateDeactivate(truckId);
  //       console.log("Truck deactivated successfully", truckId);
  //     } else {
  //       await adminTruckControllerUpdateActivate(truckId);
  //       console.log("Truck activated successfully", truckId);
  //     }
  //     // 트럭 상태 업데이트 (예: 상태 관리 상태 업데이트 또는 트럭 데이터 다시 가져오기)
  //     // 예: fetchTrucks();
  //   } catch (error) {
  //     console.error("Error updating truck status:", error);
  //     alert("Failed to update truck status");
  //   }
  // };

  // const handleConfirmStop = async () => {
  //   if (selectedTruckId) {
  //     try {
  //       const response = await adminTruckControllerUpdateEmergency(selectedTruckId);
  //       if (response.status === 200) {
  //         alert("Truck emergency stop process complete!");
  //         setTruckStopStatus(prev => ({ ...prev, [selectedTruckId]: true }));
  //       } else {
  //         throw new Error("Error");
  //       }
  //     } catch (error) {
  //       console.error("Emergency stop transmission error:", error);
  //       alert("Sorry, the truck emergency stop request failed. Please try again later" + error);
  //     }
  //     handleCloseModal();
  //   }
  // };

  // 상태 요청 
  const handleConfirmStop = async () => {
    if (selectedTruckId) {
      try {
        const response = await adminTruckControllerUpdateEmergency(selectedTruckId);
        if (response.status === 200) {
          alert("Truck emergency stop process complete!");
          const updatedStatus = {
            ...truckStopStatus,
            [selectedTruckId]: true
          };
          setTruckStopStatus(updatedStatus);
          localStorage.setItem('truckStopStatus', JSON.stringify(updatedStatus));
        } 
        else {
          throw new Error("Error");
        }
      } catch (error) {
        console.error("Emergency stop transmission error:", error);
        alert("Sorry, the truck emergency stop request failed. Please try again later" + error);
      }
      handleCloseModal();
    }
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     await fetchTrucks();
  //   };

  //   fetchData();
  // }, [navigate]); 

  useEffect(() => {
    const savedStatus = localStorage.getItem('truckStopStatus');
    if (savedStatus) {
      setTruckStopStatus(JSON.parse(savedStatus));
    }
    fetchTrucks();
  }, [navigate]); 

  return (
    <>
      <Header />
      <Sidebar />
      <Content>
        <DriverSidebar />
        <Title>Truck Delivery Status</Title>
        <Area>
          <Table>
            <TableHeader>
              <TableCellTitle>Truck Number</TableCellTitle>
              <TableCellTitle>Truck Id</TableCellTitle>
              <TableCellTitle>Driving Status</TableCellTitle>
              <TableCellTitle>Truck Availability</TableCellTitle>
              <TableCellTitle>Changing Truck Availability</TableCellTitle>
              <TableCellTitle>Emergency Stop</TableCellTitle>
              {/* <TableCellTitle>Inventory</TableCellTitle> */}
            </TableHeader> 
            {/* Table Body 자리*/}
            <TableBody>
            {trucks.map(truck => (
              <TableRow key={truck.licensePlateNumber}>
                <TableCell>{truck.licensePlateNumber}</TableCell>
                <TableCell>{truck.id}</TableCell>
                <StatusTableCell drivingStatus={truck.drivingStatus}>
                  {truck.drivingStatus}
                </StatusTableCell>
                <VehicleStatusTableCell vehicleStatus={truck.vehicleStatus}>
                  {truck.vehicleStatus}
                </VehicleStatusTableCell>
                <TableCell>
                <ChangeButton onClick={() => handleChangeClick(truck.id)}>
                  Change to '{truck.vehicleStatus === "AVAILABLE" ? "UNAVAILABLE" : "AVAILABLE"}'
                </ChangeButton>
                {/* <ToggleSwitch>
                  <CheckBox
                    type="checkbox"
                    checked={truck.vehicleStatus === "AVAILABLE"}
                    onChange={() => handleToggleChange(truck.id, truck.vehicleStatus === "AVAILABLE")}
                  />
                  <ToggleSlider />
                </ToggleSwitch> */}
                </TableCell>
                <TableCell>
                <StopButton 
                onClick={() => handleStopClick(truck.id)}
                disabled={truckStopStatus[truck.id] || truck.drivingStatus === 'ADMIN_STOP'}
                stopStatus={truckStopStatus[truck.id] || truck.drivingStatus === 'ADMIN_STOP'}
                >
                  Stop
                </StopButton>
                <EmergencyStopModal 
                  isOpen={modalIsOpen} 
                  onClose={handleCloseModal} 
                  onConfirm={handleConfirmStop} 
                />
                </TableCell>
                {/* <TableCell>
                  <InventoryButton 
                    onClick={() => {
                      navigate("/driver/truckinfo/inventory");
                    }}
                  >
                  Inventory
                  </InventoryButton>
                </TableCell> */}
              </TableRow>
            ))}
            <ConfirmModal
              isOpen={confirmModalOpen}
              onClose={() => setConfirmModalOpen(false)}
              onConfirm={handleConfirmChange}
            />
          </TableBody>
          </Table>
        </Area>
      </Content>
    </>
  );
};


const ToggleSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 47.7px;
  height: 23.33px;
`;

const ToggleSlider = styled.span`
  position: absolute;
  /* cursor: pointer; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }
`;

const CheckBox = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${ToggleSlider} {
    background-color: #ED6A2C;
  }

  &:focus + ${ToggleSlider} {
    box-shadow: 0 0 1px #2196F3;
  }

  &:checked + ${ToggleSlider}:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
`;

const Content = styled.div`
  display: inline-flex;
`;

const Title = styled.div`
  font-family: 'Poppins';
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #22232b;
  margin-top: 78px;
  margin-left: 35px;
`;


const Image = styled.img`
    width: 8px;
    height: 8px;
    flex-shrink: 0;
    margin-right: 8px;
`

const Area = styled.div`
    height: 928px;
    width: 995px;
    margin-top: 54px;
    position: absolute;
    margin-top: 168px;
    margin-left: 354px;
`


const Table = styled.table`
    border-collapse: collapse;
    width: 100%;
`;

const TableHeader = styled.thead`
    /* ... 테이블 헤더 관련 스타일링 */
    border-top: 1px solid #E4E4E4;
    border-bottom : 1px solid #E4E4E4;
    padding-top: 12px;
    padding-bottom: 12px;
    width: 995px; 
    color:  #838796;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
`;

const TableBody = styled.tbody`
 
`;

const TableRow = styled.tr`
      
`;

const TableCell = styled.td`
    padding-top: 24px;
    color: #000;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
`;

const TableCellTitle = styled.td`
    color: #838796;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    padding-top: 12px;
    padding-bottom: 12px;   
`

const InventoryButton = styled.button`
  color: #000000;
  background: #FFFFFF;
  border: 1px solid #000000;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
`;

const ChangeButton = styled.button`
  padding: 5px 10px;
`;

const StopButton = styled.button<{ stopStatus?: boolean }>` 
  color: ${({ stopStatus }) => stopStatus ? '#A9A9A9' : '#d93838'};
  background: ${({ stopStatus }) => stopStatus ? '#E0E0E0' : '#ffebeb'};
  border: ${({ stopStatus }) => stopStatus ? 'none' : '1px solid #d93838'};
  padding: 5px 10px;
  border-radius: 3px;
  cursor: ${({ stopStatus }) => stopStatus ? 'not-allowed' : 'pointer'};
  pointer-events: ${({ stopStatus }) => stopStatus ? 'none' : 'auto'}; // 클릭 이벤트 방지
`;

const StatusTableCell = styled.td<StatusTableCellProps>`
  padding-top: 24px;
  color: ${(props) => {
    switch (props.drivingStatus) {
      case "Driving":
        return '#1DB954'; 
      case "Emergency":
        return '#FB3A3A'; 
      case "ADMIN_STOP":
        return '#FB3A3A';
      case "Pause":
        return '#F5C20D'; 
      case "Assigned":
        return '#1DB954'; 
      case "IDLE":
        return '#BABCC5'; //회색으로 바꿔야함
      default:
        return '#22232B'; 
    }
  }};
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const VehicleStatusTableCell = styled.td<{ vehicleStatus: string }>`
  padding-top: 24px;
  color: ${(props) => props.vehicleStatus === "AVAILABLE" ? '#1DB954' : '#FB3A3A'};
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;






export default TruckDeliveryStatus;

