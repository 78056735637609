import React, { useState, useEffect, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import logIn from "../../utils/cognito";

interface LogInProps {}

const LogIn: React.FC<LogInProps> = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // 컴포넌트가 마운트된 상태를 추적하는 상태 추가
  const [isMounted, setIsMounted] = useState(true);

  useEffect(() => {
    return () => {
      // 컴포넌트가 마운트 해제되었을 때 isMounted를 false로 설정
      setIsMounted(false);
    };
  }, []);

  // 로그인 요청
  const signIn = async () => {
    try {
      setIsLoading(true);
      const user = await logIn(email, password);

      // CognitoUserSession 객체에서 필요한 정보 추출
      const idToken = user.getIdToken().getJwtToken();
      const refreshToken = user.getRefreshToken().getToken();
      const accessToken = user.getAccessToken().getJwtToken();

      // 필요한 정보를 객체로 저장
      const userData = {
        idToken,
        refreshToken,
        accessToken,
      };

      // console.log("1. Tokens:", { idToken, refreshToken, accessToken });

      // 사용자 정보를 로컬 스토리지에 안전하게 저장
      localStorage.setItem("user", JSON.stringify(userData));
      // test
      localStorage.setItem("accessToken", idToken);
      // console.log("사용자 정보 스토리지에 저장: ", userData);

      // 로그인 성공시 홈으로 이동
      navigate("/driver");

      if (isMounted) {
        setIsLoading(false);
      }
    } catch (error: any) {
      console.error("Error logging in:", error);
      alert((error as Error).message);
      if (isMounted) {
        setIsLoading(false);
      }
    }
  };

  const handleAdminIdChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  return (
    <Container>
      <LoginForm>
        <Title>Admin Login</Title>
        <TextInputWrapper>
          <TextInput
            placeholder="Admin ID"
            value={email}
            onChange={handleAdminIdChange}
          />
          <TextInput
            placeholder="Password"
            type="password"
            value={password}
            onChange={handlePasswordChange}
          />
          <LoginButton onClick={signIn} disabled={isLoading}>
            {isLoading ? "Logging in..." : "Login"}
          </LoginButton>
        </TextInputWrapper>
      </LoginForm>
    </Container>
  );
};

const Container = styled.div`
  background-color: #f1f1f4;
`;

const LoginForm = styled.div`
  display: flex;
  width: 384px;
  height: 312px;
  width: 384px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid rgba(18, 19, 23, 0.1);
  background: #fff;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Title = styled.div`
  display: flex;
  justify-content: center;
  color: #22232b;
  position: absolute;
  margin-top: 44px;
  margin-left: 132px;
  text-align: center;
  font-family: "Poppins";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
`;

const TextInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 116px;
  margin-left: 61px;
`;

const TextInput = styled.input<{ isFocused?: boolean }>`
  width: 242px;
  height: 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 3px;
  background: #f1f1f4;
  color: ${(props) => (props.isFocused ? "#050506" : "#22232b")};
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding-left: 12px;
  border: none;
  margin-bottom: 12px;
  border-radius: 3px;

  &:focus {
    outline: none;
    border: 2px solid #ed6a2c;
  }
`;

const LoginButton = styled.button`
  width: 253px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 3px;
  background: #ed6a2c;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  color: #fff;
  font-family: "Poppins";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  cursor: pointer;

  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
`;

export default LogIn;
