import React, { useState, useRef, useEffect } from 'react';
import styled from "styled-components";
import ClientSidebar from "../../../components/ClientSidebar";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";


interface AddPushNotificationProps {

};

const AddPushNotification: React.FC<AddPushNotificationProps> = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isTruckTypeDropdownOpen, setIsTruckTypeDropdownOpen] = useState(false);
    const [isDrivingAreaDropdownOpen, setIsDrivingAreaDropdownOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');

    useEffect(() => {
        if (title.length > 25) {
            alert("The title can't be longer than 25 characters.");
            setTitle(title.substring(0, 25));
        }
    }, [title]);

    useEffect(() => {
        if (body.length > 80) {
            alert("The body cannot be longer than 80 characters.");
            setBody(body.substring(0, 80));
        }
    }, [body]);


    const truckTypeDropdownRef = useRef<HTMLDivElement>(null);
    const drivingAreaDropdownRef = useRef<HTMLDivElement>(null);
  
      const closeDropdowns = () => {
          setIsOpen(false);
      };
  
      const handleClickOutside = (event: MouseEvent) => {
        const isClickedInsideTruckTypeDropdown = truckTypeDropdownRef.current?.contains(event.target as Node);
        const isClickedInsideDrivingAreaDropdown = drivingAreaDropdownRef.current?.contains(event.target as Node);
        
        if (!isClickedInsideTruckTypeDropdown && !isClickedInsideDrivingAreaDropdown) {
            setIsTruckTypeDropdownOpen(false);
            setIsDrivingAreaDropdownOpen(false);
        }
    };

      useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);


      useEffect(() => {
          document.addEventListener('click', closeDropdowns);
          return () => {
              document.removeEventListener('click', closeDropdowns);
          };
      }, []);
  

  return (
    <>
      <Header />
      <Sidebar />
      <Content>
        <ClientSidebar />
        <Title>Add Push Notification </Title>
        <Area>
            <Row>
                <InputContainer>
                <TitleDetail>Date</TitleDetail> 
                <TextInput1 placeholder='dd/mm/yyyy ~ dd/mm/yyyy' />
                </InputContainer>
                <InputContainer>
                <TitleDetail>Time</TitleDetail>
                <TextInput placeholder='00:00pm' />
                </InputContainer>
            </Row>
                <TitleDetail>Title</TitleDetail>
                <TextInputContainer>
                <>
                <TextInput3 
                    placeholder='title'
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
                <TextLength1>{title.length}/25</TextLength1>
                </>
                </TextInputContainer>
                <TitleDetail>Body</TitleDetail>
                <TextInput2 
                    placeholder='text'
                    value={body}
                    onChange={(e) => setBody(e.target.value)}
                />
                <TextLength2>{body.length}/80</TextLength2>
                <Button>
                  Apply
                </Button>
        </Area>
      </Content>
    </>
  );
};

const Row = styled.div`
  display: flex;
  justify-content: space-between; 
  align-items: center;
  margin-top: 8px; 
`;

const Title = styled.div`
  font-family: 'Poppins';
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #22232b;
  margin-top: 78px;
  margin-left: 35px;
`;

const Area = styled.div`
    height: 928px;
    /* width: 995px; */
    width: 522px;
    margin-top: 54px;
    position: absolute;
    margin-top: 168px;
    margin-left: 354px;
`

const Content = styled.div`
  display: inline-flex;
`;

const TextInput = styled.input`
    background-color: #F1F1F4;
    border: none;
    cursor: pointer;
    color:  #838796;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    width: 162px;
    height: 32px;
    display: flex; 
    align-items: center; 
    gap: 8px;
    padding-left: 12px;
    margin-top: 8px;

    &:focus {
        outline: none; 
        border: 2px solid #ED6A2C; 
    }
`;

const TextLength1 = styled.div`
    color: #838796;
    text-align: right;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; 
    margin-top: -14px;
`;

const TextLength2 = styled.div`
    color: #838796;
    text-align: right;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; 
    margin-top: 12px;
`;

const TextInput1 = styled.input`
    background-color: #F1F1F4;
    border: none;
    cursor: pointer;
    color:  #838796;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    width: 322px;
    height: 32px;
    display: flex; 
    align-items: center; 
    gap: 8px;
    padding-left: 12px;
    margin-top: 8px;

    &:focus {
        outline: none; 
        border: 2px solid #ED6A2C; 
    }
`;

const TextInput2 = styled.textarea`
    background-color: #F1F1F4;
    border: none;
    cursor: pointer;
    /* color:  #838796; */
    color:  #22232B;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    width: 510px;
    height: 141px;
    display: flex; 
    gap: 8px;
    padding-left: 12px;
    margin-top: 8px;
    padding-top: 12px;
    align-items: flex-start;
    resize: vertical;

    &:focus {
        outline: none; 
        border: 2px solid #ED6A2C; 
    }

    &::placeholder {
        color: #838796; 
    }
`;

const TextInput3 = styled.input`
    background-color: #F1F1F4;
    border: none;
    cursor: pointer;
    color:  #838796;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    width: 472px;
    height: 32px;
    display: flex; 
    align-items: center; 
    gap: 8px;
    padding-left: 12px;
    margin-top: 8px;

    &:focus {
        outline: none; 
        border: 2px solid #ED6A2C; 
    }
`;

const TitleDetail = styled.div`
    color:  #838796;
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-top: 24px;
`;

const Button = styled.div`
    width: 86px;
    height: 38px;
    border-radius: 3px;
    background: #121317;
    display: inline-flex;
    /* padding: 8px 24px; */
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #FFF;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin-top: 47px;
    margin-left: 440px;
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 0px;
`;

const TextInputContainer = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between; // Input과 길이 사이에 공간을 균등하게 분배
`;


export default AddPushNotification;
