import React, { useState, useRef,useEffect,useCallback } from 'react';
import styled from "styled-components";
import ClientSidebar from "../../components/ClientSidebar";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import RefreshBtn from "../../images/Refresh.png";
import DropDown from "../../images/DropDown.png";
import { adminReserveOrderControllerFindMany, adminReserveOrderControllerDispatchTruck } from '../../api/service/admin-reserve-order/admin-reserve-order'; 
import { ReserveOrder } from '../../api/model/reserveOrder'; 
import type { AdminReserveOrderControllerFindManyParams } from '../../api/model/adminReserveOrderControllerFindManyParams'; 
import { truckControllerFindMany } from "../../api/service/trucks/trucks";
import { adminTruckDriverControllerFindMany, adminTruckDriverControllerAssignTruck } from "../../api/service/admin-truck-drivers/admin-truck-drivers";
import { TruckDriver } from "../../api/model/truckDriver";

interface ScheduleDordersProps {

};

interface Truck {
  licensePlateNumber: string;
  id: string;
};

const ScheduleDorders: React.FC<ScheduleDordersProps> = () => {
  const truckTypeDropdownRef = useRef<HTMLDivElement>(null);
  const [reservedOrders, setReservedOrders] = useState<ReserveOrder[]>([]);
  const [drivers, setDrivers] = useState<TruckDriver[]>([]); 
  const [loading, setLoading] = useState(false);
  const [trucks, setTrucks] = useState<Truck[]>([]); //트럭 리스트
  const [dropdownOpenStates, setDropdownOpenStates] = useState<{ [key: string]: boolean }>({});
  const [selectedTruckDetails, setSelectedTruckDetails] = useState<{ [driverId: string]: { id: string; licensePlateNumber: string } }>({});
  const [driverDistances, setDriverDistances] = useState<{ [driverId: string]: number }>({});


  useEffect(() => {
    const initialDropdownStates = drivers.reduce((acc, driver) => {
      acc[driver.id] = false;
      return acc;
    }, {} as { [key: string]: boolean });
    setDropdownOpenStates(initialDropdownStates);
  }, [drivers]);

  // Toggle dropdown for a specific driver
  const toggleDropdown = (driverId: string) => {
    setDropdownOpenStates((prevStates) => ({
      ...prevStates,
      [driverId]: !prevStates[driverId]
    }));
  };

  // Handle truck selection
  const handleTruckSelection = (driverId: string, truck: Truck) => {
    // Log the selected truck's ID
    console.log(`Selected truck ID for driver :`, truck.id, truck.licensePlateNumber);

    setSelectedTruckDetails((prevDetails) => ({
      ...prevDetails,
      [driverId]: { id: truck.id, licensePlateNumber: truck.licensePlateNumber }
    }));
    toggleDropdown(driverId);
};
  


  // 시간 변환 함수
  const convertToLocaleTime = (date: any, time: string) => {
    // 시간 형식 변경 (_HH_MM -> HH:MM)
    const formattedTime = time.replace('_', '').replace('_', ':');
    // 날짜와 시간 결합
    const dateTime = new Date(`${date}T${formattedTime}`);
    // 현지 시간으로 변환 + 영문식 표현
    return dateTime.toLocaleString('en-US');
  };

  //화면 새로고침 함수
  const handleRefreshClick = () => {
    window.location.reload();
  };

   // 트럭 목록 조회 
   const fetchTrucks = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await truckControllerFindMany();
      setTrucks(data.items); 
      console.log("트럭 목록 조회 성공", data.items);
    } catch (error) {
      console.error("트럭 목록 조회 에러", error);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchTrucks();
  }, [fetchTrucks]);


  // 예약 주문 목록 조회하기
  const fetchReservedOrders = async () => {
    try {
      const params: AdminReserveOrderControllerFindManyParams = {
      };

      const response = await adminReserveOrderControllerFindMany(params);
      if (response.data && Array.isArray(response.data.items)) {
        setReservedOrders(response.data.items);
        console.log('예약 주문 목록 조회:', response.data.items);
      } else {
        console.log('Unexpected response structure:', response.data);
      }
    } catch (error) {
      console.error('Error fetching reserved orders:', error);
    }
  };

  useEffect(() => {
    fetchReservedOrders();
  }, []);

  // 예약 주문 트럭배정
  const dispatchTruckForOrder = async (orderId: string) => {
    const selectedTruck = selectedTruckDetails[orderId];

    if (!selectedTruck) {
      alert("Please select a truck for the order");
      return;
    }

    try {
      const updateDispatedTruckDto = { truckId: selectedTruck.id };
      const response = await adminReserveOrderControllerDispatchTruck(orderId, updateDispatedTruckDto);
      console.log("예약 주문에 트럭 배정 성공:", response);

      // 로컬 스토리지에 저장
      localStorage.setItem(`truckForOrder_${orderId}`, JSON.stringify(selectedTruck));

      alert("Truck dispatched successfully");
    } catch (error) {
      console.error("예약 주문에 트럭 배정 실패:", error);
      alert("Failed to dispatch truck");
    }
  };

  

  return (
    <>
      <Header />
      <Sidebar />
      <Content>
        <ClientSidebar />
        <Title>Scheduled Orders</Title>
        <Area>
                <Table>
                    <TableHeader>
                        <TableCellTitle>Order Number</TableCellTitle>
                        <TableCellTitle>Date</TableCellTitle>
                        <TableCellTitle>Menu-Quantity</TableCellTitle>
                        <TableCellTitle>Truck Assignments</TableCellTitle>
                        <TableCellTitle></TableCellTitle>
                    </TableHeader> 
                <TableBody>
                  {reservedOrders.map((order) => ( 
                  <TableRow 
                  key={order.id}
                  >
                    <TableCell>{order.id}</TableCell>
                    {/* 현지 시각으로 바꿔서 보여줘야함 */}
                    {/* <TableCell>{order.orderDate}</TableCell>
                    <TableCell>{order.reserveDeliveryTime}</TableCell> */}
                    <TableCell>{convertToLocaleTime(order.orderDate, order.reserveDeliveryTime)}</TableCell>
                    <TableCell>
                    {order?.detail?.orderItems.map((item, index) => (
                      <div key={index}>
                        {item.name} - Quantity: {item.quantity}
                      </div>
                    ))}
                    </TableCell>
                    <TableCell>
                      {/* 드롭다운 생성  */}
                      <DropdownContainer>
                            <DropdownButton 
                            onClick={() => toggleDropdown(order.id)}
                            >
                              <span style={{ color: '#000' }}>
                                {
                                selectedTruckDetails[order.id]?.licensePlateNumber || 
                                "Select"}
                                </span>
                              <Image src={DropDown} alt='dropdown' />
                            </DropdownButton>
                            {dropdownOpenStates[order.id] && (
                              <DropdownContent isOpen>
                                {trucks.map((truck) => (
                                  <DropdownItem key={truck.id} 
                                  onClick={() => handleTruckSelection(order.id, truck)}
                                  >
                                    {truck.licensePlateNumber}
                                  </DropdownItem>
                                ))}
                              </DropdownContent>
                             )} 
                      </DropdownContainer>
                    </TableCell>
                    <TableCell>
                    <SaveBtn onClick={() => dispatchTruckForOrder(order.id)}>
                      Save
                    </SaveBtn>
                    </TableCell>
                  </TableRow>
                ))}
                        
                </TableBody>
                </Table>
                 <img  
                  onClick={handleRefreshClick}
                  src={RefreshBtn}
                  alt="Refresh" 
                  style={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    strokeWidth: '1px',
                    stroke: '#22232B',
                    filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))',
                    marginLeft: '1100px',
                    marginTop: '40px',
                }}
                 />
        </Area>
      </Content>
    </>
  );
};


const Content = styled.div`
  display: inline-flex;
`;

const Title = styled.div`
  font-family: 'Poppins';
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #22232b;
  margin-top: 78px;
  margin-left: 35px;
`;

const Area = styled.div`
    height: 928px;
    width: 995px;
    margin-top: 54px;
    position: absolute;
    margin-top: 168px;
    margin-left: 354px;
`;

const Table = styled.table`
    border-collapse: collapse;
    width: 100%;
`;

const TableHeader = styled.thead`
    /* ... 테이블 헤더 관련 스타일링 */
    border-top: 1px solid #E4E4E4;
    border-bottom : 1px solid #E4E4E4;
    padding-top: 12px;
    padding-bottom: 12px;
    width: 995px; 
    color:  #838796;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
`;

const TableBody = styled.tbody`
 
`;

const TableRow = styled.tr`
      
`;

const TableCell = styled.td`
    padding-top: 24px;
    color: #000;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
`;

const TableCellTitle = styled.td`
    color: #838796;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    padding-top: 12px;
    padding-bottom: 12px;   
`
const DropdownContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    margin-top: 18px;
    margin-bottom: 24px;
`;

const DropdownButton = styled.button`
    background-color: #F1F1F4;
    border: none;
    padding: 10px;
    cursor: pointer;
    color:  #838796;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    min-width: 160px;
    height: 32px;
    display: flex; 
    align-items: center; 
    gap: 8px; 
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;

    span {
        //내용 한줄로 보이게
        white-space: nowrap; /
    }
`;

const DropdownContent = styled.div<{ isOpen: boolean }>`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  position: absolute;
  top: 100%;
  left: 0;
  width: 160px; // 드롭다운 버튼의 너비와 동일하게 설정
  background-color: #fff;
  min-width: 160px;
  /* height: 32px; */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 4px;
  overflow: hidden;
`;

// DropdownItem의 너비를 DropdownButton과 동일하게 맞추기 위해 수정합니다.
const DropdownItem = styled.div`
  margin-left: 12px;
  margin-top: 12px;
  color: #838796;
  background-color: red
  padding: 3px;
  min-width: 340px;
  height: 32px;
  cursor: pointer;
  &:hover {
    background-color: #f1f1f1;
  }
`;

const Image = styled.img`
    flex-shrink: 0;
    display: inline-block;
    padding-left: 116px;
    /* margin-left: 306px; */
    position: absolute;
    color: #2FAF4B; 
`
const SaveBtn = styled.button`
  color: #FFFFFF;
  background: #22232B;
  border: 1px solid #000000;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
  /* margin-top: 3px; */
`


export default ScheduleDorders;

