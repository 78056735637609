/**
 * Generated by orval v6.19.0 🍺
 * Do not edit manually.
 * OLHSO API
 * The OLHSO API description
 * OpenAPI spec version: 0.1
 */

export type DrivingStatus = typeof DrivingStatus[keyof typeof DrivingStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DrivingStatus = {
  IDLE: 'IDLE',
  DRIVING: 'DRIVING',
  ASSIGNED: 'ASSIGNED',
  PAUSED: 'PAUSED',
  NO_NEW_ORDERS: 'NO_NEW_ORDERS',
  EMERGENCY: 'EMERGENCY',
  TRUCK_BREAK_DOWN: 'TRUCK_BREAK_DOWN',
  ADMIN_STOP: 'ADMIN_STOP',
} as const;
