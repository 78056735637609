import React from 'react';
import styled from 'styled-components';
import XBtn from '../images/XBtn.png';
import Status from '../images/Status.png';
import Driving from '../images/Driving.png';
import Cancled from '../images/Cancled.png';
import Completed from '../images/Completed.png';

interface UsersDetailModalProps {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode; 
  }
  

const UsersDetailModal: React.FC<UsersDetailModalProps> = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

// 모달 외부를 클릭한 경우 또는 X 버튼을 클릭한 경우에 모달을 닫습니다.
const handleModalOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
  if (event.target === event.currentTarget) {
    onClose(); // 모달 닫는 함수 호출
  }
};
    
    return (
        <ModalOverlay onClick={handleModalOverlayClick}>
          <ModalContent>
            <XBtn/>
            <FirstWrapper>
                <UserName>Admin</UserName>
                <UserEmailTitle>Email</UserEmailTitle>
                <UserEmail>i@shinstarrr.com</UserEmail>
                <UserPhoneTitle>Phon Number</UserPhoneTitle> 
                <UserPhone>+01 243 2354</UserPhone> 
                <UserAddressTitle>Address</UserAddressTitle>
                <UserAddress>200 W 47th St, New York, New York</UserAddress>
                <UserSignUPTitle>Sign-Up Date</UserSignUPTitle>
                <UserSignUP>August 25, 2023</UserSignUP>
            </FirstWrapper>
            <SecondWrapper>
                <MilleageTitle>Mileage</MilleageTitle>
                <Milleage>14 coin</Milleage>
                <CouponTitle>Coupoon</CouponTitle>
                <Coupon>5</Coupon>
            </SecondWrapper>
            <ThirdWrapper>
                <Title>Past Orders</Title>
                <TableContainer>
                    <Table>
                        <TableHeader>
                                    <TableCellTitle>No</TableCellTitle>
                                    <TableCellTitle>Date</TableCellTitle>
                                    <TableCellTitle>Order Number</TableCellTitle>
                                    <TableCellTitle>Truck Number</TableCellTitle>
                                    <TableCellTitle>Menu</TableCellTitle>
                                    <TableCellTitle>Status</TableCellTitle>
                                    <TableCellTitle></TableCellTitle>
                        </TableHeader> 
                        <TableBody>
                                    <TableRow>
                                        <TableCell>1</TableCell>
                                        <TableCell>August 25,2023</TableCell>
                                        <TableCell>13S42G342E</TableCell>
                                        <TableCell>MV23RE</TableCell>
                                        <TableCellMenu>Yangnyum Galbi x1, Japch...</TableCellMenu>
                                        <TableCell>
                                        <StatusIcon
                                            src={Driving}
                                            alt="Driving"
                                            />
                                        Driving
                                        </TableCell>
                                        <TableCell><SearchBtn>Cancel Order</SearchBtn></TableCell>
                                    </TableRow>
                                    <TableRow>
                                    <TableCell>1</TableCell>
                                        <TableCell>August 25,2023</TableCell>
                                        <TableCell>13S42G342E</TableCell>
                                        <TableCell>MV23RE</TableCell>
                                        <TableCellMenu>Yangnyum Galbi x1, Japche, Noodle, Gimchi, Rice, Taco</TableCellMenu>
                                        <TableCell>
                                        <StatusIcon
                                            src={Cancled}
                                            alt="Cancled"
                                            />
                                        Cancled
                                        </TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>1</TableCell>
                                        <TableCell>August 25,2023</TableCell>
                                        <TableCell>13S42G342E</TableCell>
                                        <TableCell>MV23RE</TableCell>
                                        <TableCellMenu>Yangnyum Galbi x1, Japch...</TableCellMenu>
                                        <TableCell>
                                        <StatusIcon
                                            src={Completed}
                                            alt="Completed"
                                            />
                                        Completed
                                        </TableCell>
                                        <TableCell><SearchBtn>Cancel Order</SearchBtn></TableCell>
                                    </TableRow>
                                </TableBody>
                    </Table>
                </TableContainer>
            </ThirdWrapper>
          </ModalContent>
        </ModalOverlay>
      );
    };
    
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 600px;
  height: 470px;
  max-height: 700px;
  overflow-y: auto; 
`;

const FirstWrapper = styled.div`
`;

const SecondWrapper = styled.div`
`;

const ThirdWrapper = styled.div`
`;

const UserName = styled.div``
    
;

const UserEmailTitle = styled.div``;
const UserEmail = styled.div``;
const UserPhoneTitle = styled.div``;
const UserPhone = styled.div``;
const UserAddressTitle = styled.div``;
const UserAddress = styled.div``;
const UserSignUPTitle = styled.div``;
const UserSignUP = styled.div``;
const MilleageTitle = styled.div``;
const Milleage = styled.div``;
const CouponTitle = styled.div``;
const Coupon = styled.div``;
const Title = styled.div``;
const TableContainer = styled.div`
  height: 546px;
  //아래 테두리 표시
  border-bottom: 1px solid #1213171A;
`;

const Table = styled.table`
    border-collapse: collapse;
    width: 100%;
`;

const TableHeader = styled.thead`
    /* ... 테이블 헤더 관련 스타일링 */
    border-top: 1px solid #E4E4E4;
    border-bottom : 1px solid #E4E4E4;
    padding-top: 12px;
    padding-bottom: 12px;
    width: 995px; 
    color:  #838796;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
`;

const TableBody = styled.tbody`
 
`;

const TableRow = styled.tr`
      
`;

const TableCell = styled.td`
    padding-top: 24px;
    color: #000;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
`;

const TableCellMenu = styled.td`
  width: 191px;
  padding-top: 24px;
  color: #000;
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
  overflow: hidden; /* 넘치는 내용 숨김 */
  white-space: nowrap; /* 내용을 한 줄로 표시 */
  text-overflow: ellipsis; /* 넘치는 내용에 "..." 표시 */
  max-width: 191px; /* 셀의 최대 너비를 설정 */
  padding-right: 75px;
`;

const TableCellTitle = styled.td`
    color: #838796;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    padding-top: 12px;
    padding-bottom: 12px;   
`

const SearchBtn = styled.button``;
const StatusIcon = styled.img``;



export default UsersDetailModal;