import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import DriverSidebar from "../../../components/DriverSidebar";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import {
  adminTruckDriverControllerFindMany,
  adminTruckDriverControllerRemove,
} from "../../../api/service/admin-truck-drivers/admin-truck-drivers";
import { TruckDriver } from "../../../api/model/truckDriver";
import emergency from "../../../images/emergency.png";
import LoadingComponent from "../../../components/Loading";

interface DriversProps {}

interface TableCellProps {
  status?: string;
}

interface ModalProps {
  show: boolean;
  onClose: () => void;
  onConfirm: () => void;
  children: React.ReactNode;
}

const Drivers: React.FC<DriversProps> = () => {
  const navigate = useNavigate();

  const [drivers, setDrivers] = useState<TruckDriver[]>([]);
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(false); //토글 버튼
  const [showModal, setShowModal] = useState(false); //모달 관련
  const [selectedDriverId, setSelectedDriverId] = useState<string | null>(null); //모달관련

  // 트럭 운전자 목록 조회
  const fetchTruckDrivers = useCallback(async () => {
    setLoading(true);

    try {
      const { data } = await adminTruckDriverControllerFindMany();
      setDrivers(data.items);
      console.log("트럭 운전자 목록 조회:", data.items);
    } catch (error) {
      console.error("트럭 운전자 목록 조회 에러:", error);
    }

    setLoading(false);
  }, []);

  // 트럭 운전자 비활성화(사실상 삭제)
  // 드라이버 삭제 핸들러
  const handleDeleteClick = async (driverId: string) => {
    try {
      await adminTruckDriverControllerRemove(driverId);
      console.log(`Driver with id ${driverId} removed`);
      // 삭제 후 드라이버 목록 업데이트
      setDrivers((prevDrivers) =>
        prevDrivers.filter((driver) => driver.id !== driverId)
      );
      alert("Success.The driver has been deleted.");
    } catch (error) {
      console.error("드라이버 삭제 에러:", error);
    }
  };

  useEffect(() => {
    fetchTruckDrivers();
  }, [fetchTruckDrivers]);

  //트럭 추가 클릭
  const handleAddTruckClick = () => {
    navigate("/driver/driverinfo/drivers/adddriver");
  };

  const openModal = (driverId: string) => {
    setShowModal(true);
    setSelectedDriverId(driverId);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedDriverId(null);
    console.log("드라이버 삭제 모달 취소");
  };

  const confirmDelete = async () => {
    if (selectedDriverId) {
      await handleDeleteClick(selectedDriverId);
    }
    console.log("드라이버 삭제 완료");
    closeModal();
  };

  // 드라이버 삭제 확인 모달
  const Modal: React.FC<ModalProps> = ({
    show,
    onClose,
    onConfirm,
    children,
  }) => {
    if (!show) {
      return null;
    }

    if (loading) {
      return <LoadingComponent />;
    }

    return (
      <ModalBackground onClick={closeModal}>
        {" "}
        {/* 여기에 onClick 추가 */}
        <ModalContent onClick={(e) => e.stopPropagation()}>
          {" "}
          {/* 이벤트 버블링 방지 */}
          <ModalHeader>
            <EmergencyImg src={emergency} alt="emergency" />
            <ModalTitle>Delete a Driver</ModalTitle>
          </ModalHeader>
          <ModalTitle2>
            {" "}
            Are you sure you want to delete this driver?
          </ModalTitle2>
          <ModalTitle3>
            : If you press Delete Driver,
            {"\n"} the driver is deactivated and disappears from the list.
          </ModalTitle3>
          <BtnBox>
            <CancelBtn onClick={closeModal}>Cancel</CancelBtn>{" "}
            {/* 여기에 onClick 추가 */}
            <OkBtn onClick={confirmDelete}>Yes</OkBtn>{" "}
            {/* 여기에 onClick 추가 */}
          </BtnBox>
        </ModalContent>
      </ModalBackground>
    );
  };

  // Edit 버튼 클릭 이벤트 핸들러(클릭시 특정 운전자 편집 페이지로 이동)
  const handleEditClick = (driverId: string) => {
    //EditDrivers 화면으로 이동
    navigate(`/driver/driverinfo/drivers/editdriver/${driverId}`);
    console.log("Editing driver with id:", driverId);
  };

  // 상태 변경 핸들러 (각 운전자에 대해 개별적으로 처리)
  const handleToggleChange = (driverId: string, currentStatus: string) => {
    // 여기에 상태 변경 로직을 추가합니다.
    // 예: API 요청을 통해 상태를 변경한 후, 상태를 업데이트합니다.
    console.log(`Status toggle for driver ${driverId}: ${currentStatus}`);
  };

  return (
    <>
      <Header />
      <Sidebar />
      <Content>
        <DriverSidebar />
        <Title>Drivers Info</Title>
        <Area>
          <Table>
            <TableHeader>
              <TableCellTitle>Driver</TableCellTitle>
              <TableCellTitle>Phone Number</TableCellTitle>
              <TableCellTitle>E-mail</TableCellTitle>
              <TableCellTitle>Status</TableCellTitle>
              <TableCellTitle>{/* Active */}</TableCellTitle>
              <TableCellTitle></TableCellTitle>
              <TableCellTitle></TableCellTitle>
              <TableCellTitle></TableCellTitle>
            </TableHeader>
            <TableBody>
              {/*트럭 운전자 목록 보여주기*/}
              {drivers.map((driver, index) => (
                <TableRow key={driver.id}>
                  <TableCell>
                    {driver.firstName + " " + driver.lastName}
                  </TableCell>
                  <TableCell>{driver.phoneNum}</TableCell>
                  <TableCell>{driver.email}</TableCell>
                  <TableCell status={driver.status}>{driver.status}</TableCell>
                  <TableCell>
                    {/* <ToggleSwitch>
                      <CheckBox
                        type="checkbox"
                        checked={driver.status === 'WORKING'}
                        onChange={() => handleToggleChange(driver.id, driver.status)}
                      />
                      <ToggleSlider />
                    </ToggleSwitch> */}
                  </TableCell>
                  <TableCell>
                    {/* Edit 버튼 보여주기 */}
                    <EditButton onClick={() => handleEditClick(driver.id)}>
                      Edit
                    </EditButton>
                  </TableCell>
                  <TableCell>
                    <DeleteButton onClick={() => openModal(driver.id)}>
                      Delete
                    </DeleteButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Button onClick={handleAddTruckClick}>+ Add Driver</Button>
        </Area>
      </Content>

      <Modal show={showModal} onClose={closeModal} onConfirm={confirmDelete}>
        {/* <ModalTitle>Warning</ModalTitle> */}
        {/* <OkBtn>OK</OkBtn>
        <CancelBtn>Cancel</CancelBtn> */}
      </Modal>
    </>
  );
};

const Content = styled.div`
  display: inline-flex;
`;

const Title = styled.div`
  font-family: "Poppins";
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #22232b;
  margin-top: 78px;
  margin-left: 35px;
`;

const Area = styled.div`
  height: 928px;
  width: 995px;
  margin-top: 54px;
  position: absolute;
  margin-top: 168px;
  margin-left: 354px;
`;
const ToggleSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 47.7px;
  height: 23.33px;
`;

const ToggleSlider = styled.span`
  position: absolute;
  /* cursor: pointer; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

const CheckBox = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${ToggleSlider} {
    background-color: #ed6a2c;
  }

  &:focus + ${ToggleSlider} {
    box-shadow: 0 0 1px #2196f3;
  }

  &:checked + ${ToggleSlider}:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
`;

const Button = styled.div`
  width: 135px;
  heigth: 38px;
  display: inline-flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 3px;
  background-color: #121317;
  color: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  font-family: "Poppins";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  margin-left: 1191px;
  margin-right: 114px;
  margin-top: 411px;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

const TableHeader = styled.thead`
  /* ... 테이블 헤더 관련 스타일링 */
  border-top: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 995px;
  color: #838796;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

const TableBody = styled.tbody``;

const TableRow = styled.tr``;

const TableCell = styled.td<TableCellProps>`
  padding-top: 24px;
  color: ${(props) => {
    switch (props.status) {
      case "WORKING":
        return "#ED6A2C";
      case "OFF":
        return "#838796";
      default:
        return "#22232B";
    }
  }};
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const TableCellTitle = styled.td`
  color: #838796;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
`;

const EditButton = styled.button`
  background: #ffffff;
  border: 1px solid #000000;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
`;

const DeleteButton = styled.button`
  color: #ffffff;
  background: #22232b;
  border: 1px solid #000000;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
`;

// 모달 배경
const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

// 모달 내용
const ModalContent = styled.div`
  background-color: #fff2f1;
  border: 1px solid #cd3320;
  padding: 50px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 300px;
  height: 200px;
`;

const ModalTitle = styled.div`
  color: #cd3320;
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 800;
  text-align: center;
  margin-top: 20px;
`;

const ModalTitle2 = styled.div`
  color: #22232b;
  font-family: "Poppins";
  font-size: 15px;
  margin-top: 20px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalTitle3 = styled.div`
  color: #22232b;
  font-family: "Poppins";
  font-size: 15px;
  margin-top: 20px;
  margin-left: 20px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OkBtn = styled.button`
  background-color: #cd3320;
  color: white;
  border-radius: 24px;
  padding: 10px 20px;
  font-family: "Poppins";
  font-size: 15px;
  margin-right: 10px;
  border: none;
  margin-top: 20px;
  margin-left: 20px;
`;

const CancelBtn = styled.button`
  background-color: transparent;
  color: #393c46;
  border: 1px solid #393c46;
  border-radius: 24px;
  padding: 10px 20px;
  font-family: "Poppins";
  font-size: 15px;
  border: 1px solid #393c46;
  margin-top: 20px;
  margin-left: 60px;
`;

const BtnBox = styled.div`
    display: "flex",
    margin-top: 37px;
    flex-direction: "row";
    justify-content: "center";
    margin-bottom: 30px;
    gap: 10;
    
`;

const EmergencyImg = styled.img`
  width: 20px;
  height: 20px;
  margin-top: 15px;
  margin-left: 65px;
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export default Drivers;
