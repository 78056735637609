import React, { useState, useRef, useEffect } from 'react';
import styled from "styled-components";
import ClientSidebar from "../../../components/ClientSidebar";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import Search from "../../../images/Search.png";

interface MatchReviewProps {

};

const MatchReview: React.FC<MatchReviewProps> = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isTruckTypeDropdownOpen, setIsTruckTypeDropdownOpen] = useState(false);
    const [isDrivingAreaDropdownOpen, setIsDrivingAreaDropdownOpen] = useState(false);
  

    const truckTypeDropdownRef = useRef<HTMLDivElement>(null);
    const drivingAreaDropdownRef = useRef<HTMLDivElement>(null);
  
      const closeDropdowns = () => {
          setIsOpen(false);
      };
  
      const handleClickOutside = (event: MouseEvent) => {
        const isClickedInsideTruckTypeDropdown = truckTypeDropdownRef.current?.contains(event.target as Node);
        const isClickedInsideDrivingAreaDropdown = drivingAreaDropdownRef.current?.contains(event.target as Node);
        
        if (!isClickedInsideTruckTypeDropdown && !isClickedInsideDrivingAreaDropdown) {
            setIsTruckTypeDropdownOpen(false);
            setIsDrivingAreaDropdownOpen(false);
        }
    };

      useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);


      useEffect(() => {
          document.addEventListener('click', closeDropdowns);
          return () => {
              document.removeEventListener('click', closeDropdowns);
          };
      }, []);
  

  return (
    <>
      <Header />
      <Sidebar />
      <Content>
        <ClientSidebar />
        <Title>Match Review Contents</Title>
        <Area>
                <TitleDetail>Search Email</TitleDetail>
                <TextInputWrapper>
                <TextInput1 placeholder='text' />
                <SearchBtn src={Search} alt="Search" />
                </TextInputWrapper>
                <TitleDetail>Apply Mileage</TitleDetail>
                <TextInput1 placeholder='-/+' />
                <TitleDetail>Reason</TitleDetail>
                <TextInput2 placeholder='text' />
                {/* 완료 버튼  */}
                <Button>
                  Apply
                </Button>
        </Area>
      </Content>
    </>
  );
};


const TextInputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SearchBtn = styled.img`
  display: inline-flex;
  justify-content: flex-end;
  position: absolute;
  margin-left: 510px;
`;

const Title = styled.div`
  font-family: 'Poppins';
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #22232b;
  margin-top: 78px;
  margin-left: 35px;
`;

const Area = styled.div`
    height: 928px;
    width: 995px;
    margin-top: 54px;
    position: absolute;
    margin-top: 168px;
    margin-left: 354px;
`

const Content = styled.div`
  display: inline-flex;
`;

const TextInput = styled.input`
    background-color: #F1F1F4;
    border: none;
    cursor: pointer;
    color:  #838796;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    width: 522px;
    height: 32px;
    display: flex; 
    align-items: center; 
    gap: 8px;
    padding-left: 12px;
    margin-top: 8px;
`;

const TextInput1 = styled.input`
    background-color: #F1F1F4;
    border: none;
    cursor: pointer;
    color:  #838796;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    width: 522px;
    height: 32px;
    display: flex; 
    align-items: center; 
    gap: 8px;
    padding-left: 12px;

    &:focus {
        outline: none; 
        border: 2px solid #ED6A2C; 
    }
`;

const TextInput2 = styled.textarea`
    background-color: #F1F1F4;
    border: none;
    cursor: pointer;
    /* color:  #838796; */
    color:  #22232B;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    width: 522px;
    height: 141px;
    display: flex; 
    gap: 8px;
    padding-left: 12px;
    margin-top: 8px;
    padding-top: 12px;
    align-items: flex-start;
    resize: vertical;

    &:focus {
        outline: none; 
        border: 2px solid #ED6A2C; 
    }

    &::placeholder {
        color: #838796; 
    }
`;

const DropdownContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    margin-top: 8px;
    margin-bottom: 24px;
`;

const DropdownButton = styled.button`
    background-color: #f1f1f1;
    border: none;
    padding: 10px;
    cursor: pointer;
    color:  #83879d;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    width: 545px;
    height: 32px;
    display: flex; 
    align-items: center; 
    gap: 8px; 
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;

    span {
        //내용 한줄로 보이게
        white-space: nowrap; /
    }
`;

const DropdownContent = styled.div<{isOpen: boolean}>` 
    display: ${(props) => (props.isOpen ? 'block' : 'none')};
    position: absolute;
    background-color: #fff;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
`;

const DropdownItem = styled.div`
    padding: 3px;
    width: 545px;
    cursor: pointer;
    &:hover {
        background-color: #f1f1f1;
    }
`;

const Image = styled.img`
    flex-shrink: 0;
    display: inline-block;
    padding-left: 496px;
    /* margin-left: 306px; */
    position: absolute;
`

const TitleDetail = styled.div`
    color:  #838796;
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-top: 24px;
    margin=bottom: 8px;
`;

const Button = styled.div`
    width: 86px;
    height: 38px;
    border-radius: 3px;
    background: #121317;
    display: inline-flex;
    /* padding: 8px 24px; */
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #FFF;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin-top: 47px;
    margin-left: 460px;
`

export default MatchReview;
